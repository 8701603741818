import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../../apis";
import CustomerEmailVerification from "./CustomerEmailVerification";

const Signup = () => {
    const [userData, setUserData] = useState({
        firstName:"",
        lastName: "",
        email: "",
        phoneNo: "",
        password: "",
        confirmPassword: "",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isAccountCreated, setIsAccountCreated] = useState(false);

    const params = useParams();

    // const navigate = useNavigate();

    const userDataChangeHandler = (event) => {
        const {name, value} = (event.target); // event is the input value change event object
        
        setUserData((prevState) => {
            return ({
                ...prevState,
                [name]: value,
            });
        })
    };

    const formSubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const formData = {
            first_name: userData.firstName,
            last_name: userData.lastName,
            email: userData.email,
            password: userData.password,
            phone_no: userData.phoneNo,
        };
        
        api.post(`/businesses/${params.bid}/signup`, JSON.stringify(formData), {
          headers: {
            "Content-Type": "application/json",
          },
        }).then((response) => {
          setIsAccountCreated(true);
          // navigate(`/business/${params.bid}/login`);
          setIsLoading(false);
        }).catch((error) => {
          if(error.code==="ERR_NETWORK") {
            alert(`${error.message} \nKindly check your connection`);
            return;
          }
          alert(`Something went wrong \n${error.response.data.error_message}`);
          setIsLoading(false);
        });
    };

  return (
    !isAccountCreated ? <div className="font-mono bg-gray-400 min-h-screen py-14">
      <div className="container mx-auto">
        <div className="flex justify-center px-6">
          <div className="w-full xl:w-3/4 lg:w-11/12 flex">
            <div
              className="w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg"
              style={{
                backgroundImage:
                  "url('https://source.unsplash.com/Mv9hjnEUHR4/600x800')",
              }}
            ></div>
            <div className="w-full lg:w-7/12 bg-white p-5 rounded-lg lg:rounded-l-none">
              <h3 className="pt-4 text-2xl text-center">Create an Account!</h3>
              <form className="px-8 pt-6 pb-8 mb-4 bg-white rounded" onSubmit={formSubmitHandler}>
                <div className="mb-4 md:flex md:justify-between">
                  <div className="mb-4 md:mr-2 md:mb-0">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="firstName"
                    >
                      First Name
                    </label>
                    <input
                      className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="firstName"
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      required
                      value={userData.firstName}
                      onChange={userDataChangeHandler}
                    />
                  </div>
                  <div className="md:ml-2">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="lastName"
                    >
                      Last Name
                    </label>
                    <input
                      className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="lastName"
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      required
                      value={userData.lastName}
                      onChange={userDataChangeHandler}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    className="block mb-2 text-sm font-bold text-gray-700"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    placeholder="Email"
                    name="email"
                    required
                    value={userData.email}
                    onChange={userDataChangeHandler}
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block mb-2 text-sm font-bold text-gray-700"
                    htmlFor="phoneNo"
                  >
                    Phone Number
                  </label>
                  <input
                    className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="phoneNo"
                    type="number"
                    placeholder="+11234567890"
                    name="phoneNo"
                    required
                    value={userData.phoneNo}
                    onChange={userDataChangeHandler}
                  />
                </div>
                <div className="mb-4 md:flex md:justify-between">
                  <div className="mb-4 md:mr-2 md:mb-0">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="password"
                      type="password"
                      placeholder="******************"
                      name="password"
                      required
                      value={userData.password}
                      onChange={userDataChangeHandler}
                    />
                  </div>
                  <div className="md:ml-2">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="c_password"
                    >
                      Confirm Password
                    </label>
                    <input
                      className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="c_password"
                      type="password"
                      placeholder="******************"
                      name="confirmPassword"
                      required
                      value={userData.confirmPassword}
                      onChange={userDataChangeHandler}
                    />
                  </div>
                </div>
                {userData.password!==userData.confirmPassword && <p className="text-red-500 mb-5">Password do not match</p>}
                <div className="mb-6 text-center">
                  <button
                    className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                    type="submit" disabled={isLoading || (userData.password!==userData.confirmPassword)}
                  >
                    {isLoading ? "Registering" : "Register Account"}
                  </button>
                </div>
                <hr className="mb-6 border-t" />
                {/* <div className="text-center">
                  <Link
                    className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                    to="#"
                  >
                    Forgot Password?
                  </Link>
                </div> */}
                <div className="text-center">
                  <Link
                    className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                    to={`/business/${params.bid}/login`}
                  >
                    Already have an account? Login!
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div> :
    <CustomerEmailVerification email={userData.email} />
  );
};

export default Signup;
