import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomerEnquiryLedger from "./CustomerEnquiryLedger";
import { api } from "../../../../../apis";
import ImageExpander from "../../../../Owner/pages/Enquiries/ImageExpander";
import {getCustomerDetails, getCustomerToken} from "../../../../../utils/businessDetails";
import CurrencyFormat from "react-currency-format";

const CustomerEnquiryDetails = (props) => {
  const params = useParams();
  // const authToken = JSON.parse(localStorage.getItem(`${params.bid}-customer`)).token;
  const authToken = getCustomerToken(params.bid);
  const customerDetails = getCustomerDetails(params.bid);
  // console.log(customerDetails);
  const [enquiryDetails, setEnquiryDetails] = useState([
    {
      first_name: "",
      last_name: "",
      email: "",
      price: "",
      delivery_mode: "",
      address_details: [{
        address: "",
        state: "",
        city: "",
        pincode: "",
      }],
      description: "",
      pinned_images: [],
      photo: '',
    },
  ]);
  const [enquiryLedger, setEnquiryLedger] = useState([
    {
      remark: "",
      price: "",
      status: "",
      updated_on: "",
    },
  ]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedImages, setExpandedImages] = useState([]);

  const showImageExpanderHandler = (images) => {
    setExpandedImages(images);
    setIsExpanded(true);
  };

  const hideImageExpanderHandler = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    if (props.isDetailsShown === true) {
      api
        .get(
          `/businesses/${params.bid}/enquiry_details`,
          { params: { enquiry_id: props.enquiryId },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((response) => {
          setEnquiryDetails(response.data.enquiry_details);
          setEnquiryLedger(response.data.enquiry_ledger);
        })
        .catch((error) => {
          if(error.code==="ERR_NETWORK") {
            alert(`${error.message} \nKindly check your connection`);
            return;
          }
          alert("Error fetching Enquiry Details:", error.response.data.error_message);
        });
    }
  }, [params.bid, props.enquiryId, props.isDetailsShown, authToken]);

  return (
    <>
    <td colSpan={3}>
      <div
        className={` overflow-hidden overflow-y-auto transition-all duration-700 h-[500px] ${
          props.isDetailsShown ? "max-h-[500px] p-6 border" : "max-h-0"
        } ease-in-out`}
      >
        <div className="relative">
          <span className="absolute -top-2.5 left-4 bg-white text-gray-400">
            Enquiry details
          </span>
          <div className="flex gap-20 items-center border rounded-lg p-4">
            <div>
              <div className="flex flex-row gap-2">
                <p>Customer Name:</p>
                <p className="text-black">{enquiryDetails[0].first_name + " " + enquiryDetails[0].last_name}</p>
              </div>
              <div className="flex flex-row gap-2">
                <p>Customer Email:</p>
                <p className="text-black">{enquiryDetails[0].email}</p>
              </div>
              <div className="flex flex-row gap-2">
                <p>Customer Phone No.:</p>
                <p className="text-black">+{enquiryDetails[0].phone}</p>
              </div>
              <div className="flex flex-row gap-2">
                <p>Delivery Mode:</p>
                <p className="text-black">{enquiryDetails[0].delivery_mode}</p>
              </div>
              <div className="flex flex-row gap-2">
                <p>Address:</p>
                <p className="text-black">{`${enquiryDetails[0].address_details[0].address}, ${enquiryDetails[0].address_details[0].city}, ${enquiryDetails[0].address_details[0].state} ${enquiryDetails[0].address_details[0].pincode}`}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 relative border rounded-lg p-4">
          <span className="absolute -top-2.5 left-4 bg-white text-gray-400">
            Job details
          </span>
          <div className="flex justify-between">
            <p>Description: <span>{enquiryDetails[0].job_description}</span></p>
          </div>
          <p>Images:</p>
          <div className="flex gap-2 flex-wrap" onClick={() => showImageExpanderHandler(enquiryDetails[0].pinned_images)}>
              {enquiryDetails[0].pinned_images.map((image, index) => (
              <img
              key={index}
                src={image}
                alt="job"
                className="w-36 h-36 object-cover"
              />
            ))}
            </div>
        </div>
        <div className="mt-4 flex justify-between px-4">
          <div>
            <p>
              Estimated price: <CurrencyFormat value={enquiryDetails[0].price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
            </p>
          </div>
          {/* <div className="flex gap-4">
            {enquiryDetails[0].status === "INITIATED" && (
              <button
                onClick={props.onClickQuote}
                className="font-medium text-white hover:underline flex bg-blue-600 border rounded-lg py-1 px-2 items-center"
              >
                <BiSolidEdit size={20} />
                Quote
              </button>
            )}
            {enquiryDetails[0].status === "INITIATED" && (
              <button
                to="#"
                className="font-medium text-white hover:underline flex bg-red-600 border rounded-lg py-1 px-2 items-center"
              >
                <TiCancel size={20} />
                Cancel
              </button>
            )}
          </div> */}
        </div>
      </div>
    </td>
    <CustomerEnquiryLedger isDetailsShown={props.isDetailsShown} customerDetails = {customerDetails} enquiryLedger={enquiryLedger} enquiryCreatorName={enquiryDetails[0].first_name + " " + enquiryDetails[0].last_name} />
    {isExpanded && <ImageExpander onClose={hideImageExpanderHandler} images={expandedImages} type="details" user="customer" />}
    </>
  );
};

export default CustomerEnquiryDetails;
