import React, { useRef, useState } from "react";
import Modal from "../../../UI/Modal";

const CustomerQuoteForm = (props) => {
  const [imgArrObj, setImgArrObj] = useState([]);
  const [quoteFormData, setQuoteFormData] = useState({
    remark: "",
    photos: [],
    price: "",
    status: props.buttonType,
  });
  const resetImgRef = useRef();

  const parseURI = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.onload = (e) => {
        resolve(e.target.result);
      };
    });
  };

  const addImageHandler = async (event) => {
    const files = event.target.files;
    if(!files) return;
    const newImagesList = [...imgArrObj];
    const filesArray = Array.from(files);
    const photos = [];
    for (let file of filesArray) {
      const base64img = await parseURI(file);
      newImagesList.push({
        id: Math.random() * 100,
        file: file,
        imgName: file.name,
        imgLink: base64img,
      });
      photos.push(file);
    }
    setImgArrObj(newImagesList);
    setQuoteFormData((prevState) => ({
        ...prevState,
        photos: photos,
    }));
    // reset the input field so that user can upload more images in one go if needed
    // resetImgRef.current.value = null;
  };

  const imageDeleteHandler = (id) => {
    const updatedImageList = imgArrObj.filter((item) => item.id !== id);
    setImgArrObj(updatedImageList);
    const files = [];
    for(const item of updatedImageList) {
      files.push(item.file);
    }
    setQuoteFormData((prevState) => ({
      ...prevState,
      photos : files,
      }));
  };

  const inputChangeHandler = (event) => {
    const {name, value, type} = event.target;
    setQuoteFormData((prevState) => ({
        ...prevState,
        [name]: type==="number" ? parseFloat(value) : value,
    }));
  };

  const submitQuoteHandler = async(event) => {
    event.preventDefault();
    await props.onSubmitQuote(quoteFormData);
    event.target.setAttribute("disabled", true); //disable button afer adding the item once
    // setQuoteFormData({
    //   message: "",
    //   photos: [],
    //   price: "",
    // });
  };

  return (
    <Modal onBackdropClick={props.onHideForm}>
      <section className="bg-white dark:bg-gray-900 w-[460px]">
        <div className="py-4 lg:py-6 px-4 mx-auto max-w-screen-md">
          <div className="flex justify-end">
            <button className="text-white" onClick={props.onHideForm}>X</button>
          </div>
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
            {quoteFormData.status==="CANCELLED" ? "Cancel Enquiry" : "Counter Price"}
          </h2>
          <p className="mb-4 lg:mb-8 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
            {quoteFormData.status==="CANCELLED" ? "Please tell us your reason to cancel" : "Please enter a counter price for the service"}
          </p>
          <form className="space-y-4">
            <div className="sm:col-span-2">
              <label
                htmlFor="remark"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Message
              </label>
              <textarea
                id="remark"
                rows="6"
                name="remark"
                value={quoteFormData.message}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Leave a comment..."
                onChange={inputChangeHandler}
              ></textarea>
            </div>
            <div>
              <label
                htmlFor="upload"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Upload Images
              </label>
              <input
                type="file"
                id="upload"
                multiple
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                onChange={addImageHandler}
                ref={resetImgRef}
              />
            </div>
            {imgArrObj.length>0 && <div className="flex flex-col gap-y-1">
              {imgArrObj.map((item, index) => (
                <div
                  className="p-3 w-full flex justify-between text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  key={index}
                >
                  <span>
                    {item.imgName.length > 50
                      ? `${item.imgName.substring(0, 50)}...`
                      : item.imgName}
                  </span>
                  <button onClick={imageDeleteHandler.bind(null, item.id)}>
                    X
                  </button>
                </div>
              ))}
            </div>}
            <div>
            <label
                htmlFor="price"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Price
              </label>
              <input
                type="number"
                id="price"
                name="price"
                value={quoteFormData.price}
                onChange={inputChangeHandler}
                step={0.01}
                placeholder="Enter price"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              />
            </div>
            <button
              type="submit"
              onClick={submitQuoteHandler}
              className="py-3 px-5 text-sm font-medium text-center border-white dark:text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Submit
            </button>
          </form>
        </div>
      </section>
    </Modal>
  );
};

export default CustomerQuoteForm;
