import React from "react";

const Checkbox = ({ type, item, checked, onChange, disabled }) => {
  const inputChangeHandler = (event) => {
    onChange(event.target.value);
  };

  return (
    <label htmlFor={item.id}>
      <input
        type={type}
        value={item.id}
        id={item.id}
        name="categories"
        checked={checked}
        disabled={disabled}
        onChange={inputChangeHandler}
      />
      <span className={` ml-2 ${checked ? "font-bold text-black" : "text-gray-400"}`}>{item.name}</span>
    </label>
  );
};

export default Checkbox;
