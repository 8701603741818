import React, { useRef, useState } from "react";
import "./CustomersList.scss";
import { api } from "../../../../apis";
import { useParams } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import EditForm from "./EditForm";
import { getOwnerToken } from "../../../../utils/businessDetails";
import useFetch from "../../../hooks/useFetch";
import { getFilteredItems } from "../../../../utils/filterItems";
import { useFilterState } from "../../../Filter/FilterContext";
import Loader from "../../../Loader/Loader";
import Filter from "../../../Filter/Filter";

const CustomersList = () => {
  const params = useParams();
  const {query} = useFilterState();
  const searchParam = useRef(null);
  const {loading, error, data} = useFetch(`/businesses/${params.bid}/admin/users`, null, "owner", params);
  const [isEditFormShown, setIsEditFormShown] = useState(false);
  const [editedUserId, setEditedUserId] = useState("");

  if(error) {
    return alert(error);
  }

  let users = data;
  // console.log(searchParam.current);
  if (data) {
    const filteredItems = getFilteredItems({
      items: data,
      filterBy: [searchParam.current],
      query: query,
    });
    users = filteredItems;  //removing duplicate records
  }

  const updateSearchParam = (searchBy) => {
    searchParam.current = searchBy;
  }

  const editFormShownHandler = (event, id) => {
    event.stopPropagation();
    setIsEditFormShown(true);
    setEditedUserId(id);
  };

  const editFormHideHandler = () => {
    setIsEditFormShown(false);
    setEditedUserId("");
  };

  const submitEditHandler = (editFormData, user_id) => {
    api
      .put(`/businesses/${params.bid}/admin/users/${user_id}`, editFormData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getOwnerToken()}`,
        },
      })
      .then((response) => {
        window.location.reload(); // reload page to reflect changes in table data after submission of the form
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          alert(`${error.message} \nKindly check your connection`);
          return;
        }
        alert(`Error submitting form \n${error.response.data.error_message}`);
      });
  };

  return (
    !loading ?
    <div className="ml-auto bg-white h-screen customerContainer">
      <div className=" flex items-center justify-between px-4 pt-4">
        <div className="">
          <h2 className="text-2xl font-semibold leading-tight">Customers</h2>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md ">
        <div className="p-4">
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
          <div className="relative mt-1">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="table-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5"
              placeholder="Search Users"
            />
          </div>
        </div>
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3 border-r border-gray-300 whitespace-nowrap">
                First Name
              </th>
              <th scope="col" className="px-6 py-3 border-r border-gray-300 whitespace-nowrap">
                Last Name
              </th>
              <th scope="col" className="px-6 py-3 border-r border-gray-300 whitespace-nowrap">
                Phone Number
              </th>
              <th scope="col" className="px-6 py-3 border-r border-gray-300">
                Email
              </th>
              <th scope="col" className="px-6 py-3 border-r border-gray-300">
                Created On
              </th>
              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-1 py-1 border-b border-r border-gray-200 bg-white text-sm w-0.5 overflow-hidden" onClick={() => updateSearchParam("first_name")}>
                <Filter searchType="text" placeholder="" />
              </td>
              <td className="px-1 py-1 border-b border-r border-gray-200 bg-white text-sm w-0.5 overflow-hidden" onClick={() => updateSearchParam("last_name")}>
                <Filter searchType="text" placeholder="" />
              </td>
              <td className="px-1 py-1 border-b border-r border-gray-200 bg-white text-sm w-0.5 overflow-hidden" onClick={() => updateSearchParam("phone")}>
              <Filter searchType="text" placeholder="" />
              </td>
              <td className="px-1 py-1 border-b border-r border-gray-200 bg-white text-sm w-0.5 overflow-hidden" onClick={() => updateSearchParam("email")}>
                <Filter searchType="text" placeholder="" />
              </td>
              <td className="px-1 py-1 border-b border-r border-gray-200 bg-white text-sm w-0.5 overflow-hidden" onClick={() => updateSearchParam("created_on")}>
                <Filter searchType="text" placeholder="" />
              </td>
              <td className="px-1 py-1 border-b border-gray-200 bg-white text-sm w-0.5 overflow-hidden">
              </td>
            </tr>
            {data && users.map((item, index) => (
              <tr key={item.user_id}>
                <td className="px-5 py-5 border-b border-r border-gray-200 bg-white text-sm">
                  <div className="flex items-left">
                    <div className="ml-1">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.first_name}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-r border-gray-200 bg-white text-sm">
                  <div className="flex items-left">
                    <div className="ml-1">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.last_name}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-r border-gray-200 bg-white text-sm">
                  <div className="flex items-left">
                    <div className="ml-1">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.phone}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-r border-gray-200 bg-white text-sm">
                  <div className="flex items-left">
                    <div className="ml-1">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.email}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-r border-gray-200 bg-white text-sm">
                  <div className="flex items-left">
                    <div className="ml-1">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {new Date(item.created_on).toDateString()}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div className="flex item-center justify-center">
                    <button
                      onClick={(event) =>
                        editFormShownHandler(event, item.user_id)
                      }
                      id={item.user_id}
                    >
                      <FiEdit size={20} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            {isEditFormShown && (
              <EditForm
                onHideForm={editFormHideHandler}
                onSubmit={submitEditHandler}
                user={users.find((x) => x.user_id === editedUserId)}
              />
            )}
          </tbody>
        </table>
      </div>
    </div> :
    <div className="w-screen h-screen flex items-center justify-center mx-auto my-auto">
      <Loader />
    </div>
  );
};

export default CustomersList;
