import React, { useState } from "react";
import Switch from "../../components/SwitchSlider/Switch";
import { Link, useParams } from "react-router-dom";
import DeleteModal from "../../components/Modal/DeleteModal";
import { toast } from "react-toastify";
import { getOwnerToken } from "../../../../utils/businessDetails";
import { api } from "../../../../apis";

const Product = ({ item }) => {
  const params = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const changeStatusHandler = (productId, column, status) => {
    const formData = new FormData();

    formData.append(column, column === "is_inactive" ? !status : status);

    // for(const item of formData.entries()) {
    //   // console.log('item', item[0], 'value:', item[1]);
    // }
    api
      .put(`/businesses/${params.bid}/products/${productId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getOwnerToken(),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Product updated successfully");
        } else {
          alert(response.data.message);
        }
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          toast.error(`${error.message} \nKindly check your connection`);
          return;
        }
        // else {
        toast.error(
          `Error updating product \n${error.response.data.error_message}`
        );
        // }
      });
  };

  const deleteProductHandler = (product_id) => {
    setLoading(true);
    api
      .delete(`/businesses/${params.bid}/products/${product_id}`, {
        headers: { Authorization: `Bearer ${getOwnerToken()}` },
      })
      .then((response) => {
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === "ERR_NETWORK") {
          alert(`${error.message} \nKindly check your connection`);
          return;
        }
        alert(`Error \n${error.response.data.error_message}`);
      });
  };

  const toggleDeleteModalHandler = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  return (
    <tr>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <div className="flex items-center">
          <div className="flex-shrink-0 w-10 h-10">
            <img
              className="w-full h-full rounded-full"
              // src={`${process.env.REACT_APP_SERVER_URL}${item.images[0]}`}
              src={`${item.images[0]}`}
              alt="productImage"
            />
          </div>
          <div className="ml-3">
            <p className="text-gray-900 whitespace-no-wrap">{item.name}</p>
          </div>
        </div>
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <p className="text-gray-900 whitespace-no-wrap">{item.sold}</p>
      </td>
      {/* is featured slider */}
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <Switch
          activeText="YES"
          inactiveText="NO"
          mr={20}
          ml={16}
          onclick={changeStatusHandler.bind(null, item.id, "is_featured")}
          isActive={item.is_featured}
        />
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <p className="text-gray-900 whitespace-no-wrap">{item.quantity}</p>
      </td>
      {/* is active slider */}
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
        <Switch
          activeText="Active"
          inactiveText="Inactive"
          mr={6}
          ml={8}
          onclick={changeStatusHandler.bind(null, item.id, "is_inactive")}
          isActive={
            item.is_inactive === null ? item.is_inactive : !item.is_inactive
          }
        />
      </td>
      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        <div className="flex item-center justify-center">
          <Link
            to={`${item.id}`}
            state={{
              id: item.id,
              name: item.name,
              description: item.description,
              photoLinks: item.images,
              price: item.price,
              quantity: item.quantity,
              category_id: item.category[0].category_id,
            }}
            className="w-6 mr-2 transform hover:text-purple-500 hover:scale-110"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </Link>
          <button
            className="w-6 mr-2 transform hover:text-red-500 hover:scale-110"
            onClick={toggleDeleteModalHandler}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </button>
        </div>
      </td>
      {showDeleteModal && (
        <DeleteModal
          itemType="product"
          itemName={item.name}
          closeModalHandler={toggleDeleteModalHandler}
          deleteItemHandler={() => deleteProductHandler(item.id)}
        />
      )}
    </tr>
  );
};

export default Product;
