import { redirect } from "react-router-dom";
import { api } from "../apis";

export function checkBusiness(bid) {
    if(!localStorage.getItem(`${bid}-business`)) {
        return redirect("/select-shop");
    }
    return null;
}

// used in customer side code
export function getCustomerTokenDuration(bid) {
    const storedExpirationDate = localStorage.getItem(`${bid}-customerTokenExpiration`);
    const expirationDate = new Date(storedExpirationDate);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();
    return duration;
};


export function getBusinessModel(bid) {
    return JSON.parse(localStorage.getItem(`${bid}-businessModel`)).business_model;
}

export function getBusinessName(bid) {
    return JSON.parse(localStorage.getItem(`${bid}-businessModel`)).business_name;
}

export function getCustomerDetails(bid) {
    return JSON.parse(localStorage.getItem(`${bid}-customer`));
}

export function setBusinessModel(bid, businessObj) {
    return localStorage.setItem(`${bid}-businessModel`, JSON.stringify(businessObj));
}

export function getDeliveryMode(bid) {
    return JSON.parse(localStorage.getItem(`${bid}-businessModel`)).delivery_mode;
}

export function getCustomerToken(bid) {
    if(!localStorage.getItem(`${bid}-customer`)) {
        return redirect(`/business/${bid}/login`);
    }

    const tokenDuration = getCustomerTokenDuration(bid);
    // // console.log(tokenDuration);
    if(tokenDuration < 0) {
        return "EXPIRED";
    }

    return JSON.parse(localStorage.getItem(`${bid}-customer`)).token;
}

// used in owner side code
export function getOwnerTokenDuration() {
    const storedExpirationDate = localStorage.getItem('ownerTokenExpiration');
    const expirationDate = new Date(storedExpirationDate);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();
    return duration;
};

export function getBusiness(bid) {
    // // console.log(localStorage.getItem(`${bid}-business`));
    if(!localStorage.getItem(`${bid}-business`)) {
        return redirect("/select-shop");
    }
    return JSON.parse(localStorage.getItem(`${bid}-business`)).business_model;
}

export function setBusiness(bid, businessObj) {
    return localStorage.setItem(`${bid}-business`, JSON.stringify(businessObj));
}

export function getOwnerToken() {
    if(!localStorage.getItem("owner")) {
        // console.log(localStorage.getItem("owner"), "getOwnerToken inside");
        return redirect("/login");
    }

    const tokenDuration = getOwnerTokenDuration();
    if(tokenDuration < 0) {
        return "EXPIRED";
    }

    return JSON.parse(localStorage.getItem("owner")).token;
}

export function getOwnerDetails() {
    if(!localStorage.getItem("owner")) {
        return redirect("/login");
    }
    return JSON.parse(localStorage.getItem("owner"));
}

export async function fetchandUpdateBusinessDetails(bid) {
    await api
      .get(`/businesses/${bid}`)
      .then((response) => {
        setBusinessModel(bid, response.data);
      })
      .catch((error) => {

        if(error.code==="ERR_NETWORK") {
            // console.log("running");
            alert(`${error.message} \nKindly check your connection`);
            return;
        }
        if(error) {
              throw new Error();
        }
        // alert("Cannot fetch business - either business does not exist or business id is incorrect");
      });
    return null;
}