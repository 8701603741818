import React, { Fragment, useState } from "react";
import { BiSolidEdit } from "react-icons/bi";
import { TiTickOutline, TiCancel } from "react-icons/ti";
import { PiArrowsCounterClockwiseFill, PiBackspace } from "react-icons/pi";
import { useParams } from "react-router-dom";
import CustomerEnquiryDetails from "./CustomerEnquiryDetails";
import CustomerQuoteForm from "./CustomerQuoteForm";
import { api } from "../../../../../apis";
import { getCustomerToken } from "../../../../../utils/businessDetails";
import  toast, { Toaster } from 'react-hot-toast';
import CurrencyFormat from "react-currency-format";

const CustomerEnquiry = (props) => {
  const [enquiry, setEnquiry] = useState({
    enquiry_id: props.enquiry.enquiry_id,
    status: props.enquiry.status,
    permitted_states: props.enquiry.permitted_states,
    first_name: props.enquiry.first_name,
    last_name: props.enquiry.last_name,
    updated_on: props.enquiry.updated_on,
    price: props.enquiry.price,
    product_name: props.enquiry.name,
    isExpanded: false,
    created_by_customer: props.enquiry.created_by_customer,
    photo: props.enquiry.photo,
  });

  const detailsShowHandler = () => {
    setEnquiry((prevState) => ({
      ...prevState,
      isExpanded: !prevState.isExpanded,
    }));
  };

  const [isQuoteFormShown, setIsQuoteFormShown] = useState(false);
  const [buttonType, setButtonType] = useState("");

  const quoteFormShownHandler = (event, buttonType) => {
    event.stopPropagation();
    setIsQuoteFormShown(true);
    setButtonType(buttonType);
  };

  const quoteFormHideHandler = () => {
    setIsQuoteFormShown(false);
  };

  const params = useParams();
  // const authToken = JSON.parse(localStorage.getItem(`${params.bid}-customer`)).token;
  const authToken = getCustomerToken(params.bid);

  const submitQuoteHandler = (quoteFormData) => {
    const form_data = new FormData();
    form_data.append("status", quoteFormData.status);
    form_data.append("remark", quoteFormData.remark);
    form_data.append("price", quoteFormData.price);
    // form_data.append("images", quoteFormData.photos);
    quoteFormData.photos.map((item) => form_data.append("images", item));

    // for(const x of form_data) {
    //   // console.log(`${x[0]} : ${x[1]}`);
    // }

    api
      .put(
        `/businesses/${params.bid}/enquiries/${enquiry.enquiry_id}`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        window.location.reload(); // reload page to reflect changes in table data after submission of the form
      })
      .catch((error) => {
        if(error.code==="ERR_NETWORK") {
          toast.error(`${error.message} \nKindly check your connection`);
          return;
        }
        toast.error(`Error submitting form \n ${error.response.data.error_message}`);
      });
  };

  const enquiryActionHandler = (event, buttonType) => {
    event.stopPropagation();
    api
      .put(
        `/businesses/${params.bid}/enquiries/${enquiry.enquiry_id}`,
        JSON.stringify({ status: buttonType }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        window.location.reload(); // reload page to reflect changes in table data after submission of the form
      })
      .catch((error) => {
        // // console.log(error.response.data.message);
        if(error.code==="ERR_NETWORK") {
          toast.error(`${error.message} \nKindly check your connection`);
          return;
        }
        toast.error(error.response.data.error_message);
        // toast.error(`Error submitting form \n ${error.response.data.message}`);
      });
  };

  return (
      <>
        <Toaster/>
        <Fragment key={enquiry.enquiry_id}>
        <tr
            key={enquiry.enquiry_id}
            className={`bg-white border-b hover:bg-gray-50 cursor-pointer ${
                enquiry.status === "CANCELLED" && "text-red-900"
            }`}
            onClick={detailsShowHandler}
        >
          {/* <td className="w-4 p-4">
          <div className="flex items-center">
            <input
              id="checkbox-table-search-1"
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="checkbox-table-search-1" className="sr-only">
              checkbox
            </label>
          </div>
        </td> */}
          <td
              className={`px-6 py-4 font-medium ${
                  enquiry.status === "CANCELLED" ? "text-red-900" : "text-gray-900"
              } whitespace-nowrap`}
          >
            {enquiry.first_name + " " + enquiry.last_name}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">{enquiry.enquiry_id}</td>
          <td className="px-6 py-4 whitespace-nowrap">{enquiry.product_name}</td>
          <td className="px-6 py-4 whitespace-nowrap">{enquiry.updated_on.split("T")[0]}</td>
          <td className="px-6 py-4 whitespace-nowrap"> <CurrencyFormat value={enquiry.price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} /></td>
          <td className="px-6 py-4 text-left whitespace-nowrap">
            <div className="flex justify-start gap-4">
              {enquiry.permitted_states.includes("QUOTED") && (
                  <button
                      onClick={(event) => quoteFormShownHandler(event, "QUOTED")}
                      id="QUOTED"
                      className="font-medium text-white hover:underline flex bg-blue-600 border rounded-lg py-1 px-2"
                  >
                    <BiSolidEdit size={20} />
                    Quote
                  </button>
              )}
              {enquiry.permitted_states.includes("ACCEPTED") && (
                  <button
                      id="ACCEPTED"
                      onClick={(event) => enquiryActionHandler(event, "ACCEPTED")}
                      className="font-medium text-white hover:underline flex bg-green-600 border rounded-lg py-1 px-2"
                  >
                    <TiTickOutline size={20} />
                    Accept
                  </button>
              )}
              {enquiry.permitted_states.includes("COUNTERED") && (
                  <button
                      onClick={(event) => quoteFormShownHandler(event, "COUNTERED")}
                      id="COUNTERED"
                      className="font-medium text-white hover:underline flex bg-blue-600 border rounded-lg py-1 px-2"
                  >
                    <PiArrowsCounterClockwiseFill size={20} />
                    Counter
                  </button>
              )}
              {enquiry.permitted_states.includes("CANCELLED") && (
                  <button
                      id="CANCELLED"
                      onClick={(event) => quoteFormShownHandler(event, "CANCELLED")}
                      className="font-medium text-white hover:underline flex bg-red-600 border rounded-lg py-1 px-2"
                  >
                    <TiCancel size={20} />
                    Cancel
                  </button>
              )}
              {enquiry.permitted_states.includes("UNCANCELLED") && (
                  <button
                      id="UNCANCELLED"
                      onClick={(event) => enquiryActionHandler(event, "UNCANCELLED")}
                      className="font-medium text-white hover:underline flex bg-purple-600 border rounded-lg py-1 px-2"
                  >
                    <PiBackspace size={20} />
                    Uncancel
                  </button>
              )}
              {enquiry.permitted_states.includes("CANCELLED_BY_OWNER") && (
                  <div
                      onClick={(event) => (event.stopPropagation())}
                      className="font-medium hover:cursor-default text-red-900"
                  >
                    Cancelled by Owner
                  </div>
              )}
              {enquiry.status==="ACCEPTED" && (enquiry.created_by_customer===true ?
                  <div
                      onClick={(event) => (event.stopPropagation())}
                      className="font-medium hover:cursor-default text-green-700">
                    Accepted by you
                  </div> :
                  <div
                      onClick={(event) => (event.stopPropagation())}
                      className="font-medium hover:cursor-default text-green-700">
                    Accepted by owner!
                  </div>)
              }
              {enquiry.status === "CREATED_ORDER" && (
                  <div
                      onClick={(event) => (event.stopPropagation())}
                      className="font-medium hover:cursor-default text-cyan-700">
                    Order created by owner
                  </div>
              )}
            </div>
          </td>
        </tr>
        <tr>
          <CustomerEnquiryDetails
              isDetailsShown={enquiry.isExpanded}
              enquiryId={enquiry.enquiry_id}
              onClickQuote={props.quoteFormShownHandler}
          />
        </tr>
        {isQuoteFormShown && (
            <CustomerQuoteForm
                onHideForm={quoteFormHideHandler}
                onSubmitQuote={submitQuoteHandler}
                buttonType={buttonType}
            />
        )}
      </Fragment>
      </>

  );
};

export default CustomerEnquiry;
