import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useParams, useRouteLoaderData, useSubmit } from "react-router-dom";
import { cartActions } from "../../redux/reducers/cartReducer";
import { api } from "../../../apis";
import { getCustomerTokenDuration, setBusinessModel } from "../../../utils/businessDetails";

const  CartLayout = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // // console.log(useLocation());
  const cart = localStorage.getItem(`${params.bid}-cart`);
  if (cart !== null) {
    const localStorageProducts = JSON.parse(cart).products;
    dispatch(cartActions.updateCart(localStorageProducts));
  }

  // const onFocus = useCallback(() => {
  //   if (!document.hidden) {
  //     api
  //       .get(`/businesses/${params.bid}`)
  //       .then((response) => {
  //         localStorage.setItem("businessModel", response.data.business_model);
  //       })
  //       .catch((error) => {
  //         alert("Cannot fetch business model");
  //       });
  //   }
  // }, [params.bid]);

  // useEffect(() => {
    // window.addEventListener("visibilitychange", onFocus);
    // return () => {
    //   window.removeEventListener("visibilitychange", onFocus);
    // };
    // }, [onFocus]);
  //   api
  //     .get(`/businesses/${params.bid}`, {headers: {
  //       "Content-Type": "application/json",
  //     }})
  //     .then((response) => {
  //       setBusinessModel(params.bid, response.data);
  //     })
  //     .catch((error) => {
  //       if(error.code==="ERR_NETWORK") {
  //         alert(`${error.message} \nKindly check your connection`);
  //         return;
  //       }
  //       alert(`Cannot fetch business - either business does not exist or business id is incorrect \n${error.response.data.error_message}`);
  //       // window.location.reload();
  //     });
  // }, [params.bid]);

  // const id = useLocation().pathname;
  // // console.log("id", id);
  // const token = useRouteLoaderData("");
  // // console.log("token", token);
  // const submit = useSubmit();

  // useEffect(() => {
  //   if(!token) {
  //     return;
  //   }

  //   // console.log("inside cart layout");

  //   if (token === "EXPIRED") {
  //     alert("Token expired \nLogin to continue");
  //     submit(null, { action: `/business/${params.bid}/logout`, method: "get" });
  //     return;
  //   }

  //   const tokenDuration = getCustomerTokenDuration();

  //   setTimeout(() => {
  //     alert("Token expired \nLogin to continue");
  //     submit(null, { action: `/business/${params.bid}/logout`, method: "get" });
  //   }, tokenDuration);
  // // }, [token, submit, params.bid]);
  // });

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default CartLayout;
