import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [], //array of products inside cart
};

// const params = useParams();

const cartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const businessModel = JSON.parse(localStorage.getItem(`${window.location.pathname.split('/')[2]}-businessModel`)).business_model;
      if (businessModel === "product-based") {
        const item = state.products.find(
          (item) => item.id === action.payload.id
        ); //payload will contain object

        if (item === undefined) {
          state.products.push(action.payload);
        } else {
          item.quantity += action.payload.quantity;
        }
      } else {
        state.products.push(action.payload);
      }
    },

    removeItem: (state, action) => {
      const businessModel = JSON.parse(localStorage.getItem(`${window.location.pathname.split('/')[2]}-businessModel`)).business_model;
      if (businessModel === "product-based") {
        state.products = state.products.filter(
          (item) => item.id !== action.payload
        ); //payload will contain id
      } else {
        state.products = state.products.filter(
          (item, index) => index !== action.payload
        );
      }
    },

    resetCart: (state) => {
      state.products = [];
    },

    updateCart: (state, action) => {
      state.products = [...action.payload];
    },
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice.reducer;
