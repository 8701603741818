import React, { useState } from 'react';
import Modal from '../../UI/Modal';
import { useParams } from 'react-router-dom';
import { getCustomerDetails, getCustomerToken } from '../../../../utils/businessDetails';
import { api } from '../../../../apis';

const UpdateAddressForm = ({savedAddresses, selectedAddressId, onClose}) => {
  const params = useParams();
  const [focus, setFocus] = useState({
    name: false,
    mobileNo: false,
    pinCode: false,
    city: false,
    state: false,
    address: false,
  });

  const selectedAddress = savedAddresses.filter((item) => item.address_id===parseInt(selectedAddressId));

  const [addressFormData, setAddressFormData] = useState({
    name: selectedAddress[0].name,
    mobileNo: selectedAddress[0].phone_no,
    pinCode: selectedAddress[0].pincode,
    city: selectedAddress[0].city,
    state: selectedAddress[0].state,
    address: selectedAddress[0].address,
    isDefault: selectedAddress[0].is_default,
  });

  const inputFocusHandler = (event) => {
    setFocus((prevState) => ({
      ...prevState,
      [event.target.name]: true,
    }));
  };

  const inputBlurHandler = (event) => {
    setFocus((prevState) => ({
      ...prevState,
      [event.target.name]: false,
    }));
  };

  const inputChangeHandler = (event) => {
    setAddressFormData((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.name === "isDefault"
          ? event.target.checked
          : event.target.value,
    }));
  };

  const [isLoading, setIsLoading] = useState(false);

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = {
      user_id: getCustomerDetails(params.bid).user.id,
      address_id: selectedAddressId,
      name: addressFormData.name,
      phone_no: addressFormData.mobileNo,
      address_line: addressFormData.address,
      state: addressFormData.state,
      city: addressFormData.city,
      pincode: addressFormData.pinCode,
      is_default: addressFormData.isDefault,
    };

    api.put(`/businesses/${params.bid}/address`, JSON.stringify(formData), { headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getCustomerToken(params.bid)}`,
      }
    }).then((response) => {
      setIsLoading(false);
      window.location.reload();
    }).catch((error) => {
      if (error.code === "ERR_NETWORK") {
        alert(error.message);
        setIsLoading(false);
        return;
      }
      alert(error.response.data.error_message);
      setIsLoading(false);
    });
  }

  return (
    <Modal onBackdropClick={onClose}>
      <form
        className="bg-white max-w-2xl w-[460px] mt-4 rounded-md border overflow-hidden"
        onSubmit={formSubmitHandler}
      >
        <header className="sticky top-0 bg-white z-30">
          <div className="flex justify-between items-center px-4 py-4">
            <p className="font-bold text-gray-500 text-sm">
              UPDATE ADDRESS
            </p>
            <button
              onClick={onClose}
              className="text-3xl font-extralight"
            >
              X
            </button>
          </div>
          <hr className="w-full" />
        </header>
        <div className="px-4 h-96 overflow-y-auto">
          <div className="mt-4">
            <p className="text-sm mb-4">
              <strong>CONTACT DETAILS</strong>
            </p>
            <div
              className={`border rounded-md mb-4 relative ${
                focus.name && "border-black"
              }`}
            >
              <input
                placeholder={focus.name ? "" : "Name*"}
                name="name"
                value={addressFormData.name}
                required
                onFocus={(event) => inputFocusHandler(event)}
                onBlur={(event) => inputBlurHandler(event)}
                onChange={(event) => inputChangeHandler(event)}
                className="w-full outline-none px-2 py-3 font-light rounded-md text-sm"
              />
              {(focus.name || addressFormData.name.length > 0) && (
                <label
                  className={`absolute -top-2.5 left-2 bg-white px-1 font-light text-sm ${
                    !focus.name &&
                    addressFormData.name.length > 0 &&
                    "text-gray-500"
                  }`}
                >
                  Name*
                </label>
              )}
            </div>
            <div className="border rounded-md relative">
              <input
                placeholder={focus.mobileNo ? "" : "Mobile No.*"}
                name="mobileNo"
                value={addressFormData.mobileNo}
                required
                className="w-full outline-none px-2 py-3 font-light rounded-md text-sm"
                onFocus={(event) => inputFocusHandler(event)}
                onBlur={(event) => inputBlurHandler(event)}
                onChange={(event) => inputChangeHandler(event)}
              />
              {(focus.mobileNo || addressFormData.mobileNo.length > 0) && (
                <label
                  className={`absolute -top-2.5 left-2 bg-white px-1 font-light text-sm ${
                    !focus.mobileNo &&
                    addressFormData.mobileNo.length > 0 &&
                    "text-gray-500"
                  }`}
                >
                  Mobile No.*
                </label>
              )}
            </div>
          </div>
          <div>
            <p className="text-sm mb-4 mt-8">
              <strong>ADDRESS</strong>
            </p>
            <div className="border rounded-md mb-4 relative">
              <input
                placeholder={focus.pinCode ? "" : "Pin Code*"}
                name="pinCode"
                value={addressFormData.pinCode}
                required
                className="w-full outline-none px-2 py-3 font-light rounded-md text-sm"
                onFocus={(event) => inputFocusHandler(event)}
                onBlur={(event) => inputBlurHandler(event)}
                onChange={(event) => inputChangeHandler(event)}
              />
              {(focus.pinCode || addressFormData.pinCode.length > 0) && (
                <label
                  className={`absolute -top-2.5 left-2 bg-white px-1 font-light text-sm ${
                    !focus.pinCode &&
                    addressFormData.pinCode.length > 0 &&
                    "text-gray-500"
                  }`}
                >
                  Zip Code*
                </label>
              )}
            </div>
            <div className="border rounded-md mb-4 relative">
              <input
                placeholder={
                  focus.address
                    ? ""
                    : "Address (House No, Building, Street, Area)*"
                }
                name="address"
                value={addressFormData.address}
                required
                className="w-full outline-none px-2 py-3 font-light rounded-md text-sm"
                onFocus={(event) => inputFocusHandler(event)}
                onBlur={(event) => inputBlurHandler(event)}
                onChange={(event) => inputChangeHandler(event)}
              />
              {(focus.address || addressFormData.address.length > 0) && (
                <label
                  className={`absolute -top-2.5 left-2 bg-white px-1 font-light text-sm ${
                    !focus.address &&
                    addressFormData.address.length > 0 &&
                    "text-gray-500"
                  }`}
                >
                  Address (House No, Building, Street, Area)*
                </label>
              )}
            </div>
            <div className="border rounded-md mb-4 relative">
              <input
                placeholder={focus.city ? "" : "City*"}
                name="city"
                value={addressFormData.city}
                required
                className="w-full outline-none px-2 py-3 font-light rounded-md text-sm"
                onFocus={(event) => inputFocusHandler(event)}
                onBlur={(event) => inputBlurHandler(event)}
                onChange={(event) => inputChangeHandler(event)}
              />
              {(focus.city || addressFormData.city.length > 0) && (
                <label
                  className={`absolute -top-2.5 left-2 bg-white px-1 font-light text-sm ${
                    !focus.city &&
                    addressFormData.city.length > 0 &&
                    "text-gray-500"
                  }`}
                >
                  City*
                </label>
              )}
            </div>
            <div className="border rounded-md mb-4 relative">
              <input
                placeholder={focus.state ? "" : "State*"}
                name="state"
                value={addressFormData.state}
                required
                className="w-full outline-none px-2 py-3 font-light rounded-md text-sm"
                onFocus={(event) => inputFocusHandler(event)}
                onBlur={(event) => inputBlurHandler(event)}
                onChange={(event) => inputChangeHandler(event)}
              />
              {(focus.state || addressFormData.state.length > 0) && (
                <label
                  className={`absolute -top-2.5 left-2 bg-white px-1 font-light text-sm ${
                    !focus.state &&
                    addressFormData.state.length > 0 &&
                    "text-gray-500"
                  }`}
                >
                  State*
                </label>
              )}
            </div>
          </div>
          <div className="flex gap-4 mt-8 items-center mb-6">
            <input
              type="checkbox"
              name="isDefault"
              id="isDefault"
              checked={addressFormData.isDefault}
              onChange={(event) => inputChangeHandler(event)}
            />
            <label htmlFor="isDefault" className="font-light">
              Make this my default address
            </label>
            <br />
          </div>
        </div>
        <footer className="p-2 shadow-[0_10px_10px_10px_rgba(0,0,0,0.3)] sticky bottom-0">
          <button
            type="submit"
            className={`py-2 text-sm w-full text-white rounded-md ${isLoading ? "bg-gray-700" : "bg-pink-700 text-white"}`}
            disabled={isLoading}
          >
            UPDATE
          </button>
        </footer>
      </form>
    </Modal>
  );
};

export default UpdateAddressForm;