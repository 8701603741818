import React, {Fragment, useState} from "react";
import { useParams } from "react-router-dom";
import { getBusinessModel } from "../../../../../utils/businessDetails";
import CurrencyFormat from "react-currency-format";

const CustomerSingleOrder = (props) => {
    const params = useParams();
    const businessModel = getBusinessModel(params.bid);
  const convertDate = (date) => {
    const monthString = new Date(date).toLocaleDateString("default", {
      month: "short",
    });
    const dayString = new Date(date).toLocaleDateString("default", {
      weekday: "short",
    });

    const dateString =
      dayString +
      ", " +
      date.split("-")[2] +
      " " +
      monthString +
      " " +
      date.split("-")[0];
    return dateString;
  };

  window.scrollTo(0, 0);


  const [statusDropdown, setStatusDropdown] = useState(false);
  const [selectedDropdownId, setSelectedDropdownId] = useState("");

  const toggleDropdownHandler = (event, dropdownId) => {
    event.stopPropagation();

    setStatusDropdown(!statusDropdown);
    setSelectedDropdownId(dropdownId);
  };

  const getDropdownId = (order_item) => {
    if(businessModel === "service-based"){
      return order_item.order_item_id;
    } else {
      return order_item.product_id;
    }

  }

  return (
    <div className="mt-4 p-1 bg-gray-200">
      <div className="bg-white p-4">
        <header>
          <div className="flex justify-between mb-4">
            <div className="flex gap-12">
              <div>
                <p className="text-gray-500">ORDER PLACED</p>
                <p>{convertDate(props.order.created_on.split("T")[0])}</p>
              </div>
              <div>
                <p className="text-gray-500">TOTAL</p>
                <CurrencyFormat value={props.order.total} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <p>{value}</p>} />
              </div>
            </div>
            <div className="flex gap-2">
              <p className="text-gray-500">ORDER #</p>
              <p>{props.order.order_id}</p>
            </div>
            {props.order.paid!==props.order.total &&
                <div className="flex items-center gap-8">
              <div className="flex flex-col items-center justify-center">
                <p>Amount Due:</p><CurrencyFormat value={props.order.total - props.order.paid} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />

              </div>
              <div>
                <button className="bg-red-800 text-white rounded-3xl p-2 px-8">
                  Pay Now
                </button>
              </div>
            </div>}
          </div>
          <hr />
        </header>
        {props.order.order_items.map((order_item, index) => (
          <Fragment key={index}>

            <div className="bg-gray-200 mt-4">
              <div className="flex gap-8 items-center p-4 ">
                <img
                  src={order_item.image}
                  alt="product"
                  className="w-16 h-16"
                />
                <div className="flex items-center justify-between flex-1 ">
                  <div>
                    <p className="font-bold">{order_item.name}</p>
                    <p>{order_item.description}</p>
                    <p className="">
                      <span className="text-sm text-gray-600">Qty: </span>
                      <span className="text-sm font-bold">{order_item.grouped_items.length}</span>
                      <span className="text-sm text-gray-600 ml-8">Price: </span>
                      <span className="text-sm font-bold">
                         <CurrencyFormat value={order_item.price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />

                      </span>
                      {/* <span className="mr-2">${order_item.price}</span>
                      <span>x{1}</span> */}
                    </p>


                    <div className = "flex flex-row hover:cursor-pointer" >
                      {selectedDropdownId === getDropdownId(order_item) && statusDropdown ?
                          <p  className="text-blue-500 active:text-blue-600 " onClick={(event) => (toggleDropdownHandler(event,getDropdownId(order_item) ))}>
                            hide status
                          </p>
                          :
                          <p  className="text-blue-500 active:text-blue-600 " onClick={(event) => (toggleDropdownHandler(event,getDropdownId(order_item) ))}>
                            view status
                          </p>
                      }

                    </div>
                  </div>


                </div>


              </div>
              { selectedDropdownId === getDropdownId(order_item) && statusDropdown &&
                  <div className="flex justify-center">

                    <table className="w-11/12 text-sm text-left text-gray-500">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          S.No
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Order Item Id
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Status
                        </th>



                      </tr>
                      </thead>
                      <tbody>

                      { order_item.grouped_items.map( (i, index) => (

                          <tr>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <div className="flex items-left">
                                <div className="ml-1">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    {index + 1}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <div className="flex items-left">
                                <div className="ml-1">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    { i.order_item_id}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <div className="flex items-left">
                                <div className="ml-1">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    {i.current_status}
                                  </p>
                                </div>
                              </div>
                            </td>



                          </tr>

                      ) )}


                      </tbody>
                    </table>
                  </div>
              }


              <div className="bg-white h-1" />
              {/* <div className="p-4 flex gap-8 items-center">
                <p className="text-gray-600 font-light text-sm">Rate Product</p>
                <div className="flex items-center gap-2">
                  <FaStar
                    size={30}
                    className="text-gray-100 hover:text-pink-700"
                  />
                  <FaStar
                    size={30}
                    className="text-gray-100 hover:text-pink-700"
                  />
                  <FaStar
                    size={30}
                    className="text-gray-100 hover:text-pink-700"
                  />
                  <FaStar
                    size={30}
                    className="text-gray-100 hover:text-pink-700"
                  />
                  <FaStar
                    size={30}
                    className="text-gray-100 hover:text-pink-700"
                  />
                </div>
              </div> */}
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default CustomerSingleOrder;
