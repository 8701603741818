import React, { useEffect, useState } from "react";
import AddAddressForm from "./AddressForm";
import { useParams } from "react-router-dom";
import { getOwnerToken } from "../../../../../utils/businessDetails";
import { api } from "../../../../../apis";

const Address = () => {
  const params = useParams();
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");

  const showUpdateAddressHandler = (addressId) => {
    const selected_address = addresses.filter((item) => (item.id===addressId));
    setSelectedAddress({...selected_address[0], buttonType: "UPDATE"});
    setShowAddressForm(true);
  };

  const showAddAddressHandler = () => {
    setShowAddressForm(true);
  };

  const hideAddAddressHandler = () => {
    setShowAddressForm(false);
    window.location.reload();
  };

  const deleteAddressHandler = (addressId) => {
    api.delete(`/businesses/${params.bid}/admin/address`, {params: {address_id: addressId}, headers: {
      'Authorization': `Bearer ${getOwnerToken()}`
    }}).then((response) => {
      window.location.reload();
    }).catch((error) => {
      if(error.code==="ERR_NETWORK") {
        alert(`${error.message} \nKindly check your connection`);
        return;
      }
      alert(error.response.data.error_message);
    });
  }

  useEffect(() => {
    api.get(`/businesses/${params.bid}/admin/addresses`, {Headers: {
      Authorization : `Bearer ${getOwnerToken()}`,
    }}).then((response) => {
      setAddresses(response.data);
    }).catch((error) => {
      if(error.code==="ERR_NETWORK") {
        alert(`${error.message} \nKindly check your connection`);
        return;
      }
      alert(error.response.data.error_message);
    });
  }, [params.bid]);

  useEffect(() => {
    setSelectedAddress("");
  }, []);

  return (
    <div className="bg-white shadow-lg rounded-md p-4 mt-4">
      <h1 className="text-xl text-blue-500 border-b pb-2">
        <strong>Update pick-up or store address</strong>
      </h1>
      <div className="flex justify-between items-center max-[350px]:flex-col">
      <p className="mt-2">
        <span className="text-gray-500">Saved addresses </span>
      </p>
      <button
        className="border rounded-md px-4 py-2 border-gray-700 text-gray-600 text-xs font-bold mt-4"
        onClick={showAddAddressHandler}
      >
        ADD NEW ADDRESS
      </button>
      </div>
        <div className="mt-4">
            {addresses.map((address, index) => (
              <div
                key={index}
                className={`flex items-baseline gap-4 border p-4 hover:cursor-pointer rounded-md mb-2 ${
                  // parseInt(checked) === address.address_id &&
                  "shadow-[0_0px_5px_1px_rgba(0,0,0,0.1)]"
                }`}
              >
                {/* <input
                  type="radio"
                  id={address.address_id}
                  name="address"
                  value={address.address_id}
                  className="hover:cursor-pointer"
                  onChange={(event) => addressChangeHandler(event)}
                /> */}
                {/* <label
                  htmlFor={address.address_id}
                  className="hover:cursor-pointer text-sm"
                > */}
                <div>
                  <p className="font-bold mb-4">{address.store_name}</p>
                  <p className="mb-4 font-light text-gray-600">
                    {`${address.address}, ${address.city}, ${address.state}, ${address.pincode}`}
                  </p>
                  <p className="mb-4">
                    <span className="font-light text-gray-600">Mobile: </span>
                    <span className="font-bold">+1{address.phone_no}</span>
                  </p>
                  {/* {parseInt(checked) === address.address_id && ( */}
                    <>
                      <button
                        className="border px-4 py-1 rounded-md border-black text-sm font-bold text-gray-800"
                        onClick={() => deleteAddressHandler(address.id)}
                      >
                        REMOVE
                      </button>
                      <button className="border px-4 py-1 rounded-md border-black text-sm font-bold text-gray-800 ml-4"
                      onClick={() => showUpdateAddressHandler(address.id)}>
                        EDIT
                      </button>
                    </>
                  {/* )} */}
                </div>
              </div>
            ))}
          </div>
      {/* Add new address form */}
      {showAddressForm && <AddAddressForm onClose={hideAddAddressHandler} selectedAddress={selectedAddress} />}
    </div>
  );
};

export default Address;
