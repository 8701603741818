import React from "react";
import {
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillFacebook,
} from "react-icons/ai";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="w-full bg-black">
      <div className="footerHome">
        <div className="top">
          <div className="left">
            <img
              src="https://st2.depositphotos.com/3867453/12012/v/600/depositphotos_120126908-stock-illustration-letter-g-number-6-logo.jpg"
              alt="company logo"
            />
          </div>
          <div className="right">
            <div className="item">
              <h1>GShop</h1>
              <span>About</span>
              <span>Legal</span>
            </div>
            <div className="item">
              <h1>Support</h1>
              <span>Merchant support</span>
              <span>Help center</span>
            </div>
            <div className="item">
              <h1>Developers</h1>
              <span>GShop.dev</span>
              <span>Documentation</span>
            </div>
            <div className="item">
              <h1>Solutions</h1>
              <span>Online store builder</span>
              <span>Website builder</span>
              <span>Ecommerce website</span>
            </div>
          </div>
        </div>
        <hr />
        <div className="bottom">
          <div className="left">
            <span>Terms of Service</span>
            <span>Privacy Policy</span>
            <span>Sitemap</span>
          </div>
          <div className="right">
            <ul>
              <li>
                <AiFillFacebook cursor="pointer" color={"white"} />
              </li>
              <li>
                <AiFillInstagram cursor="pointer" color={"white"} />
              </li>
              <li>
                <AiFillLinkedin cursor="pointer" color={"white"} />
              </li>
              <li>
                <AiOutlineTwitter cursor="pointer" color={"white"} />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
