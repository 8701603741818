import React, { Fragment, useState } from "react";
import "./SingleOrder.scss";
import { BiSolidEdit } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { getBusiness, getOwnerToken } from "../../../../utils/businessDetails";
import RecordPaymentForm from "./RecordPaymentForm";
import { TiCancel, TiTickOutline } from "react-icons/ti";
import { api } from "../../../../apis";
import CurrencyFormat from "react-currency-format";

const SingleOrder = (props) => {
  const params = useParams();
  const businessModel = getBusiness(params.bid);
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [selectedDropdownId, setSelectedDropdownId] = useState("");
  // const [orderItemState, setOrderItemState] = useState("");
  const [isFormShown, setIsFormShown] = useState(false);

  // console.log(props)
  const convertDate = (date) => {
    const monthString = new Date(date).toLocaleDateString("default", {
      month: "short",
    });
    const dayString = new Date(date).toLocaleDateString("default", {
      weekday: "short",
    });

    const dateString =
      dayString +
      ", " +
      date.split("-")[2] +
      " " +
      monthString +
      " " +
      date.split("-")[0];
    return dateString;
  };

  const toggleDropdownHandler = (event, dropdownId) => {
    event.stopPropagation();
    // if (id === selectedOrderItemId) {
    //   setStatusDropdown(!statusDropdown);
    // } else {
    //   setStatusDropdown(true);
    //   setSelectedOrderItemId(id);
    // }
    setStatusDropdown(!statusDropdown);
    setSelectedDropdownId(dropdownId);
  };

  // const changeOrderItemStateHandler = (event) => {
  //   setOrderItemState(event.target.value);
  //   // console.log(event.target.value);
  // };

  // const closeStatusChangeHandler = () => {
  //   setStatusDropdown(false);
  //   setOrderItemState("");
  // };

  const orderActionHandler = (event, status, item_id) => {
    event.stopPropagation();
    const authToken = getOwnerToken();

    // console.log("hello" + item_id);

    api
      .put(
        `/businesses/${params.bid}/admin/order`,
        JSON.stringify({ status: status, order_item_id: item_id }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        window.location.reload(); // reload page to reflect changes in table data after submission of the form
      })
      .catch((error) => {
        // console.log(error.response.data.message);
        if (error.code === "ERR_NETWORK") {
          alert(`${error.message} \nKindly check your connection`);
          return;
        }
        alert(`Error submitting form \n ${error.response.data.error_message}`);
      });
  };

  const showFormHandler = () => {
    setIsFormShown(true);
  };

  const hideFormHandler = () => {
    setIsFormShown(false);
  };

  const getDropdownId = (order_item) => {
    if (businessModel === "service-based") {
      return order_item.order_item_id;
    } else {
      return order_item.product_id;
    }
  };

  return (
    <div className="mt-4 p-1 bg-gray-200">
      <div className="bg-white">
        <header className="p-4">
          <div className="md:flex md:flex-row justify-between mb-4 flex flex-col-reverse">
            <div className="md:flex md:flex-row md:gap-12 flex flex-col">
              <div className="md:flex md:flex-col items-start md:justify-start flex flex-row justify-between max-[350px]:flex-col">
                <p className="text-gray-500">ORDER PLACED</p>
                <p>{convertDate(props.order.created_on.split("T")[0])}</p>
              </div>
              <div className="md:flex md:flex-col items-start md:justify-start flex flex-row justify-between max-[350px]:flex-col">
                <p className="text-gray-500">TOTAL</p>

                  <CurrencyFormat value={props.order.total} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <p>{value}</p>} />

              </div>
              <div className="md:flex md:flex-col items-start md:justify-start flex flex-row justify-between max-[350px]:flex-col">
                <p className="text-gray-500">Customer Name</p>
                <p>{props.customerName}</p>
              </div>
            </div>
            <div className="md:flex-col items-start md:justify-start flex justify-between flex-col-reverse">
              <div className="flex md:gap-2 justify-between w-full max-[350px]:flex-col">
                <p className="text-gray-500">ORDER #</p>
                <p>{props.order.order_id}</p>
              </div>
              <div className="w-full">
                <div className="flex md:gap-2 justify-between ">
                  <div>
                    Amount Due:
                    <CurrencyFormat value={props.order.total - props.order.paid} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
                  </div>
                  <div>
                  {props.order.paid !== props.order.total && (
                    <button
                      className="bg-red-600 text-white px-2 rounded-md"
                      onClick={showFormHandler}
                    >
                      Record Payment
                    </button>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </header>
        {props.order.order_items.map((order_item, index) => (
          <Fragment key={index}>
            <div className="py-1 px-4">
              <div className="bg-gray-200 mt-1">
                <div className="flex gap-8 items-center p-4 ">
                  <img
                    src={order_item.image[0]}
                    alt="product"
                    className="w-16 h-16"
                  />
                  <div className="flex items-center justify-between flex-1 ">
                    <div>
                      <p className="font-bold">{order_item.name}</p>
                      <p>{order_item.description}</p>
                      <div className="flex gap-8 max-[370px]:flex-col max-[370px]:justify-start max-[370px]:gap-0">
                        <div>
                        <span className="text-sm text-gray-600">Qty: </span>
                        <span className="text-sm font-bold">
                          {order_item.grouped_items.length}
                        </span>
                        </div>
                        <div>
                        <span className="text-sm text-gray-600">
                          Price:{" "}
                        </span>
                        <span className="text-sm font-bold">
                          <CurrencyFormat value={order_item.price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
                        </span>
                        </div>
                        {/* <span className="mr-2">${order_item.price}</span>
                      <span>x{1}</span> */}
                      </div>
                      <div className="flex flex-row hover:cursor-pointer">
                        {selectedDropdownId === getDropdownId(order_item) &&
                        statusDropdown ? (
                          <p
                            className="text-blue-500 active:text-blue-600 "
                            onClick={(event) =>
                              toggleDropdownHandler(
                                event,
                                getDropdownId(order_item)
                              )
                            }
                          >
                            hide status
                          </p>
                        ) : (
                          <p
                            className="text-blue-500 active:text-blue-600 "
                            onClick={(event) =>
                              toggleDropdownHandler(
                                event,
                                getDropdownId(order_item)
                              )
                            }
                          >
                            view status
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {selectedDropdownId === getDropdownId(order_item) &&
                  statusDropdown && (
                    <div className=" overflow-x-scroll">
                      <table className="sm:w-11/12 text-sm text-left text-gray-500 w-full overflow-x-scroll mx-auto">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              S.No
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Order Item Id
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Status
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {order_item.grouped_items.map((i, index) => (
                            <tr>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div className="flex items-left">
                                  <div className="ml-1">
                                    <p className="text-gray-900 whitespace-no-wrap">
                                      {index + 1}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div className="flex items-left">
                                  <div className="ml-1">
                                    <p className="text-gray-900 whitespace-no-wrap">
                                      {i.order_item_id}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div className="flex items-left">
                                  <div className="ml-1">
                                    <p className="text-gray-900 whitespace-no-wrap">
                                      {i.current_status}
                                    </p>
                                  </div>
                                </div>
                              </td>

                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div className="flex items-left">
                                  <div className="ml-1">
                                    <div className="flex justify-start gap-4">
                                      {i.permitted_states.includes(
                                        "in-progress"
                                      ) && (
                                        <button
                                          onClick={(event) =>
                                            orderActionHandler(
                                              event,
                                              "in-progress",
                                              i.order_item_id
                                            )
                                          }
                                          id="in-progress"
                                          className="font-medium text-white hover:underline flex bg-blue-600 border rounded-lg py-1 px-2"
                                        >
                                          <BiSolidEdit size={20} />
                                          In Progress
                                        </button>
                                      )}
                                      {i.permitted_states.includes(
                                        "completed"
                                      ) && (
                                        <button
                                          id="completed"
                                          onClick={(event) =>
                                            orderActionHandler(
                                              event,
                                              "completed",
                                              i.order_item_id
                                            )
                                          }
                                          className="font-medium text-white hover:underline flex bg-green-600 border rounded-lg py-1 px-2"
                                        >
                                          <TiTickOutline size={20} />
                                          Completed
                                        </button>
                                      )}
                                      {(i.permitted_states.includes(
                                        "cancelled-by-owner"
                                      ) ||
                                        i.permitted_states.includes(
                                          "cancelled-by-customer"
                                        )) && (
                                        <button
                                          id="cancelled"
                                          onClick={(event) =>
                                            orderActionHandler(
                                              event,
                                              i.permitted_states.includes(
                                                "cancelled-by-owner"
                                              )
                                                ? "cancelled-by-owner"
                                                : "cancelled-by-customer",
                                              i.order_item_id
                                            )
                                          }
                                          className="font-medium text-white hover:underline flex bg-red-600 border rounded-lg py-1 px-2"
                                        >
                                          <TiCancel size={20} />
                                          Cancelled
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              </div>
            </div>
          </Fragment>
        ))}
      </div>
      {isFormShown && (
        <RecordPaymentForm
          order_id={props.order.order_id}
          onHideForm={hideFormHandler}
        />
      )}
    </div>
  );
};

export default SingleOrder;
