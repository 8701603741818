import React, { useEffect, useState } from "react";
import "./SingleProduct.scss";
import { BiArrowBack } from "react-icons/bi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from "../../../../apis";
import RecursiveComponent from "./RecursiveComponent";
import  toast, { Toaster } from 'react-hot-toast';

const SingleProduct = () => {
  const navigate = useNavigate();
  const productObj = useLocation().state;
  const [categories, setCategories] = useState([]);
  const [productImg, setProductImg] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [isInventoryOpened, setIsInventoryOpened] = useState(false);
  const [productFormData, setProductFormData] = useState({
    name: productObj.name,
    description: productObj.description,
    price: productObj.price,
    category_name: "",
    category_id: productObj.category_id,
    // sub_category_id: "",
    quantity: productObj.quantity,
    photos: [],
    deleteImages: [],
  });
  const params = useParams();
  const authToken = JSON.parse(localStorage.getItem("owner")).token;

  // const parseURI = async(file) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   return new Promise((resolve, reject) => {
  //     reader.onload = (e) => {
  //       resolve(e.target.result);
  //     }
  //   });
  // };

  const addImageHandler = async (event) => {
    const files = event.target.files;
    const filesObj = [];

    Array.from(files).forEach((file) =>
      filesObj.push({ image: file, id: Math.random() * 100 })
    );
    setProductFormData((prevState) => ({
      ...prevState,
      photos: filesObj,
    }));
    // const base64img = await parseURI(file);
    // const imgObj = {
    //   src: base64img,
    //   id: Math.random() * 10,
    // };

    const imgLinkArr = [...productImg];

    filesObj.forEach((fileObj) =>
      imgLinkArr.push({
        src: URL.createObjectURL(fileObj.image),
        id: fileObj.id,
      })
    );

    setProductImg(imgLinkArr);

    // const imgArr = [...productImg, imgObj];

    // setProductImg(imgArr);
    // setProductFormData((prevState) => ({
    //   ...prevState,
    //   photo_link: imgArr[0].src,
    // }));
  };

  const deleteImageHandler = (index) => {
    let filesObj = [...productFormData.photos];
    filesObj = filesObj.filter((item) => item.id !== index);
    let imgLinkArrObj = [...productImg];
    imgLinkArrObj = imgLinkArrObj.filter((item) => item.id !== index);
    setProductImg(imgLinkArrObj);
    const imgLinkArr = imgLinkArrObj.map((item) => (item.src));
    const deleteImages = productObj.photoLinks.filter((item) => (!imgLinkArr.includes(item)));
    setProductFormData((prevState) => ({ ...prevState, photos: filesObj, deleteImages: deleteImages }));
  };

  const selectParentCategoryHandler = (parentId) => {
    // console.log(parentId);
    setProductFormData((prevState) => ({
      ...prevState,
      category_id: parentId,
    }));
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    // // console.log(name, value);
    // if (name === "category_name") {
    //   const index = event.target.selectedIndex;
    //   const el = event.target.childNodes[index];
    //   setProductFormData((prevState) => ({
    //     ...prevState,
    //     category_id: el.getAttribute("id"),
    //     [name]: value,
    //   }));
    // }
    setProductFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    api
      .get(`/businesses/${params.bid}/categories/`, {
        headers: { Authorization: "Bearer " + authToken },
      })
      .then((response) => {
        setCategories(() => response.data.categories);
        // const idArr = response.data.categories.filter(
        //   (item) => item.id === productObj.category_id
        // );
        // // console.log("idArr", idArr);
        // setProductFormData((prevState) => ({...prevState, category_name: idArr[0].name}));
      })
      .catch((error) => {
        if(error.code==="ERR_NETWORK") {
          toast.error(`${error.message} \nKindly check your connection`);
          return;
        }
        toast.error(`Something went wrong! \n${error.response.data.error_message}`);
      });
  }, [authToken, params.bid, productObj.category_id]);

  useEffect(() => {
    const productImg = productObj.photoLinks.map((item) => ({
      src: item,
      id: Math.random() * 100,
    }));
    setProductImg(productImg);
  }, [productObj.photoLinks]);

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setIsLoading(true);
    // const formData = {
    //   name: productFormData.name,
    //   description: productFormData.description,
    //   price: productFormData.price,
    //   category_id: productFormData.category_id,
    //   quantity: productFormData.quantity,
    //   photo_link: productFormData.photo_link,
    // };

    const formData = new FormData();

    formData.append("name", productFormData.name);
    formData.append("description", productFormData.description);
    formData.append("price", productFormData.price);
    formData.append("category_id", productFormData.category_id);
    formData.append("quantity", productFormData.quantity);

    // // console.log("category id", productFormData.category_id); 
    
    productFormData.photos.forEach((item) => (formData.append("images", item.image)));

    productFormData.deleteImages.forEach((item) => (formData.append("delete_images", item)));

    api
      .put(
        `/businesses/${params.bid}/products/${productObj.id}`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + authToken,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        // setProductFormData({
        //   name: "",
        //   description: "",
        //   price: "",
        //   category_name: "",
        //   category_id: "",
        //   // sub_category_id: "",
        //   quantity: "",
        //   photo_link: "",
        // });
        toast.success("Product updated successfully");
        setIsLoading(false);
        navigate("..");
      })
      .catch((error) => {
        if(error.code==="ERR_NETWORK") {
          toast.error(`${error.message} \nKindly check your connection`);
          setIsLoading(false);
          return;
        }
        toast.error(`Error updating product! \n${error.response.data.error_message}`);
        setIsLoading(false);
      });
  };

  return (
      <>
        <Toaster/>
        <div className="flex items-center justify-center p-8 lg:w-4/5 lg:ml-auto max-sm:w-full max-sm:px-2">
          <div className="mx-auto w-full sm:max-w-lg">
            <div className="flex gap-4 items-center px-9">
              <Link
                  to=".."
                  className="border p-2 rounded-md border-gray-400 hover:bg-white"
              >
                <BiArrowBack size={20} color="gray"/>
              </Link>
              <span className="font-medium text-xl">Update product</span>
            </div>
            <form className="py-6 px-9" onSubmit={formSubmitHandler}>
              <div className="p-4 shadow-lg rounded-md bg-white">
                {/* Title */}
                <div className="mb-5">
                  <label
                      htmlFor="name"
                      className="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Title
                  </label>
                  <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Iphone 14 pro"
                      required
                      value={productFormData.name}
                      onChange={inputChangeHandler}
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
                {/* Description */}
                <label
                    htmlFor="description"
                    className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Description
                </label>
                <div className="w-full mb-5">
              <textarea
                  id="description"
                  name="description"
                  rows="4"
                  className="w-full border rounded-md border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md focus:border"
                  placeholder="New iphone 14 pro 6GB|128GB"
                  required
                  value={productFormData.description}
                  onChange={inputChangeHandler}
              ></textarea>
                  {/* <div className="flex items-center justify-between px-3 py-2 dark:border-gray-600">
                <div className="flex pl-0 space-x-1 sm:pl-2">
                  <button
                    type="button"
                    className="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                  >
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 12 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 6v8a5 5 0 1 0 10 0V4.5a3.5 3.5 0 1 0-7 0V13a2 2 0 0 0 4 0V6"
                      />
                    </svg>
                    <span className="sr-only">Attach file</span>
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                  >
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 16 20"
                    >
                      <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                    </svg>
                    <span className="sr-only">Set location</span>
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                  >
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 18"
                    >
                      <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                    <span className="sr-only">Upload image</span>
                  </button>
                </div>
              </div> */}
                </div>
                {/* Select Category */}
                {/* <div className="mb-5">
              <label
                htmlFor="category_name"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                Category
              </label>
              <select
                type="select"
                name="category_name"
                id="category"
                required
                value={productFormData.category_name}
                onChange={inputChangeHandler}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              >
                <option>Select category</option>
                {categories.map((item) => (
                  <option id={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div> */}
                <div className="mb-5">
                  <label
                      htmlFor="category_name"
                      className="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Category
                  </label>
                  <RecursiveComponent data={categories} level={0} selectedItem={productFormData.category_id}
                                      selectItem={selectParentCategoryHandler}/>
                  {/* <Categories
                categories={categories}
                level={0}
                selectParentCategoryHandler={selectParentCategoryHandler}
                categoryId={productFormData.category_id}
              /> */}
                </div>
                {/* Price */}
                <div className="mb-5 flex justify-between gap-4">
                  <div>
                    <label
                        htmlFor="price"
                        className="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Price
                    </label>
                    <input
                        type="number"
                        name="price"
                        id="price"
                        placeholder="Enter price"
                        required
                        step={0.01}
                        value={productFormData.price}
                        onChange={inputChangeHandler}
                        className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                  {/* <div>
                <label
                  htmlFor="discountedPrice"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Discounted price
                </label>
                <input
                  type="number"
                  name="discountedPrice"
                  id="discountedPrice"
                  placeholder="Enter discounted price"
                  required
                  step={0.01}
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div> */}
                </div>
                {/* Product Unit */}
                {/* <div className="mb-5">
              <label
                htmlFor="productUnit"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                Product Unit
              </label>
              <div className="flex gap-10 w-fit rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md">
                <input
                  type="number"
                  name="productUnit"
                  id="productUnit"
                  defaultValue={1}
                  required
                  step={1}
                  className="outline-none"
                />
                <div className="border-[#e0e0e0] border"></div>
                <select className="outline-none">
                  <option>piece</option>
                  <option>kg</option>
                  <option>litre</option>
                  <option>gallon</option>
                </select>
              </div>
            </div> */}
              </div>

              {/* Upload media */}
              <div className="p-4 shadow-lg rounded-md bg-white mt-6">
                <div>
                  <label className="mb-3 block text-base font-medium text-[#07074D]">
                    Upload File
                  </label>

                  <div>
                    <input
                        type="file"
                        name="file"
                        id="file"
                        className="sr-only"
                        multiple
                        onChange={addImageHandler}
                    />
                    <label
                        htmlFor="file"
                        className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                    >
                      <div>
                    <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                      Drop files here
                    </span>
                        <span className="mb-2 block text-base font-medium text-[#6B7280]">
                      Or
                    </span>
                        <span
                            className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] hover:cursor-pointer">
                      Browse
                    </span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              {/* Media */}
              <div className="p-4 shadow-lg rounded-md bg-white mt-6">
                <div className="mb-5">
                  <label
                      htmlFor="product"
                      className="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Media
                  </label>
                </div>
                <div
                    className="grid grid-flow-row sm:grid-cols-3 grid-cols-2 max-[400px]:grid-cols-1 gap-2 max-h-screen overflow-y-scroll">
                  {productImg.map((item, index) => (
                      <div className="relative w-32 h-32" key={index}>
                        <img
                            src={item.src}
                            alt="product"
                            className="w-32 h-32 object-cover absolute top-0 left-0"
                        />
                        <div
                            className="absolute top-0 left-0 hover:bg-gray-600 hover:bg-opacity-30 hover:cursor-pointer w-full h-full"></div>
                        <div
                            className="absolute left-12 top-12 mx-auto w-4 h-4 text-center text-5xl flex items-center justify-center text-transparent hover:text-gray-700 hover:cursor-pointer"
                            onClick={(event) => deleteImageHandler(item.id)}
                        >
                          X
                        </div>
                      </div>
                  ))}
                </div>
              </div>

              {/* Inventory */}
              <div className="p-4 shadow-lg rounded-md bg-white mt-6">
                <div
                    className="flex justify-between items-center"
                    // onClick={(event) =>
                    //   setIsInventoryOpened((prevState) => !prevState)
                    // }
                >
                  <label
                      htmlFor="quantity"
                      required
                      className=" block text-base font-medium text-[#07074D]"
                  >
                    Inventory
                  </label>
                  {/* {!isInventoryOpened ? <AiOutlineDown /> : <AiOutlineUp />} */}
                </div>
                {/* {isInventoryOpened && ( */}
                <div className="duration-500 ease-in transition-all">
                  <hr className="mt-3"/>
                  <div className="mb-5 mt-5">
                    <label
                        htmlFor="quantity"
                        className="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Quantity
                    </label>
                    <input
                        type="number"
                        name="quantity"
                        id="quantity"
                        placeholder="Quantity"
                        required
                        value={productFormData.quantity}
                        onChange={inputChangeHandler}
                        className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                {/* )} */}
              </div>

              {/* Submit button */}
              <hr className="mt-6 border-gray-300"/>
              <div className="mt-6 flex justify-end">
                <button
                    className="hover:shadow-form w-40 rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                    disabled={isLoading}
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </>

  );
};

export default SingleProduct;
