import { redirect } from "react-router-dom";
import { api } from "../../../apis";

export async function loader () {
    // console.log("inside action");
    const response = await api.get(`/signout`);

    if(response.status===200) {
        localStorage.removeItem("owner");
        localStorage.removeItem("ownerTokenExpiration");
        return redirect("/");
    }
    else {
        alert(response);
    }
}