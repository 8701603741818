import React from 'react';
import Modal from '../../../Customer/UI/Modal';

const DeleteModal = ({itemType, itemName, closeModalHandler, deleteItemHandler}) => {
  // console.log(itemName);
  return (
    <Modal top="25" onBackdropClick={closeModalHandler}>
      <div className="bg-white px-8 py-4 rounded-lg flex flex-col gap-4 shadow-lg justify-center items-center">
        <div className=" flex justify-between items-center w-full">
          <div className=" whitespace-nowrap"><h1 className=" font-bold text-xl">Deleting {itemType}?</h1></div>
          <div className=" w-full text-right cursor-pointer" onClick={closeModalHandler}>X</div>
        </div>
        <p className=" font-light">Are you sure you want to delete {itemType} <span className=" font-bold">{itemName}</span>?</p>
        <div className="flex gap-2">
          <button onClick={deleteItemHandler} className=" bg-red-700 text-white px-4 py-2 rounded-md">Yes</button>
          <button onClick={closeModalHandler} className=" bg-gray-700 text-white px-4 py-2 rounded-md">No</button>
        </div>
      </div>
      </Modal>
  )
};

export default DeleteModal;