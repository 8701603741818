import React, { useEffect, useState } from "react";
import "./Product.scss";
import { AiOutlineShoppingCart, AiOutlineHeart } from "react-icons/ai";
import { MdOutlineBalance } from "react-icons/md";
import { useDispatch } from "react-redux";
import { cartActions } from "../../../redux/reducers/cartReducer";
import { useParams } from "react-router-dom";
import { api } from "../../../../apis";
import EnquiryForm from "../../Enquiry/EnquiryForm";
import { ToastContainer, toast } from "react-toastify";
import { getBusinessModel } from "../../../../utils/businessDetails";

const Product = () => {
  const params = useParams();
  const [productData, setProductData] = useState({
    id: "",
    name: "",
    price: 0,
    description: "",
    photos: [],
    max_quantity: 0,
  });

  const images = productData.photos.map(
    (item) => item
  );

  const [selectedImage, setSelectedImage] = useState("");
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const [isFormShown, setIsFormShown] = useState(false);

  const businessModel = getBusinessModel(params.bid);
  // const authToken = JSON.parse(localStorage.getItem(`${params.bid}-customer`)).token;

  const addToCartHandler = (enquiryFormData = "") => {
    const productObj = {
      id: productData.id,
      title: productData.name,
      price: productData.price,
      description: productData.description,
      quantity: businessModel === "product-based" ? quantity : 1,
      img: selectedImage,
      enquiryFormDataMessage: enquiryFormData.message,
      // enquiryFormDataPhotos: enquiryFormData.photos,
    };

    dispatch(cartActions.addToCart(productObj));

    const cart = localStorage.getItem(`${params.bid}-cart`);
    if (cart === null) {
      let product_obj;
      if(businessModel !== "product-based") {
        product_obj = {
          ...productObj,
          // enquiryFormData,
        }
      }
      else {
        product_obj = {
          ...productObj,
        }
      }
      localStorage.setItem(
        `${params.bid}-cart`,
        JSON.stringify({
          // authToken,
          products: [{ ...product_obj }],
        })
      );
    } else {
      let parsedCart = { ...JSON.parse(cart) };

      if (businessModel === "product-based") {
        const item = parsedCart.products.find(
          (item) => item.id === productObj.id
        );

        if (item === undefined) {
          parsedCart.products.push(productObj);
        } else {
          item.quantity += productObj.quantity;
        }
        localStorage.setItem(`${params.bid}-cart`, JSON.stringify(parsedCart));
      } else {
        parsedCart.products.push(productObj);
        const newParsedCart = {
          ...parsedCart,
          // enquiryFormDataPhotos: enquiryFormData.photos,
        }
        localStorage.setItem(`${params.bid}-cart`, JSON.stringify(newParsedCart));
      }
    }
    toast.success("Product added to cart successfully");
  };

  const hideFormHandler = () => {
    setIsFormShown(false);
  };

  useEffect(() => {
    api
      .get(`/businesses/${params.bid}/products/${params.pid}`)
      .then((response) => {
        const data = {
          id: response.data.id,
          name: response.data.name,
          price: response.data.price,
          description: response.data.description,
          photos: response.data.images,
          max_quantity: response.data.quantity,
        };
        setProductData((prevState) => ({ ...data }));
        setSelectedImage(
          () => data.photos[0]
        );
      }).catch((error) => {
        if(error.code==="ERR_NETWORK") {
          alert(`${error.message} \nKindly check your connection`);
          return;
        }
        alert(error.response.data.error_message);
      });
  }, [params.bid, params.pid]);

  return (
    <div className="product">
      <div className="left">
        <div className="images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt="side-images"
              onClick={(event) => {
                setSelectedImage(image);
              }}
            />
          ))}
        </div>
        <div className="main-image">
          <img src={selectedImage} alt="main-view" />
        </div>
      </div>
      <div className="right">
        <h1>{productData.name}</h1>
        <span className="price">${productData.price}</span>
        <p>{productData.description}</p>
        {businessModel === "product-based" && (
          <div className="quantity">
            <button
              onClick={(event) =>
                setQuantity((prev) => (prev === 1 ? 1 : prev - 1))
              }
            >
              -
            </button>
            {quantity}
            {quantity < productData.max_quantity && (
              <button onClick={(event) => setQuantity((prev) => prev + 1)}>
                +
              </button>
            )}
          </div>
        )}
        <button
          className="add-to-cart"
          onClick={() =>
            businessModel === "product-based"
              ? addToCartHandler()
              : setIsFormShown(true)
          }
        >
          <AiOutlineShoppingCart size={20} />{" "}
          {businessModel === "product-based" ? "ADD TO CART" : "AVAIL SERVICE"}
        </button>
        {/*<div className="links">*/}
        {/*  <div className="item">*/}
        {/*    <AiOutlineHeart /> ADD TO WISHLIST*/}
        {/*  </div>*/}
        {/*  <div className="item">*/}
        {/*    <MdOutlineBalance /> ADD TO COMPARE*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="info">*/}
        {/*  <span>Vendor: Vendor1</span>*/}
        {/*  <span>Product Type: ProductType</span>*/}
        {/*  <span>Product Tag: Category1, SubCategory1</span>*/}
        {/*</div>*/}
        {/*<hr />*/}
        {/*<div className="info">*/}
        {/*  <span>DESCRIPTION</span>*/}
        {/*  <hr />*/}
        {/*  <span>ADDITIONAL INFORMATION</span>*/}
        {/*  <hr />*/}
        {/*  <span>FAQ</span>*/}
        {/*</div>*/}
      </div>
      {isFormShown && (
        <EnquiryForm
          onHideForm={hideFormHandler}
          onAddToCart={addToCartHandler}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Product;
