import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../../apis";

const Login = () => {
  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
  });

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setUserLogin((prevState) => ({ ...prevState, [name]: value }));
  };

  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = {
      email: userLogin.email,
      password: userLogin.password,
    };

    api
      .post(`/businesses/${params.bid}/signin`, JSON.stringify(formData), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        localStorage.setItem(`${params.bid}-customer`, JSON.stringify({...response.data, role: "customer"}));
        const expiration = new Date();
        expiration.setHours(expiration.getHours() + 24); //sets JWT expiration time to 24 hrs in frontend
        //expiration.setSeconds(expiration.getSeconds() + 10);  //sets JWT expiration time to 10 seconds in frontend
        localStorage.setItem(`${params.bid}-customerTokenExpiration`, expiration.toISOString());
        navigate(`/business/${params.bid}`);
        // navigate(-1);
        setIsLoading(false);
      })
      .catch((error) => {
        if(error.code==="ERR_NETWORK") {
          alert(`${error.message} \nKindly check your connection`);
          setIsLoading(false);
          return;
        }
        alert(`Invalid login credentials \n${error.response.data.error_message}`);
        setIsLoading(false);
      });
  };

  return (
    <div className="antialiased bg-slate-200 min-h-screen py-14">
      <div className="max-w-lg mx-auto bg-white p-8 rounded-xl shadow shadow-slate-300">
        <h1 className="text-4xl font-medium">Login</h1>
        <p className="text-slate-500">Hi, Welcome back 👋</p>

        {/* <div className="my-5">
          <button className="w-full text-center py-3 my-3 border flex space-x-2 items-center justify-center border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
            <img
              src="https://www.svgrepo.com/show/355037/google.svg"
              className="w-6 h-6"
              alt=""
            />{" "}
            <span>Login with Google</span>
          </button>
        </div> */}
        <form className="my-10" onSubmit={formSubmitHandler}>
          <div className="flex flex-col space-y-5">
            <label htmlFor="email">
              <p className="font-medium text-slate-700 pb-2">Email address</p>
              <input
                id="email"
                name="email"
                type="email"
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                placeholder="Enter email address"
                required
                value={userLogin.email}
                onChange={inputChangeHandler}
              />
            </label>
            <label htmlFor="password">
              <p className="font-medium text-slate-700 pb-2">Password</p>
              <input
                id="password"
                name="password"
                type="password"
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                placeholder="Enter your password"
                required
                value={userLogin.password}
                onChange={inputChangeHandler}
              />
            </label>
            <div className="flex flex-row justify-end">
              {/* <div>
                <label htmlFor="remember" className="">
                  <input
                    type="checkbox"
                    id="remember"
                    className="w-4 h-4 border-slate-200 focus:bg-indigo-600"
                  />
                  Remember me
                </label>
              </div> */}
              <div>
                <Link to="forgotPassword" className="font-medium text-indigo-600">
                  Forgot Password?
                </Link>
              </div>
            </div>
            <button
              className="w-full py-3 font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center"
              disabled={isLoading}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                />
              </svg>
              <span>{isLoading ? "Logging in..." : "Login"}</span>
            </button>
            <p className="text-center">
              Not registered yet?{" "}
              <Link
                to={`/business/${params.bid}/signup`}
                className="text-indigo-600 font-medium inline-flex space-x-1 items-center"
              >
                <span>Register now </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                </span>
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
