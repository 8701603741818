import React, { useEffect, useState } from "react";
import Card from "../../Card/Card";
import { useNavigate, useParams } from "react-router";

const AllProducts = ({ products }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [visibleProductsCount, setVisibleProductsCount] = useState(0);

  const showProductHandler = (productId) => {
    navigate(`/business/${params.bid}/products/product/${productId}`);
  }

  const showMoreHandler = () => {
    setVisibleProductsCount((prev) => prev + 4);
  };

  useEffect(() => {
    if (products) {
      if (products.length > 6) {
        setVisibleProductsCount(6);
      } else {
        setVisibleProductsCount(products.length);
      }
    }
  }, [products]);

  return (
    <div>
      <div className="grid justify-items-center grid-flow-row gap-8 text-neutral-600 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
        {products &&
          products
            .slice(0, visibleProductsCount)
            .map((product, index) => <Card key={product.id} item={product} onClick={() => showProductHandler(product.id)} />)}
      </div>
      {products && visibleProductsCount <= products.length && (
        <div className="w-full flex items-center justify-center mt-8">
          <button
            className="bg-gray-400 px-4 py-2 rounded-md text-white hover:bg-gray-300"
            onClick={showMoreHandler}
          >
            Load More...
          </button>
        </div>
      )}
    </div>
  );
};

export default AllProducts;
