import "./OrdersList.scss";
import React, { useEffect, useState } from "react";
import { LiaFilterSolid } from "react-icons/lia";
import SingleOrder from "./SingleOrder";
import { api } from "../../../../apis";
import { useParams } from "react-router-dom";
import Pagination from "../../../Customer/UI/Pagination";
import OrdersFilter from "../../../Customer/pages/Dashboard/Orders/OrdersFilter";
import { getBusiness, getOwnerToken } from "../../../../utils/businessDetails";
import Loader from "../../../Loader/Loader";
import Filter from "../../../Filter/Filter";
import { useFilterState } from "../../../Filter/FilterContext";
import { getFilteredItems } from "../../../../utils/filterItems";

const OrdersList = () => {
  const params = useParams();
  const bid = params.bid;
  // console.log(bid)
  const businessModel = getBusiness(bid);
  const {query, resetQueryHandler} = useFilterState();

  const [isLoading, setIsLoading] = useState(false);
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(5);
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  let currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
  const totalPages = Math.ceil(orders.length / ordersPerPage);

  const paginateFront = () => {
    if (currentPage < totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
      window.scrollTo(0, 0);
    }
  };

  const paginateBack = () => {
    if (currentPage > 1) {
      setCurrentPage((currentPage) => currentPage - 1);
      window.scrollTo(0, 0);
    }
  };

  const showFilterHandler = () => {
    setIsFilterShown(true);
  };

  const hideFilterHandler = () => {
    setIsFilterShown(false);
  };

  useEffect(() => {
    setIsLoading(true);
    const authToken = getOwnerToken();
console.log(authToken)
    api
      .get(`/businesses/${bid}/admin/orders`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        let rawOrders = response.data;

        rawOrders.forEach((x) => {
          x.order_items = x.order_items.reduce((acc, curr) => {
            let curr_product_id = curr.product_id;
            let found = acc.find(
              (x) =>
                x.product_id === curr_product_id &&
                businessModel === "product-based"
            );

            if (found) {
              found.grouped_items.push({
                order_item_id: curr.order_item_id,
                permitted_states: curr.permitted_states,
                current_status: curr.status,
              });
            } else {
              curr.grouped_items = [];
              curr.grouped_items.push({
                order_item_id: curr.order_item_id,
                permitted_states: curr.permitted_states,
                current_status: curr.status,
              });
              acc.push(curr);
            }
            return acc;
          }, []);
        });

        setOrders(rawOrders);
        setIsLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        setIsLoading(false);
        if (error.code === "ERR_NETWORK") {
          alert(`${error.message} \nKindly check your connection`);
          setIsLoading(false);
          return;
        }
        // else {
        alert(error.response.data.error_message);

        // if(error.response.status === 401) {
        //   navigate("/login");
        // }
        // }
      });
  }, [bid, businessModel]);

  useEffect(() => {
    return (() => resetQueryHandler());
  }, [resetQueryHandler]);

  if (currentOrders) {
    const filteredItems = getFilteredItems({
      items: orders,
      filterBy: ["first_name", "last_name", "order_id"],
      query: query,
    });

    if(filteredItems.length!==orders.length) {
      currentOrders = [...filteredItems].slice(indexOfFirstOrder, indexOfLastOrder);
    }
  }

  return !isLoading ? (
    <>
      <main className="p-4 ml-auto orderContainer">
        <div className="flex sm:justify-between sm:items-center sm:flex-row flex-col justify-start items-start">
          <div className="flex-2">
            <h1 className="font-bold -mb-1 text-lg">All orders</h1>
            <p className="text-gray-600 text-sm">from anytime</p>
          </div>
          <div className="flex gap-4 items-center flex-1 justify-end search-container">
            <div className="">
              <Filter searchType="text" placeholder="by Order #, customer name" />
            </div>
            <button
              className="flex items-center border border-gray-300 rounded-md p-2"
              onClick={showFilterHandler}
            >
              <LiaFilterSolid size={20} />
              Filter
            </button>
          </div>
        </div>
        {currentOrders.map((order, index) => (
          <SingleOrder
            key={index}
            order={order}
            customerName={
              order.first_name.charAt(0).toUpperCase() +
              order.first_name.slice(1) +
              " " +
              order.last_name.charAt(0).toUpperCase() +
              order.last_name.slice(1)
            }
          />
        ))}
      </main>
      {isFilterShown && <OrdersFilter onClose={hideFilterHandler} />}
      <footer className="ml-auto orderContainer">
        <Pagination
          totalOrders={orders.length}
          paginateFront={paginateFront}
          paginateBack={paginateBack}
          ordersPerPage={ordersPerPage}
          currentPage={currentPage}
        />
      </footer>
    </>
  ) : (
    <div className="w-screen h-screen flex items-center justify-center mx-auto my-auto">
      <Loader />
    </div>
  );
};

export default OrdersList;
