import React, { useState } from "react";
import Modal from "../../../Customer/UI/Modal";

const EditForm = (props) => {
  // console.log(props);
  const [editFormData, setEditFormData] = useState({
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    phone: props.user.phone,
    email: props.user.email,
  });

  const inputChangeHandler = (event) => {

    const {name, value} = event.target;
    setEditFormData((prevState) => ({
        ...prevState,
      [name] : value
    }));
  };

  const submitEditHandler = (event) => {
    event.preventDefault();
    // console.log(editFormData);
    props.onSubmit(editFormData, props.user.user_id);
    event.target.setAttribute("disabled", true); //disable button afer adding the item once

  }

  return (
    <Modal onBackdropClick={props.onHideForm}>
      <section className="bg-white dark:bg-gray-900 sm:max-w-lg sm:mx-auto">
        <div className="py-4 lg:py-6 px-4 mx-auto max-w-screen-md">
          <div className="flex justify-end">
            <button className="text-white" onClick={props.onHideForm}>X</button>
          </div>

          <form className="space-y-4">

            <div>
            <label
                htmlFor="first_name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                First Name
              </label>
              <input
                type="string"
                id="first_name"
                name="first_name"
                value={editFormData.first_name}
                onChange={inputChangeHandler}

                placeholder="Enter first name"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              />
            </div>

            <div>
              <label
                  htmlFor="last_name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Last Name
              </label>
              <input
                  type="string"
                  id="last_name"
                  name="last_name"
                  value={editFormData.last_name}
                  onChange={inputChangeHandler}

                  placeholder="Enter last name"
                  className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              />
            </div>

            <div>
              <label
                  htmlFor="phone"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Phone Number
              </label>
              <input
                  type="string"
                  id="phone"
                  name="phone"
                  value={editFormData.phone}
                  onChange={inputChangeHandler}

                  placeholder="Enter Phone Number"
                  className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              />
            </div>

            <div>
              <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Email
              </label>
              <input
                  type="string"
                  id="email"
                  name="email"
                  value={editFormData.email}
                  onChange={inputChangeHandler}

                  placeholder="Enter email"
                  className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              />
            </div>
            <button
              type="submit"
              onClick={submitEditHandler}
              className="py-3 px-5 text-sm font-medium text-center border-white dark:text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Submit
            </button>
          </form>
        </div>
      </section>
    </Modal>
  );
};

export default EditForm;
