import React, { useEffect, useState } from "react";
import { BsCashCoin, BsCreditCard } from "react-icons/bs";

const Payment = ({ formData, setFormData, placeOrder }) => {
  const [mode, setMode] = useState("");
  const [paymentForm, setPaymentForm] = useState({
    cardNo: "",
    nameOnCard: "",
    validity: "",
    cvv: "",
    paymentMethod: mode,
  });

  const paymentModeClickHandler = (m) => {
    setMode(m);
    setPaymentForm((prevState) => ({
      ...prevState,
      paymentMethod: m,
      cardNo: "",
      nameOnCard: "",
      validity: "",
      cvv: "",
    }));
    setFormData((formData) => ({
      ...formData,
      paymentMethod: m,
      cardNo: "",
      nameOnCard: "",
      validity: "",
      cvv: "",
    }));
  };

  const inputChangeHandler = (event) => {
    mode === "cod"
      ? (() => {
          setPaymentForm((prevState) => ({
            ...prevState,
          }));
          setFormData((formData) => ({
            ...formData,
          }));
        })()
      : (() => {
          setPaymentForm((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
          }));
          setFormData((formData) => ({
            ...formData,
            [event.target.name]: event.target.value,
          }));
        })();
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    placeOrder();
  };

  useEffect(() => {
    if (mode === "") {
      setMode("cod");
      setFormData((formData) => ({
        ...formData,
        paymentMethod: "cod",
      }));
    }
  }, [setFormData, mode]);

  return (
    <form onSubmit={formSubmitHandler}>
      <p className="font-bold mb-4">Choose Payment Mode</p>
      <div className="border rounded-md flex sm:flex-row flex-col overflow-hidden ">
        {/* <div className=" flex max-sm:flex-row"> */}
        <div className="bg-gray-100">
          <button
            className={`flex items-center border-b whitespace-nowrap w-full px-4 py-4 gap-4 max-[460px]:px-2 max-[460px]:gap-2 ${
              mode === "cod"
                ? "text-pink-700 bg-white"
                : "text-gray-700 border-r"
            }`}
            type="button"
            onClick={() => paymentModeClickHandler("cod")}
          >
            <BsCashCoin size={20} />
            <p>
              <strong>Cash On Delivery</strong>
            </p>
          </button>
          <button
            className={`flex items-center border-b whitespace-nowrap w-full px-4 py-4 gap-4 max-[460px]:px-2 max-[460px]:gap-2 ${
              mode === "card"
                ? "text-pink-700 bg-white"
                : "text-gray-700 border-r"
            }`}
            type="button"
            onClick={() => paymentModeClickHandler("card")}
          >
            <BsCreditCard size={20} />
            <p>
              <strong>Credit/Debit Card</strong>
            </p>
          </button>
        </div>
        <div className="">
          {/* payment details */}
          {mode === "cod" && (
            <div className="p-8 w-full max-md:p-2">
              <p className="mb-4">
                <strong>Cash On Delivery</strong>
              </p>
              <button
                className="bg-pink-700 text-white rounded-md px-4 py-2 w-full"
                type="submit"
              >
                PLACE ORDER
              </button>
            </div>
          )}
          {/* </div> */}

          {mode === "card" && (
            <div className="p-8 w-full">
              <p className="mb-4">
                <strong>Credit/Debit Card</strong>
              </p>
              <p className="text-sm font-bold text-gray-500 mb-2">
                Payment Details
              </p>
              <div>
                <input
                  type="number"
                  placeholder="Card Number"
                  name="cardNo"
                  value={paymentForm.cardNo}
                  required
                  onChange={inputChangeHandler}
                  className="block border rounded-md w-full px-4 py-2 mb-2"
                />
                <input
                  placeholder="Name on card"
                  name="nameOnCard"
                  value={paymentForm.nameOnCard}
                  required
                  onChange={inputChangeHandler}
                  className="block border rounded-md w-full px-4 py-2 mb-2"
                />
                <div className="">
                  <input
                    placeholder="Validity (mm/yy)"
                    name="validity"
                    value={paymentForm.validity}
                    required
                    onChange={inputChangeHandler}
                    className="border rounded-md px-4 py-2 mb-2 lg:w-3/5 w-full md:mr-6"
                  />
                  <input
                    type="number"
                    placeholder="CVV"
                    name="cvv"
                    value={paymentForm.cvv}
                    required
                    onChange={inputChangeHandler}
                    className="border rounded-md px-4 py-2 mb-2 lg:w-1/3 w-full"
                  />
                </div>
              </div>
              <button
                className="bg-pink-700 text-white rounded-md px-4 py-2 w-full"
                type="submit"
              >
                PAY NOW
              </button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default Payment;
