import React, { useEffect, useRef, useState } from "react";
import "./Home.scss";
import { Link, useParams } from "react-router-dom";
import { api } from "../../../../apis";
import Profile from "../../Profile/Profile";
import { getBusinessName } from "../../../../utils/businessDetails";
import { useSelector } from "react-redux";
import { AiOutlineShoppingCart } from "react-icons/ai";
import ImageSlider from "./ImageSlider";
import SidebarCategoryModal from "../../Navbar/SidebarCategoryModal";
import { checkIsCustomerAuthenticated } from "../../../../utils/auth";
import AllProducts from "./AllProducts";
import useFetch from "../../../hooks/useFetch";
import Navbar from "../../Navbar/Navbar";

const Home = () => {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSidebarCategoryModalShown, setIsSidebarCategoryModalShown] =
    useState(false);
  const params = useParams();
  const {loading:loading1, error:error1, data:categories} = useFetch(`/businesses/${params.bid}/categories/`, null, "customer", params);
  const {loading:loading2, error:error2, data:allProducts} = useFetch(`/businesses/${params.bid}/products`, null, "customer", params);
  let featured_products = [];
  if(allProducts) {
    featured_products = allProducts.filter(
      (item) => !item.is_inactive && item.is_featured
    );
  }
  // const [logo, setLogo] = useState("");
  const slider = useRef();
  // let products = useSelector((state) => state.cart.products);
  // check if user is authenticated and set state accordingly
  // const authToken =
  //   localStorage.getItem(`${params.bid}-customer`) === null
  //     ? undefined
  //     : JSON.parse(localStorage.getItem(`${params.bid}-customer`)).token;
      // : getCustomerToken(${params.bid});
  let defaultTransform = 0;

  //get business details
  // const businessName = getBusinessName(params.bid);

  const goPrevHandler = (event) => {
    if (Math.abs(defaultTransform) === 0) defaultTransform = 0;
    else defaultTransform = defaultTransform + 398;
    slider.current.style.transform = "translateX(" + defaultTransform + "px)";
    // // console.log("slider", slider);
  };

  const goNextHandler = (event) => {
    defaultTransform = defaultTransform - 398;
    if (Math.abs(defaultTransform) >= slider.current.scrollWidth / 1)
      defaultTransform = 0;
    slider.current.style.transform = "translateX(" + defaultTransform + "px)";
  };

  const showSidebarCategoryHandler = () => {
    setIsSidebarCategoryModalShown(true);
  };

  const hideSidebarCategoryHandler = () => {
    setIsSidebarCategoryModalShown(false);
  };
  //
  // useEffect(() => {
  //   setIsAuthenticated((prevState) => {
  //     // return authToken ? true : false;
  //     return checkIsCustomerAuthenticated(params.bid);
  //   });
  //
  //   // getallCategories();
  // }, [params.bid]);
  //
  // useEffect(() => {
  //   api
  //     .get(`businesses/${params.bid}`)
  //     .then((response) => {
  //       setLogo(response.data.logo[0]);
  //     })
  //     .catch((error) => {
  //       // if(error.code==="ERR_NETWORK") {
  //       //   alert(`${error.message} \nKindly check your connection`);
  //       //   return;
  //       // }
  //       // alert(`Please upload business logo image - ${error}`);
  //     });
  // }, [params.bid]);

  return (
    <div className="relative">
      <Navbar></Navbar>
      {/*<header>*/}
      {/*  <nav className="bg-white border-gray-200 px-2 sm:px-4 lg:px-6 py-4 shadow-xl">*/}
      {/*    <div className="flex justify-between items-center mx-auto max-w-screen-xl">*/}
      {/*      <div>*/}
      {/*        <Link to="" className="flex items-center">*/}
      {/*          {logo && (*/}
      {/*            <img*/}
      {/*              src={logo}*/}
      {/*              className="h-12 w-40 object-cover"*/}
      {/*              alt="Logo"*/}
      {/*            />*/}
      {/*          )}*/}
      {/*          /!* <span className="self-center text-xl font-semibold whitespace-nowrap">*/}
      {/*          {businessName}*/}
      {/*        </span> *!/*/}
      {/*          {!logo && (*/}
      {/*            <span className=" logoText font-bold text-xl inline-block bg-gradient-to-r from-cyan-400 to-blue-500 bg-clip-text text-transparent">*/}
      {/*              {businessName}*/}
      {/*            </span>*/}
      {/*          )}*/}
      {/*        </Link>*/}
      {/*      </div>*/}

      {/*      /!* <div*/}
      {/*        className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"*/}
      {/*        id="mobile-menu-2"*/}
      {/*      >*/}
      {/*        <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">*/}
      {/*          <li>*/}
      {/*            <a*/}
      {/*              href="#categories"*/}
      {/*              className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0"*/}
      {/*            >*/}
      {/*              Categories*/}
      {/*            </a>*/}
      {/*          </li>*/}
      {/*          <li>*/}
      {/*            <Link*/}
      {/*              to="#"*/}
      {/*              className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0"*/}
      {/*            >*/}
      {/*              Features*/}
      {/*            </Link>*/}
      {/*          </li>*/}
      {/*          <li>*/}
      {/*            <Link*/}
      {/*              to="#"*/}
      {/*              className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0"*/}
      {/*            >*/}
      {/*              Offers*/}
      {/*            </Link>*/}
      {/*          </li>*/}
      {/*          <li>*/}
      {/*            <Link*/}
      {/*              to="#"*/}
      {/*              className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0"*/}
      {/*            >*/}
      {/*              Contact*/}
      {/*            </Link>*/}
      {/*          </li>*/}
      {/*        </ul>*/}
      {/*      </div> *!/*/}
      {/*      {!isAuthenticated ? (*/}
      {/*        <div className="flex items-center lg:order-2">*/}
      {/*          <Link*/}
      {/*            to={`/business/${params.bid}/login`}*/}
      {/*            className="text-gray-800 hover:bg-gray-700 hover:text-white focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 focus:outline-none"*/}
      {/*          >*/}
      {/*            Log in*/}
      {/*          </Link>*/}
      {/*          <Link*/}
      {/*            to={`/business/${params.bid}/signup`}*/}
      {/*            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"*/}
      {/*          >*/}
      {/*            Get started*/}
      {/*          </Link>*/}
      {/*          <button*/}
      {/*            data-collapse-toggle="mobile-menu-2"*/}
      {/*            type="button"*/}
      {/*            className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"*/}
      {/*            aria-controls="mobile-menu-2"*/}
      {/*            aria-expanded="false"*/}
      {/*          >*/}
      {/*            <span className="sr-only">Open main menu</span>*/}
      {/*            <svg*/}
      {/*              className="w-6 h-6"*/}
      {/*              fill="currentColor"*/}
      {/*              viewBox="0 0 20 20"*/}
      {/*              xmlns="http://www.w3.org/2000/svg"*/}
      {/*            >*/}
      {/*              <path*/}
      {/*                fillRule="evenodd"*/}
      {/*                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"*/}
      {/*                clipRule="evenodd"*/}
      {/*              ></path>*/}
      {/*            </svg>*/}
      {/*            <svg*/}
      {/*              className="hidden w-6 h-6"*/}
      {/*              fill="currentColor"*/}
      {/*              viewBox="0 0 20 20"*/}
      {/*              xmlns="http://www.w3.org/2000/svg"*/}
      {/*            >*/}
      {/*              <path*/}
      {/*                fillRule="evenodd"*/}
      {/*                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"*/}
      {/*                clipRule="evenodd"*/}
      {/*              ></path>*/}
      {/*            </svg>*/}
      {/*          </button>*/}
      {/*        </div>*/}
      {/*      ) : (*/}
      {/*        <div className="flex items-end gap-2 sm:gap-8 order-2">*/}
      {/*          <Profile*/}
      {/*            setIsAuthenticated={setIsAuthenticated}*/}
      {/*            isAuthenticated={isAuthenticated}*/}
      {/*          />*/}
      {/*          <div className=" ml-1 sm:ml-2 lg:ml-4 relative inline-block">*/}
      {/*            <Link to={`/business/${params.bid}/cart`} className="">*/}
      {/*              <div className="absolute -top-1.5 -right-1 z-10 bg-yellow-400 text-xs font-bold h-4 w-4 items-center justify-center flex rounded-full">*/}
      {/*                {products.length}*/}
      {/*              </div>*/}
      {/*              <div className="h-9 lg:h-10 text-gray-500 flex flex-col items-center">*/}
      {/*                <AiOutlineShoppingCart size={25} />*/}
      {/*                <span className="font-normal text-sm">Cart</span>*/}
      {/*              </div>*/}
      {/*            </Link>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  </nav>*/}
      {/*</header>*/}

      <section>
        <ImageSlider slides={featured_products} />
      </section>

      {/* Shop by Category section */}
      <section className="mt-8 px-4 lg:px-6" id="categories">
        <div className="flex flex-col justify-center items-center space-y-2">
          <h1 className="mb-4 text-4xl font-extrabold text-gray-900  mt-8 uppercase">
            Shop By Category
          </h1>
        </div>
        <div
            className="flex items-end justify-end mt-4 w-full"
            onClick={showSidebarCategoryHandler}
          >
            <span className="underline cursor-pointer text-xl font-bold text-blue-500 ">
              See all
            </span>
          </div>
        <div className="flex items-center justify-center w-full h-full py-4 sm:py-8">
          <div className="w-full relative flex items-center justify-center">
            <button
              aria-label="slide backward"
              className="absolute z-30 left-0 sm:ml-10 ml-4 p-4 focus:outline-none bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer hover:bg-gray-500"
              id="prev"
              onClick={goPrevHandler}
            >
              <svg
                className="text-white"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
              <div
                id="slider"
                ref={slider}
                className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700"
              >
                {categories && categories.categories.map((item) => (
                  <Link
                    to={`/business/${params.bid}/products/category/${item.id}`}
                    key={item.id}
                    className="flex flex-shrink-0 relative w-1/3 max-sm:w-full max-md:w-1/2"
                  >
                    <img
                      src={item.photo_url}
                      alt="black chair and white table"
                      className="object-cover object-center w-full h-80"
                    />
                    <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6">
                      {/* <h2 className="lg:text-xl leading-4 text-base lg:leading-5 text-white dark:text-gray-900">
                        Catalog {item.id}
                      </h2> */}
                      <div className="flex h-full items-end pb-6">
                        <h3 className="text-xl lg:text-2xl font-semibold leading-5 lg:leading-6 text-white">
                          {item.name}
                        </h3>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <button
              aria-label="slide forward"
              className="absolute z-30 right-0 sm:mr-10 mr-4 focus:outline-none bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 hover:bg-gray-500 p-4"
              id="next"
              onClick={goNextHandler}
            >
              <svg
                className="text-white"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7L1 13"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </section>

      <section className="mt-8 px-4 lg:px-6">
        <div>
          <div className="flex flex-col justify-center items-center space-y-2">
            <h1 className="mb-4 text-4xl font-extrabold text-gray-900 mt-8 uppercase">
              Shop By Products
            </h1>
          </div>
          <div className=" w-full h-full py-8 sm:py-18">
            <AllProducts products={allProducts} />
          </div>
        </div>
      </section>

      <footer className="p-4 bg-gray-200 sm:p-6 mt-20">
        <div className="mx-auto max-w-screen-xl">
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              © 2022{" "}
              <Link to="#" className="hover:underline">
                Your website
              </Link>
              . All Rights Reserved.
            </span>
            <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
              <Link
                to="#"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
              <Link
                to="#"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
              <Link
                to="#"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </Link>
              <Link
                to="#"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
              <Link
                to="#"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </footer>
      <SidebarCategoryModal
        onHideSidebar={hideSidebarCategoryHandler}
        isSidebarShown={isSidebarCategoryModalShown}
      />
    </div>
  );
};

export default Home;
