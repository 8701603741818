import React from "react";
import "./Card.scss";

const Card = (props) => {
  const { name, description, images, price } = props.item;
  // const [isHover, setIsHover] = useState(false);

  return (
    <div
      className="relative flex w-full max-w-md mx-auto flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md"
      onClick={props.onClick}
      // onMouseEnter={() => (setIsHover(true))}
      // onMouseLeave={() => (setIsHover(false))}
    >
      <div
        className="relative flex h-60 overflow-hidden"
      >
        {/* <img className="object-cover w-full h-full" src={process.env.REACT_APP_SERVER_URL + images[0]} alt="product" /> */}
        <img className="object-cover w-full h-full" src={images[0]} alt="product" />
        {/* <span className="absolute top-0 left-0 m-2 rounded-full bg-black px-2 text-center text-sm font-medium text-white">
          39% OFF
        </span> */}
      </div>
      <hr />
      <div className="mt-4 px-5 pb-5">
        {/* {isHover && <button className={`flex w-full mb-2 items-center justify-center rounded-md bg-slate-900 px-3 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300`}>
          <AiOutlineHeart size={20} />
          <span className="ml-2">Add to wishlist</span>
        </button>} */}
        <div>
            <h5 className="text-base tracking-tight text-slate-900 font-bold">{name}</h5>
            {/* {!isHover && <p className="font-light">{description}</p>} */}
            <p className="font-light">{description}</p>
          <div className="flex items-center justify-between mt-2">
            <p>
              <span className="text-lg font-bold text-slate-900">${price}</span>
              {/* <span className="text-xs text-slate-900 line-through">$699</span> */}
            </p>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Card;
