import { redirect } from "react-router-dom";
import { getCustomerTokenDuration } from "./businessDetails";

export function GetUser(user) {
    return localStorage.getItem(user);
};

export function checkAuthToken() {
    const owner = JSON.parse(GetUser("owner"));

    if(!owner) {
        return redirect("/login");
    }

    return null;
}

export function checkCustomerAuthToken(bid) {
    // // console.log(bid, JSON.parse(localStorage.getItem(`${bid}-customer`)));
    const user = JSON.parse(GetUser(`${bid}-customer`));

    if(!user) {
        // history.push("/dashboard");
        return redirect(`/business/${bid}/login`);
    }

    return null;
}

export function checkIsCustomerAuthenticated(bid) {
    const authToken = localStorage.getItem(`${bid}-customer`);
    const tokenDuration = getCustomerTokenDuration(bid);
    if(!authToken || tokenDuration < 0) {
        return false;
    }
    return true;
}