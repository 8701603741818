import React from 'react';
import Home from '../components/Home/Home';

const HomePage = () => {
  return (
    // <div>
      <Home />
    // </div>
  )
};

export default HomePage;