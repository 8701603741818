import React, { useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import { RxDragHandleHorizontal } from "react-icons/rx";

const Navbar = () => {
  const [isexpanded, setIsExpanded] = useState(false);

  return (
    <div className="nav">
      <div className="nav-l">
        <div className="left">
          <Link to="/" className="logo">
            <img
              src="https://st2.depositphotos.com/3867453/12012/v/600/depositphotos_120126908-stock-illustration-letter-g-number-6-logo.jpg"
              alt="company logo"
              className="w-8 h-8"
            />
            <p className="ml-2 italic font-extrabold text-2xl">GShop</p>
          </Link>
          {/* <div className="links">
          <div className="item">
            <span>Solutions</span>
            <AiOutlineDown />
          </div>
          <div className="item">
            <span>Pricing</span>
          </div>
          <div className="item">
            <span>Resources</span>
            <AiOutlineDown />
          </div>
        </div> */}
        </div>
        <div className="right">
          <Link to="/login" className="login">
            Log in
          </Link>
          <Link to="/sign-up" className="signup">
            Start free trial
          </Link>
        </div>
        <div className="expanded-view">
          {!isexpanded ? (
            <RxDragHandleHorizontal
              className="expand-icon"
              onClick={() => setIsExpanded(true)}
            />
          ) : (
            <button onClick={() => setIsExpanded(false)}>X</button>
          )}
        </div>
      </div>
      {isexpanded && (
        <div className="expanded">
          <Link to="/login" className="login">
            Log in
          </Link>
          <Link to="/sign-up" className="signup">
            Start free trial
          </Link>
        </div>
      )}
    </div>
  );
};

export default Navbar;
