import React from "react";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { MdVerified } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { getBusinessModel } from "../../../utils/businessDetails";
import CurrencyFormat from "react-currency-format";

const OrderThankYou = ({orderDetails}) => {
    const params = useParams();
    const businessModel = getBusinessModel(params.bid);
    const {customer, orderId, orderItems, orderedOn, orderTotal} = orderDetails;

    const convertDate = (date) => {
      const monthString = new Date(date).toLocaleDateString("default", {
        month: "short",
      });
      const dayString = new Date(date).toLocaleDateString("default", {
        weekday: "short",
      });
  
      const dateString =
        dayString +
        ", " +
        date.split("-")[2] +
        " " +
        monthString +
        " " +
        date.split("-")[0];
      return dateString;
    };

  return (
    <div>
      <h1 className="text-center mt-8 text-2xl text-green-700">
        {businessModel === "product-based" ?
            <strong>Your order has been confirmed</strong> :
            <strong>Your Quote has been confirmed</strong>
        }

      </h1>
      <p className="text-pink-700 flex justify-center"><MdVerified size={40} /></p>
      <h1 className="text-center mt-4 text-xl mb-4">
        {businessModel === "product-based" ?
            <strong>Thank you for your order</strong>:
            <strong>Thnak you Quatation Request has been sent to the business</strong>
        }

      </h1>
      <Link to={`/business/${params.bid}`} className="text-indigo-500 underline mb-4 flex justify-center items-center">
        <span>Continue shopping</span>
        <AiOutlineDoubleRight size={20} className="-mr-2" />
        <AiOutlineDoubleRight size={20} />
      </Link>
      <div className="py-10 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto border">
        <p className="text-2xl font-bold text-center mb-4">Order Summary</p>
        <div className="flex justify-start item-start space-y-2 flex-col">
          <h1 className="text-xl lg:text-xl font-semibold leading-7 lg:leading-9 text-gray-800">
            Order #{orderId}
          </h1>
          <p className="text-base font-medium leading-6 text-gray-600">
            {convertDate(orderedOn.split("T")[0])}
          </p>
        </div>
        <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
          <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
            <div className="flex flex-col justify-start items-start bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
              <p className="text-lg md:text-xl font-semibold leading-6 xl:leading-5 text-gray-800">
                Customer’s Cart
              </p>
              {orderItems.map((item, index) => (
              <div key={index} className="mt-4 md:mt-6 flex flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full">
                <div className="pb-4 md:pb-8 w-full md:w-40">
                  <img
                    className="w-full h-40 hidden md:block"
                    // src={businessModel==="product-based" ? `${process.env.REACT_APP_SERVER_URL + item.image}` : `${(process.env.REACT_APP_SERVER_URL + (item.images)[0])}`}
                    src={businessModel==="product-based" ? `${item.image}` : `${((item.images)[0])}`}
                    alt="order_item"
                  />
                  <img
                    className="w-full md:hidden"
                    // src={businessModel==="product-based" ? `${process.env.REACT_APP_SERVER_URL + item.image}` : `${(process.env.REACT_APP_SERVER_URL + (item.images)[0])}`}
                    src={businessModel==="product-based" ? `${item.image}` : `${((item.images)[0])}`}
                    alt="order_item"
                  />
                </div>
                <div className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full pb-16 space-y-4 md:space-y-0">
                  <div className="w-full flex flex-col justify-start items-start space-y-8">
                    <h3 className="text-xl xl:text-2xl font-semibold leading-6 text-gray-800">
                      {item.name}
                    </h3>
                    <div className="flex justify-start items-start flex-col space-y-2">
                      {/* <p className="text-sm leading-none text-gray-800">
                        <span className="dark:text-gray-400 text-gray-300">
                          Style:{" "}
                        </span>{" "}
                        Italic Minimal Design
                      </p>
                      <p className="text-sm leading-none text-gray-800">
                        <span className="dark:text-gray-400 text-gray-300">
                          Size:{" "}
                        </span>{" "}
                        Small
                      </p>
                      <p className="text-sm leading-none text-gray-800">
                        <span className="dark:text-gray-400 text-gray-300">
                          Color:{" "}
                        </span>{" "}
                        Light Blue
                      </p> */}
                      <p className="text-sm leading-none text-gray-800">
                        {item.description}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between space-x-8 items-start w-full">
                    <p className="text-base xl:text-lg leading-6">
                      <CurrencyFormat value={item.price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
                      {/* <span className="text-red-300 line-through"> $45.00</span> */}
                    </p>
                    <p className="text-base xl:text-lg leading-6 text-gray-800">
                      {businessModel==="product-based" ? `${item.quantity}` : 1}
                    </p>
                    <p className="text-base xl:text-lg font-semibold leading-6 text-gray-800">
                      {businessModel==="product-based" ?
                          <CurrencyFormat value={item.totalPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
                          :
                          <CurrencyFormat value={item.price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
                      }
                    </p>
                  </div>
                </div>
              </div>
              ))}
            </div>
            <div className="flex justify-center flex-col md:flex-row items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
              <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6">
                <h3 className="text-xl font-semibold leading-5 text-gray-800">
                  Summary
                </h3>
                <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                  <div className="flex justify-between w-full">
                    <p className="text-base leading-4 text-gray-800">
                      Subtotal
                    </p>
                    <p className="text-base leading-4 text-gray-600">
                      <CurrencyFormat value={orderTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
                    </p>
                  </div>
                  {/* <div className="flex justify-between items-center w-full">
                    <p className="text-base leading-4 text-gray-800">
                      Discount{" "}
                      <span className="bg-gray-200 p-1 text-xs font-medium dark:bg-white dark:text-gray-800 leading-3 text-gray-800">
                        STUDENT
                      </span>
                    </p>
                    <p className="text-base leading-4 text-gray-600">
                      -$28.00 (50%)
                    </p>
                  </div> */}
                  {/* <div className="flex justify-between items-center w-full">
                    <p className="text-base leading-4 text-gray-800">
                      Shipping
                    </p>
                    <p className="text-base leading-4 text-gray-600">
                      $8.00
                    </p>
                  </div> */}
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-base font-semibold leading-4 text-gray-800">
                    Total
                  </p>
                  <p className="text-base font-semibold leading-4 text-gray-600">
                    <CurrencyFormat value={orderTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
                  </p>
                </div>
              </div>
              
            </div>
          </div>
          <div className="bg-gray-50 w-full xl:w-96 flex justify-between items-center md:items-start px-4 py-6 md:p-6 xl:p-8 flex-col">
            <h3 className="text-xl font-semibold leading-5 text-gray-800">
              Customer
            </h3>
            <div className="flex flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0">
              <div className="flex flex-col justify-start items-start flex-shrink-0">
                <div className="flex justify-center w-full md:justify-start items-center space-x-4 py-8 border-b border-gray-200">
                  {/* <img
                    src="https://i.ibb.co/5TSg7f6/Rectangle-18.png"
                    alt="avatar"
                  /> */}
                  <div className="flex justify-start items-start flex-col space-y-2">
                    <p className="text-base font-semibold leading-4 text-left text-gray-800">
                      {customer.name}
                    </p>
                    {/* <p className="text-sm leading-5 text-gray-600">
                      10 Previous Orders
                    </p> */}
                  </div>
                </div>

                <div className="flex justify-center text-gray-800 md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 7L12 13L21 7"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className=" text-sm leading-5 ">
                    {customer.email}
                  </p>
                </div>
              </div>
              <div className="flex justify-between xl:h-full items-stretch w-full flex-col mt-6 md:mt-0">
                <div className="flex justify-center md:justify-start xl:flex-col flex-col md:space-x-6 lg:space-x-8 xl:space-x-0 space-y-4 xl:space-y-12 md:space-y-0 md:flex-row items-center md:items-start">
                  <div className="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4 xl:mt-8">
                    <p className="text-base font-semibold leading-4 text-center md:text-left text-gray-800">
                      {orderDetails.delivery_mode==="out-store" ? "Shipping Address" : "Pickup Address"}
                    </p>
                    <p className="w-48 lg:w-full xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                      {`${customer.address.address1}, ${customer.address.city} ${customer.address.state} ${customer.address.pincode}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderThankYou;
