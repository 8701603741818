import React, { useState } from 'react';

const Switch = ({activeText, inactiveText, mr, ml, onclick, isActive}) => {
    const [isSelected, setIsSelected] = useState(isActive!==null ? isActive : false);

    const toggleStatusHandler = () => {
        const status = isSelected;
        setIsSelected((prevState) => (!prevState));
        onclick(!status);
    }

  return (
    <div className={`relative w-20 h-7 flex justify-between cursor-pointer items-center shadow-lg transition-all duration-500 ${isSelected ? "bg-green-600" : "bg-gray-100"} rounded-full`} onClick={toggleStatusHandler}>
        <div className={`w-7 h-7 bg-gray-400 rounded-full transition-all duration-500 ${isSelected && "ml-[52px]"}`}></div>
        {isSelected && <span className={`absolute text-white overflow-hidden`} style={{left: ml + 'px'}}>{activeText}</span>}
        {!isSelected && <span className={`text-xs overflow-hidden`} style={{marginRight: mr + 'px'}}>{inactiveText}</span>}
    </div>
  );
};

export default Switch;