import React, { useEffect, useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { api } from "../../../../apis";
import { ToastContainer, toast } from "react-toastify";
import { getBusiness, getOwnerToken } from "../../../../utils/businessDetails";

const Delivery = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [deliveryModes, setDeliveryModes] = useState([]);
  const businessModel = getBusiness(params.bid);
  const [deliveryView, setDeliveryView] = useState(false);

  const currentDeliveryMode = JSON.parse(
    localStorage.getItem(`${params.bid}-business`)
  ).delivery_mode;

  const selectDeliveryMethodHandler = (event) => {
    let tempDeliveryMethods = [...deliveryModes];
    const uncheckedDeliveryMethod = tempDeliveryMethods.find(
      (item) => item === event.target.value
    );
    if (uncheckedDeliveryMethod) {
      tempDeliveryMethods = tempDeliveryMethods.filter(
        (item) => item !== uncheckedDeliveryMethod
      );
    } else {
      tempDeliveryMethods.push(event.target.value);
    }
    setDeliveryModes(tempDeliveryMethods);
  };

  const toggleDeliveryModeView = () => {
    setDeliveryView((prevState) => !prevState);
  };

  const updateDeliveryMode = async() => {
    setIsLoading(true);
    const formData = {
        delivery_mode : deliveryModes.length===1 ? deliveryModes[0] : "in/out-store",
    }

    // // console.log(formData);
    await api.put(`/businesses/${params.bid}/delivery-mode`, JSON.stringify(formData), {headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getOwnerToken()}`,
    }}).then((response) => {
        const business = {...JSON.parse(localStorage.getItem(`${params.bid}-business`))};
        business.delivery_mode = formData.delivery_mode;
        localStorage.setItem(`${params.bid}-business`, JSON.stringify(business));
        toast.success("Delivery mode updated successfully");
        window.location.reload();
    }).catch((error) => {
      if(error.code==="ERR_NETWORK") {
        alert(`${error.message} \nKindly check your connection`);
        setIsLoading(false);
        return;
      }
        toast.error(`Cannot update delivery mode \n${error.response.data.error_message}`);
        setIsLoading(false);
    });
  };

  useEffect(() => {
    if (deliveryView === false) {
      if(currentDeliveryMode==="in/out-store") {
        setDeliveryModes(["in-store", "out-store"]);
      }
      else {
        setDeliveryModes([currentDeliveryMode]);
      }
    }
  }, [deliveryView, currentDeliveryMode]);

  return (
    <div className="bg-white shadow-lg rounded-md p-4">
      <h1 className="text-xl text-blue-500 border-b pb-2">
        <strong>Delivery mode</strong>
      </h1>
      <p className="mt-2">
        <span className="text-gray-500">Current mode: </span>
        <span className="ml-2 font-bold text-gray-500">
          {currentDeliveryMode}
        </span>
      </p>
      <button
        className={`border-t mt-4 font-light flex gap-2 items-center border rounded-md p-2 hover:border-black ${
          deliveryView && "border-black"
        }`}
        onClick={toggleDeliveryModeView}
      >
        <p className="text-xs font-bold text-gray-600">CLICK TO UPDATE</p>
        {deliveryView ? <AiOutlineUp size={20} /> : <AiOutlineDown size={20} />}
      </button>
      <div
        className={`transition-all overflow-y-auto duration-1000 ease-in-out ${
          deliveryView ? "max-h-[500px]" : "max-h-[0px]"
        }`}
      >
        {deliveryView && (
          <div>
            <div className="flex mx-auto mt-8 font-light max-[350px]:flex-col gap-2">
              <label className="flex px-4 items-center border border-gray-400 rounded-md w-2/3 h-20 hover:border-gray-700 max-[350px]:w-full">
                <input
                  type="checkbox"
                  name="delivery"
                  value="in-store" // 0 - store pick-up
                  required
                  checked={deliveryModes.includes("in-store")}
                  onChange={(event) => selectDeliveryMethodHandler(event)}
                />
                <span className="ml-2">Store pick-up</span>
              </label>
              <label className="flex items-center px-4 border border-gray-400 rounded-md w-2/3 h-20 hover:border-gray-700 max-[350px]:w-full">
                <input
                  type="checkbox"
                  name="delivery"
                  value="out-store" // 1 - home delivery
                  required
                  checked={deliveryModes.includes("out-store")}
                  onChange={selectDeliveryMethodHandler}
                />
                <span className="ml-2">
                  {businessModel === "product-based"
                    ? "Home delivery"
                    : "Onsite"}
                </span>
              </label>
            </div>
            <button
              className={`text-white rounded-md p-2 mt-2 w-full ${isLoading ? "bg-gray-500 hover:bg-gray-400" : "bg-indigo-500 hover:bg-indigo-400"}`}
              disabled={isLoading}
              onClick={updateDeliveryMode}
            >
              {isLoading ? "UPDATING..." : "UPDATE"}
            </button>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Delivery;
