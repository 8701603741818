import { api } from "../../apis";
import { useEffect, useState } from "react";
import { getCustomerToken, getOwnerToken } from "../../utils/businessDetails";

//fetch data without params
const useFetch = (url, contentType, userType, params) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      api
        .get(url, {
          headers: {
            "Content-Type": contentType ? contentType : "application/json",
            Authorization: `Bearer ${userType==="owner" ? getOwnerToken() : getCustomerToken(params.bid)}`,
          },
        })
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          if(error.code==="ERR_NETWORK") {
            setError(`${error.message} \nKindly check your connection`);
          }
          else {
            setError(error.response.data.error_message);
          }
          // setError(error);
          setLoading(false);
        });
    };
    fetchData();
  }, [url, contentType, userType, params?.bid]);

  return {loading, error, data};
};

export default useFetch;
