import React from "react";

const Backdrop = (props) => {
  return (
    <div
      className="z-50 fixed top-0 left-0 bg-black opacity-25 w-full h-full"
      onClick={props.onClick}
    ></div>
  );
};

export default Backdrop;
