import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

const ChildComponent = (props) => {
    const params = useParams();
  const [showNested, setShowNested] = useState({});

  const toggleNestedHandler = (category_id) => {
    setShowNested({
      ...showNested,
      [category_id]: !showNested[category_id],
    });
  };

  return (
    <div className="relative">
      {props.children.map((category) => (
        <div key={category.id} style={{ marginLeft: `${props.level * 20}px` }}>
          <div className="flex items-center mb-3">
            <div
              className="border absolute left-[7px]"
              style={{ width: `${props.level * 13}px` }}
            ></div>
            <button
              onClick={() => toggleNestedHandler(category.id)}
              className="bg-indigo-600 text-white h-4 w-4 rounded-full flex items-center justify-center"
            >
              {!showNested[category.id] && <span>+</span>}
              {showNested[category.id] && <span>-</span>}
            </button>
            <Link to={`/business/${params.bid}/products/category/${category.id}`} className="ml-2 hover:cursor-pointer hover:text-xl transition-all duration-300 ease-in-out">{category.name}</Link>
          </div>
          {showNested[category.id] && category.children.length > 0 && (
            <div className="relative">
              <div className="border absolute left-[7px] -top-[18px] bottom-[13px]"></div>
              <ChildComponent children={category.children} level={1} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ChildComponent;
