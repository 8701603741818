import React, {useEffect, useState} from "react";
import "./Cart.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../redux/reducers/cartReducer";
import { ToastContainer } from "react-toastify";
import { BsArrowRightShort } from "react-icons/bs";
import { getBusinessModel } from "../../../utils/businessDetails";
import CurrencyFormat from "react-currency-format";
import {getPicture} from "../../../Apis/CoreApi";

const Cart = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imageMap, setImageMap] = useState({});
  const products = useSelector((state) => state.cart.products);
  console.log(products);
  const businessModel = getBusinessModel(params.bid);
  const user = JSON.parse(localStorage.getItem(`${params.bid}-customer`));

  if (products.length > 0) {
    localStorage.setItem(
        `${params.bid}-cart`,
        JSON.stringify({
          user,
          products: [...products],
        })
    );
  }

  const subTotal = () => {
    let total = 0;
    products.forEach((item) => (total = total + item.quantity * item.price));
    return total.toFixed(2);
  };

  const shipping = 0;
  const tax = 0;
  const total = parseFloat(subTotal() + shipping + tax).toFixed(2);

  const removeProductHandler = (index) => {
    if (products.length === 1) {
      localStorage.removeItem(`${params.bid}-cart`);
    }
    dispatch(cartActions.removeItem(index));
  };

  const getQuote = () => {
    if (!user) {
      window.location.href = `${process.env.REACT_APP_CLIENT_URL}business/${params.bid}/login`;
      return;
    }

    navigate('checkout', {state: {total: total}});
    // const dataItems = products.map((item) => ({
    //   product_id: item.id,
    //   price: item.price,
    //   status: "INITIATED",
    //   remark: item.enquiryFormData.message,
    // }));
    // const data = {
    //   user_id: user.user.id,
    //   items: dataItems,
    // };


    // api
    //   .post(`/businesses/${params.bid}/enquiries`, JSON.stringify(data), {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${user.token}`,
    //     },
    //   })
    //   .then((response) => {
    //     toast.success(
    //       "Enquiry created successfully! We will get back to you soon."
    //     );
    //     dispatch(cartActions.resetCart());
    //     localStorage.removeItem(`${params.bid}-cart`);
    //   })
    //   .catch((error) => {
    //     toast.error(`Enquiry cannot be created! ${error.response.data.error}`);
    //   });
  };

  const checkout = () => {
    if (!user) {
      window.location.href = `${process.env.REACT_APP_CLIENT_URL}business/${params.bid}/login`;
      return;
    }

    // // console.log("total:", total);

    // if(!total) {
    //   window.location.href = `http://localhost:3000/business/${params.bid}/cart`;
    //   return;
    // }
    navigate('checkout', {state: {total: total}});
    // const dataItems = products.map((item) => ({
    //   product_id: item.id,
    //   price: item.price,
    //   quantity: item.quantity,
    // }));


    // const data = {
    //   user_id: user.user.id,
    //   items: dataItems,
    // };

    // api
    //   .post(`/businesses/${params.bid}/order`, JSON.stringify(data), {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${user.token}`,
    //     },
    //   })
    //   .then((response) => {
    //     toast.success("Order created successfully!");
    //     dispatch(cartActions.resetCart());
    //     localStorage.removeItem(`${params.bid}-cart`);
    //   })
    //   .catch((error) => {
    //     toast.error(`Order cannot be created! ${error.response.data.error}`);
    //   });
  };


  useEffect( () => {
    async function fetchImageMap() {
      try{
        const res = await getPicture(products.map(product => product.id))
        setImageMap(res);
      }
      catch (error) {
        console.log(error);
        console.error( `error fetchign images from the server`);
      }
    }
    fetchImageMap();
  }, []);



  return (
      <div className="max-w-5xl mx-auto mb-48 h-screen" >
        {products.length > 0 ? (
                <div className="">
                  <div className="lg:w-full w-full lg:px-8 lg:py-14 md:px-6 px-4 md:py-8 py-4 overflow-x-hidden h-auto">
                    <p className="lg:text-4xl text-3xl font-bold leading-10 text-gray-800 border-b border-gray-300 pb-14">
                      {businessModel==='service-based'? "Service Quote Basket":"Shopping Cart"}
                    </p>

                    {products.length > 0 ? (
                        products.map((item, index) => (
                            <div
                                key={index}
                                className="md:flex w-full items-strech py-8 border-b border-gray-300"
                            >
                              <div className="w-40 h-40">
                                <img
                                    src={imageMap[item.id]}
                                    alt="cart-product"
                                    className="h-full object-center object-cover md:block hidden"
                                />
                              </div>
                              <div className="md:pl-3 md:w-full 2xl:w-3/4 flex flex-col justify-between">
                                <div className="flex items-start justify-between w-full pt-1">
                                  <div>
                                    <p className="text-xl font-black text-gray-800 ">
                                      {item.title}
                                    </p>
                                    <p className="w-72 line-clamp-1 text-gray-600 mt-2">
                                      {item.description}
                                    </p>
                                    <p className="text-base font-black leading-none text-gray-800 mt-4">
                                      ${item.price}
                                    </p>
                                  </div>
                                  <div className="text-xl focus:outline-none">
                                    Quantity: {item.quantity}
                                  </div>
                                </div>
                                <div className="flex items-center justify-between pt-5">
                                  <div className="flex itemms-center">
                                    {/* <p className="text-sm leading-3 underline text-gray-800  cursor-pointer">
                        Move to wishlist
                      </p> */}
                                    <button
                                        className="text-sm leading-3 underline text-red-500 cursor-pointer"
                                        onClick={
                                          businessModel === "product-based"
                                              ? removeProductHandler.bind(null, item.id)
                                              : removeProductHandler.bind(null, index)
                                        }
                                    >
                                      Remove
                                    </button>
                                  </div>
                                  <p className="text-xl leading-none text-gray-800">
                                    {/* <span className="mr-0">${item.price} X {item.quantity} = </span> */}
                                    <span>${item.quantity * item.price}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                        ))
                    ) : (
                        <div className="flex flex-col items-center justify-center h-full text-2xl py-8">
                          <span>Cart is empty</span>
                          <Link to=".." className="underline">
                            Continue Shopping
                          </Link>
                        </div>
                    )}
                  </div>
                  <div className="ml-auto max-w-2xl px-8">
                    <div
                        className="bg-gray-50 rounded-md flex flex-col h-auto lg:px-8 md:px-7 px-4 lg:py-6 md:py-10 py-6 justify-between overflow-y-auto">
                      <div>
                        <div className="flex items-center justify-between py-4 border-b border-gray-300 font-light">
                          <p className="text-base leading-none text-gray-800">Subtotal</p>
                          <p className="text-base leading-none text-gray-800">

                            <CurrencyFormat value={subTotal()} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
                          </p>
                        </div>
                        {/* <div className="flex items-center justify-between pt-5">
                  <p className="text-base leading-none text-gray-800 dark:text-white">
                    Shipping
                  </p>
                  <p className="text-base leading-none text-gray-800 dark:text-white">
                    ${shipping}
                  </p>
                </div>
                <div className="flex items-center justify-between pt-5">
                  <p className="text-base leading-none text-gray-800 dark:text-white">
                    Tax
                  </p>
                  <p className="text-base leading-none text-gray-800 dark:text-white">
                    ${tax}
                  </p>
                </div> */}
                      </div>
                      <div>
                        <div className="flex items-center justify-between pt-4">
                          <p className="text-base font-bold leading-normal text-gray-800">
                            Quote Total
                          </p>
                          <p className="text-base font-bold leading-normal text-right text-gray-800">
                            <CurrencyFormat value={total} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
                          </p>
                        </div>
                      </div>
                    </div>
                    <button
                        className="text-base w-full py-4 bg-indigo-600 rounded-md focus:ring-gray-800 text-white dark:hover:bg-indigo-700 mt-8"
                        onClick={businessModel === "product-based" ? checkout : getQuote}
                        disabled={products.length <= 0}
                    >
                      {businessModel === "product-based" ? "Checkout" : "Get Quote"}
                    </button>
                    <div className="flex mt-4 items-center justify-center">
                      <p>or</p>
                      <Link
                          to=".."
                          className="text-indigo-600 ml-2 flex items-center hover:text-indigo-400"
                      >
                        Continue Shopping
                        <BsArrowRightShort size={20}/>
                      </Link>
                    </div>
                  </div>
                </div>):

            <div>
              <div className="rounded bg-gray-900 p-8 mt-12">
                <h1 className="text-4xl text-white">    {businessModel==='service-based'? "Quote Basket":" Cart"} Empty!</h1>
              </div>
              <div className="mx-auto h-2 w-11/12 rounded-b bg-gray-900 opacity-75"></div>
              <div className="mx-auto h-2 w-10/12 rounded-b bg-gray-900 opacity-50"></div>
              <div className="mx-auto h-2 w-9/12 rounded-b bg-gray-900 opacity-25"></div>
            </div>
        }
        <ToastContainer/>
      </div>
  );
};

export default Cart;
