import React, { useState } from "react";
import { api } from "../../../apis";
import { ToastContainer, toast } from "react-toastify";
import { BsCheckCircleFill } from "react-icons/bs";
import { useParams } from "react-router-dom";

const CustomerEmailVerification = (props) => {
    const params = useParams();
  const [emailSent, setEmailSent] = useState(false);

  const sendEmailVerificationAgainHandler = () => {
    api
      .get(`businesses/${params.bid}/VerifyEmail`, { params: { email: props.email } })
      .then((response) => {
        toast.success(response.data.msg);
        setEmailSent(true);
      })
      .catch((error) => {
        if(error.code==="ERR_NETWORK") {
          toast.error(`${error.message} \nKindly check your connection`);
          return;
        }
        toast.error(error.response.data.error_message);
      });
  };

  return (
    <div className="app font-sans min-w-screen min-h-screen bg-grey-lighter py-8 px-4">
      <div className="mail__wrapper max-w-md mx-auto">
        <div className="mail__content bg-white p-8 shadow-md">
          <div className="content__header text-center tracking-wide border-b">
            <h1 className="text-3xl h-24 flex items-center justify-center">
              E-mail Confirmation
            </h1>
          </div>

          <div className="content__body py-8 border-b">
            <p>
              Hey, <br />
              <br />
              It looks like you just signed up for The App, that’s awesome! Can
              we ask you for email confirmation? Open your email to verify.
            </p>
            <div className="text-white text-sm tracking-wide bg-blue-600 rounded w-full my-8 p-4 text-center">
              VERIFY YOUR EMAIL
            </div>
            <div className="mb-4">
              <span>Didn't get verification email?</span>
              {!emailSent ? (
                <button
                  className="ml-2 text-blue-600 underline"
                  onClick={sendEmailVerificationAgainHandler}
                >
                  Email again
                </button>
              ) : (
                <div className="flex text-green-600 items-center">
                  <span className="">Email sent</span>
                  <BsCheckCircleFill size={20} />
                </div>
              )}
            </div>
            <p className="text-sm">
              Keep Rockin'!
              <br />
              {/* Your The GShop team */}
            </p>
          </div>

          <div className="content__footer mt-8 text-center text-grey-darker">
            <h3 className="text-base sm:text-lg mb-4">Thanks for using The App!</h3>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CustomerEmailVerification;
