import React, { useState } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import "./Home.scss";
import { Link } from "react-router-dom";
import { BsCheckAll } from "react-icons/bs";

const Home = () => {
  const [email, setEmail] = useState("");

  return (
    <div>
      <Navbar />
      <div className="homepage">
        <div className="front">
          <div className="left">
            <h1>Your One-Stop Ecommerce Solution for Every Business Type:</h1>
            <h2>Explore, Sell, and Succeed!</h2>
            <p>Setup and run any kind of business with GShop</p>
            <div>
              <input
                type="email"
                id="email"
                placeholder="Email"
                onChange={(event) => setEmail(event.target.value)}
              />
              <Link to="/sign-up" state={email} className="signup">
                Signup
              </Link>
            </div>
          </div>
          {/* <div className="right">
            <img
              src="https://st2.depositphotos.com/3867453/12012/v/600/depositphotos_120126908-stock-illustration-letter-g-number-6-logo.jpg"
              alt="company logo"
            />
          </div> */}
        </div>
      </div>
      <div className="business">
        <div className="item-1 shared-item">
          <div className="item-img">
            <img src="/Enquiry-owner.jpg" alt="enquiryPagePhoto" />
          </div>
          <div className="item-desc">
            <h1>Universal Business Compatibility</h1>
            <ul>
              <li>
                <BsCheckAll size={30} color={"green"} />
                <p>
                  Sell products or services, from physical goods to digital offerings.
                </p>
              </li>
              <li>
                <BsCheckAll size={30} color={"green"} />
                <p>
                  Seamless Customer-Owner Interaction throughout the entire business process.
                </p>
              </li>
              <li>
                <BsCheckAll size={30} color={"green"} />
                <p>
                  Ideal for startups, small businesses, and established
                  enterprises.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="item-2 shared-item">
          <div className="item-desc">
            <h1>Easy Setup and Customization</h1>
            <ul>
              <li>
                <BsCheckAll size={30} color={"green"} />
                <p>
                  Intuitive user-friendly interface.
                </p>
              </li>
              <li>
                <BsCheckAll size={30} color={"green"} />
                <p>
                  Customer profiles and order history.
                </p>
              </li>
              <li>
                <BsCheckAll size={30} color={"green"} />
                <p>
                  No limit on the number of products or services.
                </p>
              </li>
            </ul>
          </div>
          <div className="item-img">
            <img src="/Product.jpg" alt="productPagePhoto" />
          </div>
        </div>
        <div className="item-1 shared-item">
          <div className="item-img">
            <img src="/Cart.jpg" alt="CartPagePhoto" />
          </div>
          <div className="item-desc">
            <h1>Simplified Checkout Process</h1>
            <ul>
              <li>
                <BsCheckAll size={30} color={"green"} />
                <p>
                  One-click checkout option.
                </p>
              </li>
              <li>
                <BsCheckAll size={30} color={"green"} />
                <p>
                  Customer delivery options according to needs.
                </p>
              </li>
              <li>
                <BsCheckAll size={30} color={"green"} />
                <p>
                  Adapt to the growth of your business.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
