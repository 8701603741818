import React from "react";
import Signup from "../components/Signup/Signup";

const SignupPage = () => {
    return (
        <div>
            <Signup />
        </div>
    );
};

export default SignupPage;