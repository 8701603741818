import React, { useEffect, useState } from "react";
import {
  AiOutlineSearch,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import "./Navbar.scss";
import { useSelector } from "react-redux";
import { api } from "../../../apis";
import {getBusinessModel, getBusinessName} from "../../../utils/businessDetails";
import SidebarCategoryModal from "./SidebarCategoryModal";
import Profile from "../Profile/Profile";
import { checkIsCustomerAuthenticated } from "../../../utils/auth";

const Navbar = () => {
  const params = useParams();
  const [logo, setLogo] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSidebarCategoryModalShown, setIsSidebarCategoryModalShown] =
    useState(false);
  let products = useSelector((state) => state.cart.products);

  const businessName = getBusinessName(params.bid);
  const businessModel = getBusinessModel(params.bid);

  const showSidebarCategoryHandler = () => {
    setIsSidebarCategoryModalShown(true);
  };

  const hideSidebarCategoryHandler = () => {
    setIsSidebarCategoryModalShown(false);
  };

  useEffect(() => {
    api
      .get(`businesses/${params.bid}`)
      .then((response) => {
        setLogo(response.data.logo[0]);
      })
      .catch((error) => {
        // alert(`Please upload business logo image - ${error}`);
      });
  }, [params.bid]);

  useEffect(() => {
    setIsAuthenticated((prevState) => {
      // const authToken = getCustomerToken(params.bid);
      // // console.log(authToken);
      // if(!authToken || authToken==="EXPIRED") {
      //   return false;
      // }
      // else {
      //   return true;
      // }
      // return getCustomerToken(params.bid) ? true : false;
      return checkIsCustomerAuthenticated(params.bid);
    });
  }, [params.bid]);

  return (
    // <!-- component -->
    <header className="bg-white sticky top-0 left-0 w-full h-20 z-20 border-b-2">
      <div className=" w-full px-2 sm:px-4 py-4 flex items-center justify-between">
        {/* <!-- logo --> */}
        <Link
          to={`/business/${params.bid}`}
          className="flex gap-2 items-center flex-shrink-0 w-1/3 md:w-1/6"
        >
          {logo && <img
            className="h-12 w-full bg-transparent object-cover"
            src={logo}
            alt=""
          />}
          {!logo && <span className="text-xl font-semibold">{businessName}</span>}
        </Link>

        {/* <!-- search --> */}
        <div className="  max-w-xs xl:max-w-lg 2xl:max-w-2xl bg-gray-100 rounded-md hidden xl:flex md:flex items-center py-3">
          <button
            className="bg-transparent uppercase font-bold text-sm px-4 mr-4 outline-none w-fit"
            onClick={showSidebarCategoryHandler}
          >
            <p className="w-fit">categories</p>
          </button>
          {/*<input*/}
          {/*  className="border-l border-gray-300 bg-transparent font-semibold text-sm pl-4 outline-none w-full"*/}
          {/*  type="text"*/}
          {/*  placeholder="I'm searching for ..."*/}
          {/*/>*/}
          {/*<div className="px-4 text-gray-500">*/}
          {/*  <AiOutlineSearch size={20} />*/}
          {/*</div>*/}
        </div>

        {/* <!-- buttons --> */}
        <nav className="contents">
          <ul className="ml-4 xl:w-48 flex items-end justify-end">
            <li className="ml-2 lg:ml-4 relative inline-block">
              {/* <Link className="" to="">
                <div className="h-9 lg:h-10 text-gray-500 flex flex-col items-center">
                  <BsPerson size={25} />
                  <span className="font-normal text-sm">Profile</span>
                </div>
              </Link> */}
              <Profile setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />
            </li>
            {/* <li className="ml-2 lg:ml-4 relative inline-block">
              <Link className="" to="">
                <div className="absolute -top-1.5 right-2 z-10 bg-yellow-400 text-xs font-bold h-4 w-4 rounded-full flex items-center justify-center">
                  0
                </div>
                <div className="h-9 lg:h-10 text-gray-500 flex flex-col items-center">
                  <AiOutlineHeart size={25} />
                  <span className="font-normal text-sm">Wishlist</span>
                </div>
              </Link>
            </li> */}
            <li className=" ml-1 sm:ml-2 lg:ml-4 relative inline-block">
              <Link to={`/business/${params.bid}/cart`} className="">
                <div className="absolute -top-1.5 -right-1 z-10 bg-yellow-400 text-xs font-bold h-4 w-4 items-center justify-center flex rounded-full">
                  {products.length}
                </div>
                <div className="h-9 lg:h-10 text-gray-500 flex flex-col items-center">
                  <AiOutlineShoppingCart size={25} />
                  <span className="font-normal text-sm">    {businessModel==='service-based'? "Basket":"Cart"}</span>
                </div>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <SidebarCategoryModal
        onHideSidebar={hideSidebarCategoryHandler}
        isSidebarShown={isSidebarCategoryModalShown}
      />
    </header>
  );
};

export default Navbar;
