import React, { useEffect, useState } from "react";
import { api } from "../../../../apis";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getOwnerToken } from "../../../../utils/businessDetails";

const Logo = () => {
  const [displayedLogo, setDisplayedLogo] = useState("");
  const [logo, setLogo] = useState(""); //to input file from local
  const [imgURL, setImgURL] = useState("");
  const params = useParams();

  const inputChangeHandler = (event) => {
    const file = event.target.files[0];
    setLogo(file);

    const imgURL = URL.createObjectURL(file);
    setImgURL(imgURL);
  };

  const removeImageHandler = () => {
    setLogo("");
    setImgURL("");
    setDisplayedLogo("");
  };

  const updateLogoHandler = () => {
    const formData = new FormData();
    formData.append("image", logo);

    api
      .post(`/businesses/${params.bid}/logo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getOwnerToken()}`
        },
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        if(error.code==="ERR_NETWORK") {
          alert(`${error.message} \nKindly check your connection`);
          return;
        }
        alert(`Error fetching logo - ${error.response.data.error_message}`);
      });
  };

  useEffect(() => {
    api
      .get(`businesses/${params.bid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getOwnerToken(),
        },
      })
      .then((response) => {
        setDisplayedLogo(response.data.logo[0]);
        setImgURL(response.data.logo[0]);
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          alert(`${error.message} \nKindly check your connection`);
          return;
        }
        alert(error.response.data.error_message);
      });
  }, [params.bid]);

  return (
    <div className="bg-white shadow-lg rounded-md p-4 mt-4">
      <h1 className="text-xl text-blue-500 border-b pb-2">
        <strong>Add/Update your store logo</strong>
      </h1>
      {displayedLogo ? (
        <div className="mt-4">
          <img alt="logo" src={displayedLogo} className="w-32 h-32" />
          <button
            className="bg-red-500 hover:bg-red-400 text-white rounded-md px-4 py-1 mt-4"
            onClick={removeImageHandler}
          >
            Remove
          </button>
        </div>
      ) : logo ? (
        <div className="mt-4">
          <img alt="logo" src={imgURL} className="w-32 h-32" />
          <button
            className="bg-indigo-500 hover:bg-indigo-400 text-white rounded-md px-4 py-1 mt-4"
            onClick={updateLogoHandler}
          >
            Update
          </button>
          <button
            className="bg-red-500 hover:bg-red-400 text-white rounded-md px-4 py-1 mt-4 ml-4"
            onClick={removeImageHandler}
          >
            Remove
          </button>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full mt-4">
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                JPG or JPEG
              </p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={inputChangeHandler}
            />
          </label>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Logo;
