import React, { useEffect, useState } from "react";
import { api } from "../../../apis";
import { Link, useParams } from "react-router-dom";
import ChildComponent from "./ChildComponent";

const SidebarCategoryModal = (props) => {
  const params = useParams();
  const [categories, setCategories] = useState([]);
  const [showNested, setShowNested] = useState({});

  const toggleNestedHandler = (category_id) => {
    setShowNested({
      ...showNested,
      [category_id]: !showNested[category_id],
    });
  };

  const hideSidebarHandler = () => {
    props.onHideSidebar();
    setShowNested({});
  };

  useEffect(() => {
    api.get(`businesses/${params.bid}/categories`).then((response) => {
      setCategories(response.data.categories);
    }).catch((error) => {
      if(error.code==="ERR_NETWORK") {
        alert(`${error.message} \nKindly check your connection`);
        return;
      }
      alert(`Error fetching categories \n${error.response.data.error_message}`);
    });
  }, [params.bid]);

  return (
    <>
    {/* bg-gradient-to-r from-cyan-500 to-blue-500 */}
    {props.isSidebarShown && <div className="z-30 bg-black fixed top-0 left-0 opacity-70 w-screen h-screen"></div>}
      <div
        className={`text-white bg-white shadow shadow-black h-screen max-w-fit fixed z-40 top-0 ease-in-out duration-700 ${
          props.isSidebarShown ? "left-0" : "-left-80"
        }`}
        onMouseLeave={hideSidebarHandler}
      >
        <button
          className="absolute -right-7 top-4 text-white text-2xl h-5 w-5 flex items-center justify-center"
          onClick={hideSidebarHandler}
        >
          X
        </button>
        <div className="bg-gray-700 w-full mb-6 px-8 py-4 text-center">
        <p className="uppercase text-white font-bold text-xl">
          All categories
        </p>
        </div>
        {categories.map((category) => (
          <div
            key={category.id}
            className="relative mb-4 text-lg text-gray-600 font-semibold py-1 px-8"
          >
            <div className="flex items-center">
              <button
                onClick={() => toggleNestedHandler(category.id)}
                className="bg-indigo-600 text-white h-4 w-4 rounded-full flex items-center justify-center relative"
              >
                {!showNested[category.id] && <span>+</span>}
                {showNested[category.id] && <span>-</span>}
              </button>
              <Link
                to={`/business/${params.bid}/products/category/${category.id}`}
                className="ml-2 hover:cursor-pointer hover:text-xl transition-all duration-300 ease-in-out"
              >
                {category.name}
              </Link>
            </div>
            {showNested[category.id] && category.children.length > 0 && (
              <div className="relative">
                <div
                  className="border absolute left-[7px] -top-[6px] bottom-[13px]"
                ></div>
                <ChildComponent children={category.children} level={1} />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default SidebarCategoryModal;
