import React, { useEffect, useState } from "react";
import { api } from "../../../../apis";
import { useParams } from "react-router-dom";
import EnquiryLedger from "./EnquiryLedger";
import { LiaEditSolid } from "react-icons/lia";
import { AiOutlineSend } from "react-icons/ai";
import ImageExpander from "./ImageExpander";
import toast,{Toaster} from "react-hot-toast";
import CurrencyFormat from 'react-currency-format';
const EnquiryDetails = (props) => {
  const params = useParams();
  const authToken = JSON.parse(localStorage.getItem("owner")).token;
  const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);
  const [description, setDescription] = useState("");
  const [enquiryDetails, setEnquiryDetails] = useState([
    {
      first_name: "",
      last_name: "",
      email: "",
      price: "",
      delivery_mode: "",
      address_details: [
        {
          address: "",
          state: "",
          city: "",
          pincode: "",
        },
      ],
      description: "",
      pinned_images: [],
      photo:'',
    },
  ]);
  const [enquiryLedger, setEnquiryLedger] = useState([
    {
      remark: "",
      price: "",
      status: "",
      updated_on: "",
    },
  ]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedImages, setExpandedImages] = useState([]);

  const textareaResizeHandler = (e) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const changeJobDescriptionHandler = () => {
    setIsDescriptionEditable(false);
    const form_data = {
      enquiry_id: props.enquiryId,
      description: description,
    };

    api
      .put(
        `businesses/${params.bid}/admin/enquiry/description`,
        JSON.stringify(form_data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        toast.success("description updated successfully");
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          toast.error(`${error.message} \nKindly check your connection`);
          return;
        }
        toast.error(error.response.data.error_message);
      });
  };

  const showImageExpanderHandler = (images) => {
    setExpandedImages(images);
    setIsExpanded(true);
  };

  const hideImageExpanderHandler = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    if (props.isDetailsShown === true) {
      api
        .get(`/businesses/${params.bid}/admin/enquiry_details`, {
          params: { enquiry_id: props.enquiryId },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          setEnquiryDetails(response.data.enquiry_details);
          setEnquiryLedger(response.data.enquiry_ledger);
          // console.log(response.data.enquiry_details[0].job_description);
          setDescription(response.data.enquiry_details[0].job_description);
        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {
            toast.error(`${error.message} \nKindly check your connection`);
            return;
          }
          toast.error(
            `Error fetching Enquiry Details: ${error.response.data.error_message}`
          );
        });
    }
  }, [params.bid, props.enquiryId, props.isDetailsShown, authToken]);

  // // console.log(enquiryDetails[0].address_details[0]);

  return (
    <>
      <td colSpan={3}>
        <div
          className={` bg-white overflow-y-auto transition-all h-[500px] duration-700 ${
            props.isDetailsShown ? "max-h-[500px] p-6 border" : "max-h-0"
          } ease-in-out`}
        >
          <div className="relative">
            <span className="absolute -top-2.5 left-4 bg-white text-gray-400">
              Enquiry details
            </span>
            <div className="flex gap-20 items-center border rounded-lg p-4">
               <div>
              <img
                src={enquiryDetails[0].photo}
                alt="profile"
                className="rounded-full w-32 h-32"
              />
            </div>
              <div>
                <div className="flex flex-row gap-2">
                  <p>Customer Name:</p>
                  <p className="text-black">
                    {enquiryDetails[0].first_name +
                      " " +
                      enquiryDetails[0].last_name}
                  </p>
                </div>
                <div className="flex flex-row gap-2">
                  <p>Customer Email:</p>
                  <p className="text-black">{enquiryDetails[0].email}</p>
                </div>
                <div className="flex flex-row gap-2">
                  <p>Customer Phone No.:</p>
                  <p className="text-black">+17160754100</p>
                </div>
                <div className="flex flex-row gap-2">
                  <p>Delivery Mode:</p>
                  <p className="text-black">
                    {enquiryDetails[0].delivery_mode}
                  </p>
                </div>
                <div className="flex flex-row gap-2">
                  <p>Address:</p>
                  <p className="text-black">{`${enquiryDetails[0].address_details[0].address}, ${enquiryDetails[0].address_details[0].city}, ${enquiryDetails[0].address_details[0].state} ${enquiryDetails[0].address_details[0].pincode}`}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 relative border rounded-lg p-4">
            <span className="absolute -top-2.5 left-4 bg-white text-gray-400">
              Job details
            </span>
            {!isDescriptionEditable && (
              <div className="flex justify-between">
                <p>
                  Description: <span>{description}</span>
                </p>
                <button onClick={() => setIsDescriptionEditable(true)}>
                  <LiaEditSolid size={25} />
                </button>
              </div>
            )}
            {isDescriptionEditable && (
              <p className="flex justify-between">
                <p>Description: </p>
                <textarea
                  name="description"
                  className="border w-full overflow-visible p-1"
                  onKeyDown={(event) => textareaResizeHandler(event)}
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                >
                  {/* {description} */}
                </textarea>
                <button onClick={changeJobDescriptionHandler}>
                  <AiOutlineSend size={25} />
                </button>
              </p>
            )}
            <p>Images:</p>
            <div className="flex gap-2 flex-wrap" onClick={() => showImageExpanderHandler(enquiryDetails[0].pinned_images)}>
              {enquiryDetails[0].pinned_images.map((image, index) => (
              <img
              key={index}
                // src={process.env.REACT_APP_SERVER_URL + image}
                src={image}
                alt="job"
                className="w-36 h-36 object-cover"
              />
            ))}
            </div>
          </div>
          <div className="mt-4 flex justify-between px-4">
            <div>
              <p>
                Estimated price: <CurrencyFormat value={enquiryDetails[0].price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />

                {/*Estimated price: <span>${parseInt(enquiryDetails[0].price).toFixed(2)}</span>*/}
              </p>
            </div>
            {/* <div className="flex gap-4">
            {enquiryDetails[0].status === "INITIATED" && (
              <button
                onClick={props.onClickQuote}
                className="font-medium text-white hover:underline flex bg-blue-600 border rounded-lg py-1 px-2 items-center"
              >
                <BiSolidEdit size={20} />
                Quote
              </button>
            )}
            {enquiryDetails[0].status === "INITIATED" && (
              <button
                to="#"
                className="font-medium text-white hover:underline flex bg-red-600 border rounded-lg py-1 px-2 items-center"
              >
                <TiCancel size={20} />
                Cancel
              </button>
            )}
          </div> */}
          </div>
        </div>
      </td>
      <EnquiryLedger
        isDetailsShown={props.isDetailsShown}
        enquiryLedger={enquiryLedger}
        customerImage={enquiryDetails[0].photo}
        enquiryCreatorName={
          enquiryDetails[0].first_name + " " + enquiryDetails[0].last_name
        }
      />
      {isExpanded && <ImageExpander onClose={hideImageExpanderHandler} images={expandedImages} type="details" user="owner" />}
      <Toaster/>
    </>
  );
};

export default EnquiryDetails;
