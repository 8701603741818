import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { LiaFilterSolid } from "react-icons/lia";
import OrdersFilter from "./OrdersFilter";
import { api } from "../../../../../apis";
import { useParams } from "react-router-dom";
import CustomerSingleOrder from "./CustomerSingleOrder";
import Pagination from "../../../UI/Pagination";
import {getBusinessModel, getCustomerToken} from "../../../../../utils/businessDetails";

const CustomerOrdersList = () => {
  const [isFilterShown, setIsFilterShown] = useState(false);
  const params = useParams();

  const businessModel = getBusinessModel(params.bid);
  // const authToken = JSON.parse(
  //   localStorage.getItem(`${params.bid}-customer`)
  // ).token;
  const user_id = JSON.parse(localStorage.getItem(`${params.bid}-customer`))
    .user.id;
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(5);
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
  const totalPages = Math.ceil(orders.length / ordersPerPage);

  const paginateFront = () => {
    if (currentPage < totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const paginateBack = () => {
    if (currentPage > 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const showFilterHandler = () => {
    setIsFilterShown(true);
  };

  const hideFilterHandler = () => {
    setIsFilterShown(false);
  };

  useEffect(() => {
    console.log(params.bid)
console.log(JSON.parse(localStorage.getItem(`${params.bid}-customer`)))
    console.log(getCustomerToken(params.bid))
    api
      .get(
        `/businesses/${params.bid}/orders`,
        { params: { user_id: user_id },
          headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCustomerToken(params.bid)}`
      } },
      )
      .then((response) => {
        let rawOrders = response.data;

        rawOrders.forEach(x => {
          x.order_items = x.order_items.reduce((acc, curr) => {

                let curr_product_id = curr.product_id;
                let found = acc.find(x => x.product_id === curr_product_id && businessModel === "product-based" );

                // if(found && businessModel.business_model == "product-based" ){
                if(found ){
                  found.grouped_items.push({order_item_id : curr.order_item_id, permitted_states: curr.permitted_states, current_status : curr.status });
                } else {
                  curr.grouped_items = []
                  curr.grouped_items.push({order_item_id : curr.order_item_id, permitted_states: curr.permitted_states, current_status : curr.status });
                  acc.push(curr);
                }
                return acc;
              }, []
          )
        })
        setOrders(rawOrders);
      })
      .catch((error) => {
        if(error.code==="ERR_NETWORK") {
          alert(`${error.message} \nKindly check your connection`);
          return;
        }
        alert(`Error fetching orders \n${error.response.data.error_message}`);
      });
  }, [params.bid, user_id, businessModel]);

  return (
    <div className="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
      <main className="p-4">
        <div className="flex justify-between items-center">
          <div className="flex-2">
            <h1 className="font-bold -mb-1 text-lg">All orders</h1>
            <p className="text-gray-600 text-sm">from anytime</p>
          </div>
          <div className="flex gap-4 items-center flex-1 justify-end">
            <div className="flex items-center border border-black gap-4 rounded-md p-2 text-gray-400 w-1/2">
              <AiOutlineSearch size={20} />
              Search
            </div>
            <button
              className="flex items-center border border-gray-300 rounded-md p-2"
              onClick={showFilterHandler}
            >
              <LiaFilterSolid size={20} />
              Filter
            </button>
          </div>
        </div>
        {currentOrders.map((order, index) => (
          <CustomerSingleOrder
            key={index}
            order={order}
            customerName={
              order.first_name.charAt(0).toUpperCase() +
              order.first_name.slice(1) +
              " " +
              order.last_name.charAt(0).toUpperCase() +
              order.last_name.slice(1)
            }
          />
        ))}
      </main>
      {isFilterShown && <OrdersFilter onClose={hideFilterHandler} />}
      <footer className="max-w-4xl">
        <Pagination
          totalOrders={orders.length}
          paginateFront={paginateFront}
          paginateBack={paginateBack}
          ordersPerPage={ordersPerPage}
          currentPage={currentPage}
        />
      </footer>
    </div>
  );
};

export default CustomerOrdersList;
