import React, { useState } from "react";
import "./SingleCategory.scss";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from "../../../../apis";
import { BiArrowBack } from "react-icons/bi";
import { getOwnerToken } from "../../../../utils/businessDetails";
import RecursiveComponent from "../Products/RecursiveComponent";

const SingleCategory = () => {
  const navigate = useNavigate();
  const categoryObj = useLocation().state;
  const [categoryImg, setCategoryImg] = useState([categoryObj.mediaURL]);
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState({
    id: categoryObj.id,
    name: categoryObj.name,
    description: categoryObj.description,
    media: "",
    categories: categoryObj.categories,
    parent_id: categoryObj.parentId,
  });

  const params = useParams();
  // const token = localStorage.getItem("owner");

  const addImageHandler = (event) => {
    const file = event.target.files[0];
    setCategoryData((prevState) => ({ ...prevState, media: file }));
    // const imgObj = {
    //   src: URL.createObjectURL(file),
    //   id: Math.random() * 10,
    // };
    const imgArr = [];
    imgArr.push(URL.createObjectURL(file));

    setCategoryImg(imgArr);
  };

  const deleteImageHandler = (index) => {
    // let imgArr = [...categoryImg];
    // imgArr = imgArr.filter((item) => item.id !== index);
    const imgArr = [];
    setCategoryImg(imgArr);
    setCategoryData((prevState) => ({ ...prevState, media: "" }));
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setCategoryData((prevState) => ({ ...prevState, [name]: value }));
  };

  const [parentId, setParentId] = useState(categoryObj.parentId);

  const findDisabledCategoryObj = (categories, nestingKey) => {
    // // console.log(categories);
    return categories.reduce((acc, { [nestingKey]: nested, ...o }) => {
      if (o.id === categoryObj.id) {
        acc.push(nested);
      }

      if (nested.length > 0) {
        acc.push(...findDisabledCategoryObj(nested, nestingKey));
      }

      return acc;
    }, []);
  };

  const disabledCategoryObj = findDisabledCategoryObj(
    categoryObj.categories,
    "children"
  )[0];

  const findDisabledCategories = (disabledCategoryObj) => {
    let result = [];

    while (disabledCategoryObj.length > 0) {
      disabledCategoryObj = disabledCategoryObj[0];
      result.push(disabledCategoryObj.id);
      disabledCategoryObj = disabledCategoryObj.children;
    }

    return result;
  };

  const disabledCategoriesArray = findDisabledCategories(disabledCategoryObj);

  const selectParentCategoryHandler = (parentCategoryId) => {
    setParentId((prevState) => parentCategoryId);
  };

  const checkDataChanged = () => {
    if (
      categoryData.name === categoryObj.name &&
      (!parentId ||
      parentId === categoryObj.parentId) &&
      categoryData.description === categoryObj.description &&
      categoryImg[0] === categoryObj.mediaURL
    ) {
      return false;
    }
    return true;
  };

  const formSubmitHandler = (event) => {
    // send data to server
    event.preventDefault();
    const formData = new FormData();

    if(!checkDataChanged()) {
      alert("No changes detected.");
      return;
    }

    if(categoryData.name!==categoryObj.name) {
      formData.append("name", categoryData.name);
    }
    if (parentId && parentId !== categoryObj.parentId) {
      formData.append("parent_id", parentId);
    }
    if(categoryData.description !== categoryObj.description) {
      formData.append("description", categoryData.description);
    }
    if(categoryImg[0] !== categoryObj.mediaURL) {
      formData.append("image", categoryData.media);
    }

    setLoading(true);

    api
      .put(`/businesses/${params.bid}/categories/${categoryObj.id}`, formData, {
        headers: {
          Authorization: `Bearer ${getOwnerToken()}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setLoading(false);
        toast.success("Category updated successfully");
        navigate("..");
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          setLoading(false);
          alert(`${error.message} \nKindly check your connection`);
          return;
        }
        toast.error(
          `Error updating category \n${error.response.data.error_message}`
        );
        setLoading(false);
      });
  };

  return (
    <div className="flex items-center justify-center p-8 lg:w-4/5 lg:ml-auto max-sm:w-full max-sm:px-2">
      <div className="mx-auto w-full sm:max-w-lg">
        <div className="flex gap-4 items-center px-9">
          <Link
            to=".."
            className="border p-2 rounded-md border-gray-400 hover:bg-white"
          >
            <BiArrowBack size={20} color="gray" />
          </Link>
          <span className="font-medium text-xl">Update category</span>
        </div>
        <form className="py-6 px-9" onSubmit={formSubmitHandler}>
          <div className="p-4 shadow-lg rounded-md bg-white">
            <div className="mb-5">
              <label
                htmlFor="name"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                Title
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Tshirt"
                  required
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  value={categoryData.name}
                  onChange={inputChangeHandler}
                />
              </label>
            </div>

            <label
              htmlFor="description"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              Description
            </label>
            <div className="w-full mb-5">
              <textarea
                id="description"
                name="description"
                rows="4"
                className="w-full border rounded-md border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md focus:border"
                placeholder="TShirt Collection..."
                required
                maxLength="200"
                value={categoryData.description}
                onChange={inputChangeHandler}
              ></textarea>
            </div>
            <div className="mb-5">
              <label
                htmlFor="parentCategory"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                Parent Category
              </label>
              {/* <Categories categories={categoryData.categories} level={0} selectParentCategoryHandler={selectParentCategoryHandler} parentId={categoryData.parent_id} /> */}
              <RecursiveComponent
                data={categoryData.categories}
                level={0}
                selectedItem={parentId}
                selectItem={selectParentCategoryHandler}
                disabledCategoriesArray={disabledCategoriesArray}
              />
            </div>
          </div>

          <div className="p-4 shadow-lg rounded-md bg-white mt-6">
            <div>
              <label className="mb-3 block text-base font-medium text-[#07074D]">
                Upload File
              </label>

              <div>
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="sr-only"
                  onChange={addImageHandler}
                />
                <label
                  htmlFor="file"
                  className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                >
                  <div>
                    <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                      Drop files here
                    </span>
                    <span className="mb-2 block text-base font-medium text-[#6B7280]">
                      Or
                    </span>
                    <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] hover:cursor-pointer">
                      Browse
                    </span>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="p-4 shadow-lg rounded-md bg-white mt-6">
            <div className="mb-5">
              <label
                htmlFor="category"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                Media
              </label>
            </div>
            <div className="flex gap-2">
              {categoryImg.length > 0 &&
                categoryImg.map((item, index) => (
                  <div className="relative w-32 h-32" key={index}>
                    <img
                      id={index}
                      src={item}
                      alt="category"
                      className="w-32 h-32 object-cover absolute top-0 left-0"
                    />
                    <div className="absolute top-0 left-0 hover:bg-gray-600 hover:bg-opacity-30 hover:cursor-pointer w-full h-full"></div>
                    <div
                      className="absolute left-12 top-12 mx-auto w-4 h-4 text-center text-5xl flex items-center justify-center text-transparent hover:text-gray-700 hover:cursor-pointer"
                      onClick={(event) => deleteImageHandler(item.id)}
                    >
                      X
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <hr className="mt-6 border-gray-300" />
          <div className="mt-6 flex justify-end">
            <button
              className="hover:shadow-form w-40 rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
              type="submit"
              disabled={loading}
            >
              {loading? "...Updating" : "Update"}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SingleCategory;
