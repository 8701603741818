import React, { useEffect } from 'react';
import { Outlet, useParams, useRouteLoaderData, useSubmit } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { getCustomerTokenDuration } from '../../../utils/businessDetails';
import  toast, { Toaster } from 'react-hot-toast';

const Layout = () => {
  const params = useParams();
  const token = useRouteLoaderData("checkout");
  const submit = useSubmit();

  useEffect(() => {
    if(!token) {
      return;
    }

    if (token === "EXPIRED") {
      toast.error("Token Expired \nPlease login to checkout");
      submit(null, { action: `/business/${params.bid}/logout`, method: "get" });
      return;
    }

    const tokenDuration = getCustomerTokenDuration(params.bid);

    setTimeout(() => {
      toast.error("Token Expired \nPlease login to checkout");
      submit(null, { action: `/business/${params.bid}/logout`, method: "get" });
    }, tokenDuration);
  }, [token, submit, params.bid]);

  return (
    <div>
      <Toaster />
        <Navbar />
        <Outlet />
        <Footer />
    </div>
  )
}

export default Layout