import React, { useState } from "react";
import Modal from "../UI/Modal";

const EnquiryForm = (props) => {
  const [imgArrObj, setImgArrObj] = useState([]);
  const [enquiryFormData, setEnquiryFormData] = useState({
    message: "",
    photos: [],
  });
  // const resetImgRef = useRef();

  // const parseURI = async (file) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   return new Promise((resolve) => {
  //     reader.onload = (e) => {
  //       resolve(e.target.result);
  //     };
  //   });
  // };

  // const addImageHandler = async (event) => {
  //   const files = event.target.files;
  //   const newImagesList = [...imgArrObj];
  //   const filesArray = Array.from(files);
  //   for (let file of filesArray) {
  //     const base64img = await parseURI(file);
  //     newImagesList.push({
  //       id: Math.random() * 100,
  //       file: file,
  //       imgName: file.name,
  //       imgLink: base64img,
  //     });
  //   }
  //   setImgArrObj(newImagesList);
  //   setEnquiryFormData((prevState) => ({
  //       ...prevState,
  //       // photos: newImagesList,
  //       photos: Array.from(files),
  //   }));
  //   // reset the input field so that user can upload more images in one go if needed
  //   // resetImgRef.current.value = null;
  // };

  const imageDeleteHandler = (id) => {
    const updatedImageList = imgArrObj.filter((item) => item.id !== id);
    setImgArrObj(updatedImageList);
    const files = [];
    for(const item of updatedImageList) {
      files.push(item.file);
    }
    setEnquiryFormData((prevState) => ({
      ...prevState,
      // photos: updatedImageList,
      photos: files,
    }));
  };

  const inputChangeHandler = (event) => {
    const {name, value} = event.target;
    setEnquiryFormData((prevState) => ({
        ...prevState,
        [name]: value,
    }));
  };

  const addToCart = (event) => {
    event.preventDefault();
    props.onAddToCart(enquiryFormData);
    event.target.setAttribute("disabled", true); //disable button afer adding the item once
  }

  return (
    <Modal onBackdropClick={props.onHideForm}>
      <section className="bg-white dark:bg-gray-900 max-h-screen w-3/4 mx-auto max-lg:w-full max-sm:h-screen overflow-y-scroll">
        <div className="py-4 lg:py-6 px-4 mx-auto max-w-screen-md">
          <div className="flex justify-end">
            <button className="text-black" onClick={props.onHideForm}>X</button>
          </div>
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
            Contact Us
          </h2>
          <p className="mb-4 lg:mb-8 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
            Need details about this service? Let us know.
          </p>
          <form className="space-y-4">
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Enquiry
              </label>
              <textarea
                id="message"
                rows="6"
                name="message"
                value={enquiryFormData.message}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Leave a comment..."
                onChange={inputChangeHandler}
              ></textarea>
            </div>
            {/* <div>
              <label
                htmlFor="upload"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Upload Images
              </label>
              <input
                type="file"
                id="upload"
                multiple
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                onChange={addImageHandler}
                ref={resetImgRef}
              />
            </div> */}
            {/* <div className="flex flex-col gap-y-1">
              {imgArrObj.map((item, index) => (
                <div
                  className="p-3 w-full flex justify-between text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  key={index}
                >
                  <span>
                    {item.imgName.length > 50
                      ? `${item.imgName.substring(0, 50)}...`
                      : item.imgName}
                  </span>
                  <div className="hover:cursor-pointer" onClick={imageDeleteHandler.bind(null, item.id)}>
                    X
                  </div>
                </div>
              ))}
            </div> */}
            <button
              type="submit"
              onClick={addToCart}
              className="py-3 px-5 text-sm font-medium text-center border-white dark:text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
            Add to Quote Basket
            </button>
          </form>
        </div>
      </section>
    </Modal>
  );
};

export default EnquiryForm;
