import React, { useEffect, useState } from "react";

const Pagination = ({
  ordersPerPage,
  paginateFront,
  paginateBack,
  totalOrders,
  currentPage,
}) => {
  const [disabledPrev, setDisabledPrev] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);

  useEffect(() => {
    if(currentPage===1) {
      setDisabledPrev(true);
    }
    else {
      setDisabledPrev(false);
    }

    if(currentPage===Math.ceil(totalOrders/ordersPerPage)) {
      setDisabledNext(true);
    }
    else {
      setDisabledNext(false);
    }
  }, [currentPage, totalOrders, ordersPerPage]);

  return (
    <div className="flex flex-col items-center mb-8">
      <div className="font text-gray-600">
        <span>Showing </span>
        <span>{currentPage * ordersPerPage - 4}</span>
        <span> to </span>
        <span>{(currentPage * ordersPerPage) > totalOrders ? totalOrders : (currentPage * ordersPerPage)}</span>
        <span> of </span>
        <span>{totalOrders}</span>
        <span> orders</span>
      </div>
      <div className="mt-2">
        <button
          className={`mr-4 px-4 py-1 text-white rounded-md ${disabledPrev ? "bg-gray-600" : "bg-indigo-700 hover:bg-indigo-600"}`}
          onClick={paginateBack}
          disabled={disabledPrev}
        >
          Prev
        </button>
        <button
          className={`px-4 py-1 text-white rounded-md ${disabledNext ? "bg-gray-600" : "bg-indigo-700 hover:bg-indigo-600"}`}
          onClick={paginateFront}
          disabled={disabledNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
