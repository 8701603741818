import React, { useEffect, useState } from 'react';
import {
  BiSolidDashboard,
  BiCreditCardFront,
  BiSolidCategory,
  BiLogOut,
} from "react-icons/bi";
import { MdStoreMallDirectory, MdOutlinePayments } from "react-icons/md";
import { BsFillFolderSymlinkFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { IoIosBusiness } from "react-icons/io";
import { Link, useParams } from 'react-router-dom';
import { getBusiness } from '../../../utils/businessDetails';
import "./SidebarContent.scss";

const SidebarContent = () => {
    const params = useParams();
  const [listItem, setListItem] = useState("");
  // const [logoImg, setLogoImg] = useState("");
  const businessModel = getBusiness(params.bid);

  useEffect(() => {
    if (window.location.pathname.split("/")[3]) {
      setListItem(window.location.pathname.split("/")[3]);
    } else {
      setListItem("");
    }
  }, []);

  return (
    <div className="flex flex-col justify-between h-full border-r bg-white w-full z-[110]">
        <div className="center">
          <ul>
            <p>MAIN</p>
            <li
              onClick={() => setListItem("")}
              className={`${
                listItem === "" &&
                "bg-gradient-to-r from-sky-600 to-cyan-400 rounded-md text-white"
              } hover:text-gray-400`}
            >
              <Link to={`/admin/${params.bid}`} className="w-full">
                <BiSolidDashboard color="purple" size={25} />
                <span className="">Dashboard</span>
              </Link>
            </li>
            <p>LISTS</p>
            <li
              onClick={() => setListItem("orders")}
              className={`${
                listItem === "orders" &&
                "bg-gradient-to-r from-sky-600 to-cyan-400 rounded-md text-white"
              } hover:text-gray-400`}
            >
              <Link to={`/admin/${params.bid}/orders`} className="w-full">
                <BiCreditCardFront color="purple" size={20} />
                <span>Orders</span>
              </Link>
            </li>
            {businessModel === "service-based" && (
              <li
                onClick={() => setListItem("enquiries")}
                className={`${
                  listItem === "enquiries" &&
                  "bg-gradient-to-r from-sky-600 to-cyan-400 rounded-md text-white"
                } hover:text-gray-400`}
              >
                <Link to={`/admin/${params.bid}/enquiries`} className="w-full">
                  <BsFillFolderSymlinkFill color="purple" size={20} />
                  <span>Enquiries</span>
                </Link>
              </li>
            )}
            <li
              onClick={() => setListItem("categories")}
              className={`${
                listItem === "categories" &&
                "bg-gradient-to-r from-sky-600 to-cyan-400 rounded-md text-white"
              } hover:text-gray-400`}
            >
              <Link to={`/admin/${params.bid}/categories`} className="w-full">
                <BiSolidCategory color="purple" size={20} />
                <span>Categories</span>
              </Link>
            </li>
            <li
              onClick={() => setListItem("products")}
              className={`${
                listItem === "products" &&
                "bg-gradient-to-r from-sky-600 to-cyan-400 rounded-md text-white"
              } hover:text-gray-400`}
            >
              <Link to={`/admin/${params.bid}/products`} className="w-full">
                <MdStoreMallDirectory color="purple" size={20} />
                <span>Products</span>
              </Link>
            </li>

            <li
              onClick={() => setListItem("customers")}
              className={`${
                listItem === "customers" &&
                "bg-gradient-to-r from-sky-600 to-cyan-400 rounded-md text-white"
              } hover:text-gray-400`}
            >
              <Link to={`/admin/${params.bid}/customers`} className="w-full">
                <FaUsers color="purple" size={20} />
                <span>Customers</span>
              </Link>
            </li>
            <li
              onClick={() => setListItem("payment")}
              className={`${
                listItem === "payment" &&
                "bg-gradient-to-r from-sky-600 to-cyan-400 rounded-md text-white"
              } hover:text-gray-400`}
            >
              <MdOutlinePayments color="purple" size={20} />
              <span>Payment</span>
            </li>
            <li
              onClick={() => setListItem("manage-business")}
              className={`${
                listItem === "manage-business" &&
                "bg-gradient-to-r from-sky-600 to-cyan-400 rounded-md text-white"
              } hover:text-gray-400`}
            >
              <Link
                to={`/admin/${params.bid}/manage-business`}
                className="w-full"
              >
                <IoIosBusiness color="purple" size={20} />
                <span>Manage Business</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="border-t border-b center">
          <ul>
            <li
                onClick={() => setListItem("logout")}
                className={`${
                  listItem === "logout" &&
                  "bg-gradient-to-r from-sky-600 to-cyan-400 rounded-md text-white"
                } hover:text-gray-400`}
              >
                <BiLogOut
                  color="purple"
                  size={20}
                  style={{ transform: "rotate(180deg)" }}
                />
                <Link to="/logout" className="">
                  Logout
                </Link>
            </li>
          </ul>
        </div>
      </div>
  )
};

export default SidebarContent;