import React, { useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import { Outlet, useLoaderData, useParams, useSubmit } from "react-router-dom";
import { getCustomerTokenDuration } from "../../../../../utils/businessDetails";

import toast,{Toaster} from "react-hot-toast";

const CustomerDashboardLayout = () => {
  const params = useParams();
  const token = useLoaderData();
  const submit = useSubmit();

  useEffect(() => {
    if(!token) {
      return;
    }

    // console.log("inside customer dashboard layout");

    if (token === "EXPIRED") {
      toast.error("Token Expired \nLogin to continue");
      submit(null, { action: `/business/${params.bid}/logout`, method: "get" });
      return;
    }

    const tokenDuration = getCustomerTokenDuration(params.bid);

    setTimeout(() => {
      toast.error("Token Expired \nLogin to continue");
      submit(null, { action: `/business/${params.bid}/logout`, method: "get" });
    }, tokenDuration);
  });

  return (
    <>
      <Toaster />
      <Sidebar />
      <Navbar />
      <Outlet />
    </>
  );
};

export default CustomerDashboardLayout;
