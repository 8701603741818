import React from "react";
import Delivery from "./Delivery";
import Address from "./Address/Address";
import Logo from "./Logo";
import "./ManageBusiness.scss";

const ManageBusiness = () => {
  return (
    <div className="px-12 py-4 bg-white ml-auto h-fit manageBusinessContainer">
      {/* Update delivery methods */}
      <Delivery />
      {/* update store address */}
      <Address />
      {/* add or update store logo */}
      <Logo />
    </div>
  );
};

export default ManageBusiness;
