import React, { createContext, useCallback, useContext, useState } from "react";

export const FilterContextDispatch = createContext();
export const FilterStateContext = createContext();

const FilterContext = ({ children }) => {
  const [query, setQuery] = useState("");

  const setQueryHandler = (queryInput) => {
    setQuery(queryInput);
  };

  const resetQueryHandler = useCallback(() => {
    setQuery("");
  }, []);

  return (
    <FilterContextDispatch.Provider value={setQueryHandler}>
      <FilterStateContext.Provider value={{ query, resetQueryHandler }}>
        {children}
      </FilterStateContext.Provider>
    </FilterContextDispatch.Provider>
  );
};

export default FilterContext;
export const useFilterDispatch = () => useContext(FilterContextDispatch);
export const useFilterState = () => useContext(FilterStateContext);
