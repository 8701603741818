import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCustomerDetails } from "../../../utils/businessDetails";
import { LiaUserCircle } from "react-icons/lia";

const Profile = ({setIsAuthenticated, isAuthenticated}) => {
    const [toggleUserMenu, setToggleUserMenu] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    // console.log("isAuthenticated", isAuthenticated);
    const customerDetails = isAuthenticated ? getCustomerDetails(params.bid)?.user : "";
    // console.log(customerDetails)
    const logoutHandler = () => {
    setIsAuthenticated(false);
    navigate(`/business/${params.bid}/logout`);
  }

  return (
    <div className="flex items-center lg:order-2 relative">
      <button
        type="button"
        className="mr-3 text-sm rounded-full md:mr-0 flex flex-col items-center h-10 text-gray-500"
        id="user-menu-button"
        onClick={(event) => (setToggleUserMenu((prevState) => (!prevState)))}
      >
        {/* <span className="sr-only">Open user menu</span> */}
        {/* <img
          className="w-8 h-8 rounded-full"
          src="/docs/images/people/profile-picture-3.jpg"
          alt="userPhoto"
        /> */}
        <LiaUserCircle size={30} />
        <span className=" font-normal text-sm">Profile</span>
      </button>
      
      {toggleUserMenu ? (isAuthenticated ? <div
        className="absolute top-full right-0 z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
        id="user-dropdown"
      >
        <div className="px-4 py-3">
          <span className="block text-sm text-gray-900 dark:text-white">
            {customerDetails.first_name.charAt(0).toUpperCase() + customerDetails.first_name.slice(1) + " " + customerDetails.last_name.charAt(0).toUpperCase() + customerDetails.last_name.slice(1)}
          </span>
          <span className="block text-sm  text-gray-500 truncate dark:text-gray-400">
            {customerDetails.email}
          </span>
        </div>
        <ul className="py-2" aria-labelledby="user-menu-button">
          <li>
            <Link
              to={`/business/${params.bid}/dashboard`}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            >
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            >
              Settings
            </Link>
          </li>
          <li>
            <button
              onClick={logoutHandler}
              className="block px-4 w-full text-left py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            >
              Sign out
            </button>
          </li>
        </ul>
      </div> : 
      <div className="absolute top-full right-0 z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
      id="user-dropdown">
        <div className="px-4 py-3">
          <Link className="block text-sm text-gray-900 dark:text-white hover:cursor-pointer" to={`/business/${params.bid}/login`}>Login/Signup</Link>
        </div>
      </div>
      ) : ""}
    </div>
  );
};

export default Profile;
