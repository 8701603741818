import React, { useEffect, useState } from 'react';
import { MdVerified } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { api } from '../../../apis';
import { IoMdLogIn } from 'react-icons/io';
import ErrorPage from '../../Error/ErrorPage';

const CustomerVerifyEmail = () => {
    const params = useParams();
    const [error, setError] = useState({
      hasOccurred: "",
      description: "",
      code: "",
    });

    useEffect(() => {
    api.get(`/businesses/${params.bid}/users/${params.user_id}/verify/${params.token}`).then((response) => {
      // console.log(response);
      setError({
        hasOccurred: false,
        description: "Your account has been verified successfully",
        code : response?.data?.status || "",
      })
    }).catch((error) => {
      // alert(`Error, ${error?.message || "Something went wrong"}`);
      if(error.code==="ERR_NETWORK") {
        alert(`${error.message} \nKindly check your connection`);
        return;
      }
      setError({
        hasOccurred: true,
        description: `${error.response.data?.msg || "Something went wrong"}`,
        code: error.response.status,
      });
    })
    
}, [params]);

  return (
    !error.hasOccurred ? <div className="app font-sans min-w-screen min-h-screen bg-grey-lighter py-8 px-4">
      <div className="mail__wrapper max-w-md mx-auto">
        <div className="mail__content bg-white p-8 shadow-md">
          <div className="content__header text-center tracking-wide border-b">
            {/* <div className="text-red text-sm font-bold">THEAPP.IO</div> */}
            <h1 className="text-3xl h-24 flex items-center justify-center text-green-600">
                <span>EMAIL VERIFICATION COMPLETE</span>
              <MdVerified size={30} color="" />
            </h1>
          </div>

          <div className="content__body py-8 border-b">
            <p>
              Hey, <br />
              <br />
              Your email has been verified successfully.
            </p>
            {/* <div className="mt-4"><Link to={`/business/${params.bid}/login`} className="text-blue-500 underline font-semibold">Login</Link></div> */}
            <Link to={`/business/${params.bid}/login`} className="text-white text-lg tracking-wide bg-blue-600 rounded w-full my-8 p-4 text-center flex items-center justify-center">
              <span className="mr-2">Login</span>
              <IoMdLogIn size={20} />
            </Link>
            <p className="text-sm">
              Keep Rockin'!
              <br />
            </p>
          </div>

          <div className="content__footer mt-8 text-center text-grey-darker">
            <h3 className="text-base sm:text-lg mb-4">Thanks for using The App!</h3>
            {/* <p>www.theapp.io</p> */}
          </div>
        </div>
      </div>
    </div> :
    error.code === 400 && <ErrorPage description={error.description} pages={`business/${params.bid}/login`} text="Login" />
  );
};

export default CustomerVerifyEmail;