import axios from "axios";

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

export const getUserProfile = async ({ authToken, business_name_id}) => {

    const response = await api.get(  `/businesses/${business_name_id}/users/profile`, {
        headers: { 'Authorization': `Bearer ${authToken}`}
    });
    // console.log(response.data);
    return response.data;
};

export const getOwnerProfile = async ({ authToken}) => {
    const response = await api.get(  `/owner/profile`, {
        headers: {
            'Authorization': `Bearer ${authToken}`
        }
    });
    return response.data;
};

export const getPicture = async (product_ids) => {
    console.log(product_ids);
    let params = new URLSearchParams();
    if(product_ids)
        for (const product_id of product_ids)
            params.append("id",product_id);
    const response = await api.get(`/businesses/demo-services/images/products`, { params }, {});
    console.log(response.data);
    return response.data;
}


export const updatePicture = async ({isOwner,photo, authToken, business_name_id}) => {

// console.log(isOwner,photo, authToken, business_name_id)
    const formData  = new FormData()
    formData.append("image", photo)
    const url =  isOwner ? `/owner/picture` : `/businesses/${business_name_id}/users/picture`;
    const res = await api.post(url,
        formData,
        {headers: {'Authorization': `Bearer ${authToken}`}
        })
    // console.log(res.response);

    return res;
};

export const updateUserProfile = async ({first_name, last_name, phone, authToken, business_name_id}) => {

    const response = await api.put(
        `/businesses/${business_name_id}/users/profile`,
        {first_name, last_name, phone},
        { headers: {'Authorization': `Bearer ${authToken}`}
        });
    // console.log(response);
    return response;
};

export const updateOwnerProfile = async ({first_name, last_name, phone, authToken}) => {

    const response = await api.put(
        `/owner/profile`,
        {first_name, last_name, phone},
        { headers: {'Authorization': `Bearer ${authToken}`}
        });
    // console.log(response);
    return response;
};
