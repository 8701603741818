import React, { useState } from "react";
import List from "../../List/List";
import { useParams } from "react-router-dom";
import "./Products.scss";
import Filter from "./Filter";
import { FaAngleDown } from "react-icons/fa";
import SlidingFilter from "./SlidingFilter";

const Products = () => {
  const params = useParams();
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const showFilterHandler = () => {
    setIsFilterVisible(true);
  }

  const hideFilterHandler = () => {
    setIsFilterVisible(false);
  }

  return (
    <div className="products h-screen">
      {/*<div className="left">*/}
      {/*  <p className=" font-bold border-b mb-4">Filter</p>*/}
      {/*  /!*<Filter />*!/*/}
      {/*</div>*/}
      <div className=" pr-4 border-b shadow-sm filter-bar min-[769px]:hidden">
        <div className=" flex items-start justify-end">
          <button className=" flex items-center gap-2 py-2 px-2 border-l" onClick={showFilterHandler}>
            <span>Filter</span>
            <span>
              <FaAngleDown size={12} />
            </span>
          </button>
        </div>
        {/*{isFilterVisible && <SlidingFilter onClose={hideFilterHandler} />}*/}
      </div>
      
      <div className="right">
        {<List categoryId={params.cid} businessId={params.bid} />}
      </div>
    </div>
  );
};

export default Products;