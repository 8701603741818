import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet, useLoaderData, useSubmit } from "react-router-dom";
import { getOwnerTokenDuration } from "../../../utils/businessDetails";

import  toast, { Toaster } from 'react-hot-toast';
const AdminLayout = () => {
  const token = useLoaderData();
  const submit = useSubmit();

  useEffect(() => {
    if(!token) {
      return;
    }

    // console.log("inside admin layout");

    if (token === "EXPIRED") {
      toast.error("Token Expired \nLogin to continue");
      submit(null, { action: "/logout", method: "get" });
      // console.log("after submit");
      return;
    }

    const tokenDuration = getOwnerTokenDuration();

    const timer = setTimeout(() => {
      toast.error("Token Expired \nLogin to continue.");
      submit(null, { action: "/logout", method: "get" });
    }, tokenDuration);

    return () => clearTimeout(timer);
  }, [token, submit]);

  return (
    <div className="">
      <Toaster />
        <Navbar />
      <div className="">
        <Sidebar />
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
