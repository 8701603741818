import React, { useState } from "react";
import Checkbox from "./Checkbox";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

const RecursiveComponent = ({ data, level, selectedItem, selectItem, disabledCategoriesArray }) => {
  const [showNested, setShowNested] = useState({});

  const toggleNested = (event, id) => {
    event.preventDefault();
    setShowNested((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return data.map((item) => (
    <div key={item.id}>
      <div
        className={` flex justify-between py-1`}
        style={{ marginLeft: `${16 * level}px` }}
      >
        <Checkbox
          type="radio"
          item={item}
          checked={item.id === parseInt(selectedItem) ? true : false}
          disabled={disabledCategoriesArray?.filter((i) => parseInt(i)===parseInt(item.id)).length>0}
          onChange={selectItem}
        />
        {item.children.length > 0 && (
          <button onClick={(event) => toggleNested(event, item.id)}>
            {!showNested[item.id] ? (
              <AiOutlineDown size={15} />
            ) : (
              <AiOutlineUp size={15} />
            )}
          </button>
        )}
      </div>
      <hr />
      {item.children.length > 0 && showNested[item.id] && (
        <RecursiveComponent
          data={item.children}
          level={level + 1}
          selectedItem={selectedItem}
          selectItem={selectItem}
          disabledCategoriesArray={disabledCategoriesArray}
        />
      )}
    </div>
  ));
};

export default RecursiveComponent;
