import React, { useEffect } from "react";
import SelectBusiness from "../components/Shop/SelectBusiness";
import { useLoaderData, useSubmit } from "react-router-dom";
import { getOwnerTokenDuration } from "../utils/businessDetails";
import  toast, { Toaster } from 'react-hot-toast';

const SelectBusinessPage = () => {
  const token = useLoaderData();
  const submit = useSubmit();

  useEffect(() => {
    if(!token) {
      return;
    }

    if (token === "EXPIRED") {
      toast.error("Token expired \nLogin to continue");
      submit(null, { action: "/logout", method: "get" });
      return;
    }

    const tokenDuration = getOwnerTokenDuration();

    setTimeout(() => {
      toast.error("Token expired \nLogin to continue");
      submit(null, { action: "/logout", method: "get" });
    }, tokenDuration);
  }, [token, submit]);

  return (
    <div>
      <Toaster />
      <SelectBusiness />
    </div>
  );
};

export default SelectBusinessPage;
