import React, { useEffect, useState } from "react";
import Card from "../Card/Card";
import "./List.scss";
import { api } from "../../../apis";
import { useNavigate, useParams } from "react-router-dom";
import  toast, { Toaster } from 'react-hot-toast';

const List = (props) => {
  const params = useParams();
  const {categoryId, businessId} = props;
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  const selectProductHandler = (productId) => {
    navigate(`/business/${params.bid}/products/product/${productId}`);
  };

  useEffect(() => {
    api.get(`/businesses/${businessId}/products`, {params: {category_id: categoryId}}).then((response) => {
      setProducts(()=> (response.data));
    }).catch((error) => {
      if(error.code==="ERR_NETWORK") {
        toast.error(`${error.message} \nKindly check your connection`);
        return;
      }
      toast.error(`Cannot load products - ${error.response.data.error_message}`);
    })
  }, [categoryId, businessId]);


  return (
      <>
        <Toaster/>
        <div
            className="grid grid-flow-row gap-8 justify-center items-center text-neutral-600 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {products.map((item) => {
            return <Card item={item} key={item.id} onClick={selectProductHandler.bind(null, item.id)}/>
          })}
        </div>
      </>


  );
};

export default List;
