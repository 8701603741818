import React from "react";
import "./Widget.scss";
import {
  MdPersonOutline,
  MdOutlineMonetizationOn,
} from "react-icons/md";
import { GiShoppingCart } from "react-icons/gi";
import {Link} from "react-router-dom";

const Widget = (props) => {
  let data;

  const user_count = props.dashboard.total_users;

    const order_count = props.dashboard.total_orders;

    const total_earnings = parseInt(props.dashboard.total_earnings).toFixed(2);

  switch (props.type) {
    case "user":
      data = {
        title: "USERS",
        isMoney: false,
        link: "View all users",
          link_dest: "customers",
          value : user_count,
        icon: (
          <MdPersonOutline
            size={20}
            color="crimson"
            style={{
              backgroundColor: "rgba(255, 0, 0, 0.2)",
              borderRadius: "3px",
              padding: "2px",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "ORDERS",
        isMoney: false,
        link: "View all orders",
          link_dest: "orders",
          value : order_count,
        icon: (
          <GiShoppingCart
            size={20}
            color="goldenrod"
            style={{
              backgroundColor: "rgba(218,165, 32, 0.2)",
              borderRadius: "3px",
              padding: "2px",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "EARNINGS",
        isMoney: true,
          link_dest: "",
        link: "View net earnings",
          value : total_earnings,
        icon: (
          <MdOutlineMonetizationOn
            size={20}
            color="green"
            style={{
              backgroundColor: "rgba(0, 128, 0, 0.2)",
              borderRadius: "3px",
              padding: "2px",
            }}
          />
        ),
      };
      break;

    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "$"} {data.value}
        </span>
          <Link to={data.link_dest}><span className="link">{data.link}</span></Link>
      </div>

    </div>
  );
};

export default Widget;
