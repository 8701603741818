import React, { useEffect, useRef, useState } from "react";
import "./Navbar.scss";
import { Link, useParams } from "react-router-dom";
import { api } from "../../../apis";
import { getOwnerDetails, getOwnerToken } from "../../../utils/businessDetails";
import { CgProfile } from "react-icons/cg";
import { RxDragHandleHorizontal } from "react-icons/rx";
import Modal from "../../Customer/UI/Modal";
import SidebarContent from "../Sidebar/SidebarContent";

const Navbar = () => {
  const params = useParams();
  const [toggleVisible, setToggleVisible] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [logo, setLogo] = useState("");
  const [owner, setOwner] = useState("");
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const profileCardRef = useRef();

  const handleClickOutside = (event) => {
    // // console.log(event.target);
    // // console.log(profileCardRef.current.contains(event.target));
    if (!profileCardRef?.current?.contains(event.target)) {
      setToggleVisible(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const toggleProfile = () => {
    setToggleVisible((prevState) => !prevState);
    // // console.log(profileCardRef.current);
    document.addEventListener("mousedown", handleClickOutside);
  };

  // useEffect(() => {
    // api
    //   .get(`/${params.bid}_logo`)
    //   .then((response) => {
    //     // console.log("inside 1", response);
    //     setLogo(`${params.bid}_logo`);
    //   })
    //   .catch((error) => {
    //     if (error.code === "ERR_NETWORK") {
    //       alert(`${error.message} \nKindly check your connection`);
    //       return;
    //     }
    //     // console.log("inside 2");
    //     // alert(`Please upload business logo image - ${error}`);
    //   });
    // const image = params.bid + '_logo';
    // const imageURL = `https://${BUSINESSBUCKET}.s3.${S3REGION}.amazonaws.com/${image}`;
    // // console.log(imageURL);
    // setLogo(imageURL);
  // }, [params.bid]);

  useEffect(() => {
    api
      .get(`businesses/${params.bid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getOwnerToken(),
        },
      })
      .then((response) => {
        setBusinessName(response.data.business_name);
        // console.log(response.data.logo[0]);
        setLogo(response.data.logo[0]);
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          alert(`${error.message} \nKindly check your connection`);
          return;
        }
        // alert(error.response.data.error_message);
        // alert(error);
      });
  }, [params.bid]);

  useEffect(() => {
    setOwner(getOwnerDetails());
  }, []);

  // const closeSidebarHandler = () => {
  //   // console.log("hihi");
  //   setSidebarToggle(false);
  // }

  return (
    <div className="navbar-admin">
      <div className="top">
        <div
          className="navigation-menu"
          onClick={() => setSidebarToggle((prevState) => !prevState)}
        >
          <RxDragHandleHorizontal size={20} />
          {sidebarToggle && (
            <Modal />)}
              {/* {sidebarToggle &&  */}
              <div className={`flex h-screen gap-2 absolute top-0 duration-700 ease-in-out ${sidebarToggle?"-left-0":"-left-80"} z-[100]`}>
                <SidebarContent />
                <div className=" font-bold text-xl">
                  X
                </div>
              </div>
              {/* } */}
            {/* </Modal> */}
          {/* // )} */}
        </div>
        {logo && (
          <img
            src={logo}
            alt="company logo"
            className="logo"
          />
        )}
        {!logo && (
          <span className=" logoText inline-block bg-gradient-to-r from-cyan-400 to-blue-500 bg-clip-text text-transparent">
            {businessName}
          </span>
        )}
      </div>
      <div className="navbar-left">
        {/* <input placeholder="Search..." />
        <BiSearchAlt2 color="gray" /> */}
        {/* <span className="font-bold text-3xl text-gray-600">Dashboard</span> */}
      </div>
      <div className="relative" ref={profileCardRef}>
        <button
          onClick={toggleProfile}
          // onMouseDown={closeProfileHandler}
          className="flex flex-col items-center h-12"
        >
          <CgProfile color="gray" size={25} />
          <span className="text-gray-500">Profile</span>
        </button>
        {toggleVisible && (
          <div className="absolute bg-gray-700 rounded-md top-7 px-3 py-3 right-0">
            <button
              className="flex flex-col border-b border-gray-500 pb-2"
              onClick={(event) => window.location.reload()}
            >
              <span className="text-white">
                {owner.user.first_name + " " + owner.user.last_name}
              </span>
              <span className="text-gray-400">{owner.user.email}</span>
            </button>
            <div className="flex flex-col text-white items-start pt-4">
              <Link
                to={`/admin/${params.bid}/profile`}
                className="hover:bg-gray-600 w-full py-1"
              >
                Profile
              </Link>
              <Link to={`/select-shop`}
              className="hover:bg-gray-600 w-full py-1">
                Switch Business
              </Link>
              <Link className="mb-2 w-full py-1 hover:bg-gray-600" to="/logout">
                Sign out
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
