import React, { useState } from "react";
import Modal from "../../../Customer/UI/Modal";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { api } from "../../../../apis";
import { useParams } from "react-router-dom";
import toast, {Toaster} from "react-hot-toast";

const ImageExpander = (props) => {
  const params = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [jobImages, setJobImages] = useState([]);

  const nextSlideHandler = () => {
    let newSlide = currentIndex;
    if (currentIndex < props.images.length - 1) {
      newSlide = currentIndex + 1;
      setCurrentIndex(newSlide);
    }
  };

  const prevSlideHandler = () => {
    let newSlide = currentIndex;
    if (currentIndex > 0) {
      newSlide = currentIndex - 1;
      setCurrentIndex(newSlide);
    }
  };

  const changeJobImageHandler = (imageId) => {
    // console.log(typeof imageId);
    const job_images = [...jobImages];
    const filter_jobImages = job_images.filter((item) => item === imageId);
    if (filter_jobImages.length === 0) {
      job_images.push(imageId);
    } else {
      const index = job_images.indexOf(imageId);
      job_images.splice(index, 1);
    }
    // console.log(job_images);
    setJobImages(job_images);
  };

  const jobImagesSubmitHandler = () => {
    const form_data = {
      image_ids: [...jobImages],
    };

    const authToken = JSON.parse(localStorage.getItem("owner")).token;
    // // console.log([...form_data.image_ids]);
    // console.log(JSON.stringify(form_data));
    props.type === "ledger" &&
      api
        .post(
          `/businesses/${params.bid}/admin/pinned_enquiry_images`,
          JSON.stringify(form_data),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authToken,
            },
          }
        )
        .then((response) => {
          toast.success("Images added successfully");
          window.location.reload();
        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {
            toast.error(`${error.message} \nKindly check your connection`);
            return;
          }
          toast.error(`Error ${error.response.data.error_message}`);
        });
    props.type === "details" &&
      api
        .delete(`/businesses/${params.bid}/admin/pinned_enquiry_images`, {
          data: JSON.stringify(form_data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          toast.success("Images removed successfully");
          window.location.reload();
        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {
            toast.error(`${error.message} \nKindly check your connection`);
            return;
          }
          toast.error(`Error ${error.response.data.error_message}`);
        });
  };

  const canceljobImagesHandler = () => {
    setJobImages([]);
    props.onClose();
  };

  return (
      <>
      <Toaster/>
        <Modal onBackdropClick={props.onClose}>
          <div className="bg-white w-full overflow-hidden mx-auto border h-screen">
            <div className="flex justify-end w-full px-2 py-1 gap-4 border-b">
              {props.user === "owner" && (
                  <button
                      className=" bg-indigo-600 text-white rounded-md px-2 py-1 hover:bg-indigo-500 hover:shadow-md"
                      onClick={jobImagesSubmitHandler}
                  >
                    Submit
                  </button>
              )}
              <button
                  className=" bg-red-600 text-white rounded-md px-2 py-1 hover:bg-red-500 hover:shadow-xl"
                  onClick={canceljobImagesHandler}
              >
                Cancel
              </button>
            </div>
            <div
                className="flex transform duration-500"
                style={{
                  transform: `translateX(-${
                      (100 * currentIndex) / props.images.length
                  }%)`,
                  width: `${100 * props.images.length}%`,
                }}
            >
              {props.images.map((image, index) => (
                  <div
                      key={index}
                      className="flex items-center flex-col relative"
                      style={{ width: `${100 / props.images.length}%`, height: `${100/2}vh` }}
                  >
                    <div
                        className="flex justify-between absolute w-full"
                    >
                      <button onClick={prevSlideHandler} className="bg-white">
                        {currentIndex !== 0 && <AiOutlineLeft size={25} />}
                      </button>
                      <button onClick={nextSlideHandler} className=" bg-white">
                        {currentIndex !== props.images.length - 1 && (
                            <AiOutlineRight size={25} />
                        )}
                      </button>
                    </div>
                    {props.user === "owner" && (
                        <div className="z-10 flex items-center gap-1">
                          <input
                              type="checkbox"
                              id={image}
                              name={image}
                              onChange={() => changeJobImageHandler(image)}
                          />
                          <label htmlFor={index} className="">
                            {props.type === "ledger"
                                ? "Add to job details"
                                : "Remove from job details"}
                          </label>
                        </div>
                    )}
                    <div className="">
                      <img
                          // src={process.env.REACT_APP_SERVER_URL + image}
                          src={image}
                          className="max-w-full border-2 border-white"
                          alt="job details"
                      />
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </Modal>
      </>

  );
};

export default ImageExpander;
