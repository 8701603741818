import React, { useState } from "react";
import Modal from "../../../Customer/UI/Modal";
import { api } from "../../../../apis";
import {getCustomerToken, getOwnerToken} from "../../../../utils/businessDetails";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const RecordPaymentForm = (props) => {
    const params = useParams();
  const [formData, setFormData] = useState({
    amount: "",
    payment_mode: "",
  });

  const inputChangeHandler = (event) => {
    const {name, value} = event.target;

    setFormData((prevState) => ({
        ...prevState,
        // [name]: name==="amount" ? parseFloat(value) : value,
        [name]: value,
    }));
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();

    const form_data = {
        order_id: parseInt(props.order_id),
        amount: parseFloat(formData.amount),
        payment_mode: formData.payment_mode,
    };




    api.post(`/businesses/${params.bid}/payment`, JSON.stringify(form_data), {
        headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${getOwnerToken()}`,
        }
    }).then((response) => {
        // // console.log(response);
        window.location.reload();
        toast.success(response.data.success_message);
    }).catch((error) => {
        // // console.log(error);
        if(error.code==="ERR_NETWORK") {
          alert(`${error.message} \nKindly check your connection`);
          return;
        }
        toast.error(error.response.data.error_message);
    });
  };

  return (
    <Modal onBackdropClick={props.onHideForm}>
      <form className="bg-white max-w-lg mx-auto rounded-md border overflow-hidden p-4 relative " onSubmit={formSubmitHandler}>
        <h1 className="text-center text-xl">
          <strong>Record payment</strong>
        </h1>
        <button
          className="absolute right-4 top-2 text-lg"
          onClick={props.onHideForm}
        >
          X
        </button>
        <p className="text-center mt-4">
          <span className="text-gray-500">ORDER #</span> {props.order_id}
        </p>
        <div className="mt-4 flex flex-col">
          <label htmlFor="amount">Amount</label>
          <input
            type="number"
            name="amount"
            id="amount"
            value={formData.amount}
            // step={0.01}
            // inputMode="decimal"
            placeholder="Enter amount"
            onChange={inputChangeHandler}
            // pattern="[0-9]*[.,]?[0-9]*"
            className="border rounded-md text-gray-700 px-2 py-1 outline-none focus:border-black"
          />
        </div>
        <div className="mt-4">
          <select
            name="payment_mode"
            id="mode"
            value={formData.payment_mode}
            onChange={inputChangeHandler}
            className="outline-none border rounded-md py-2 px-2 focus:border-black"
          >
            <option name="payment_mode" value="none">
              Select payment mode
            </option>
            <option name="payment_mode" value="CASH">Cash</option>
            <option name="payment_mode" value="CARD">Card</option>
          </select>
        </div>
        <button className="bg-indigo-600 text-white rounded-md px-2 mt-8 w-full py-2 hover:bg-indigo-500">
          Submit
        </button>
      </form>
      <ToastContainer />
    </Modal>
  );
};

export default RecordPaymentForm;
