import React, { useEffect, useState } from "react";
import Widget from "../../Widget/Widget";
import "./AdminHome.scss";
import Table from "../../Table/Table";
import { api } from "../../../../apis";
import { useParams } from "react-router-dom";
import { getOwnerToken } from "../../../../utils/businessDetails";
import  toast, { Toaster } from 'react-hot-toast';

const AdminHome = () => {
  const [dashboard, setDashboard] = useState({});
  // const authToken = JSON.parse(localStorage.getItem("owner")).token;
  const authToken = getOwnerToken();
  const params = useParams();

  useEffect(() => {
    api
      .get(`/businesses/${params.bid}/admin/dashboard`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        const dashboard = response.data;
        setDashboard(dashboard);
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          toast.error(`${error.message} \nKindly check your connection`);
          return;
        }
        toast.error(
          `Error fetching data from server. ${error.response.data.error_message}`
        );
      });
  }, [params, authToken]);

  return (
      <>
        <Toaster/>
        <div className="home ml-auto">
          <div className="widgets">
            <Widget type="user" dashboard={dashboard}/>
            <Widget type="order" dashboard={dashboard}/>
            <Widget type="earning" dashboard={dashboard}/>
          </div>
          <div className="list-container">
            <div className="title">Latest Orders</div>
            <Table data={dashboard.latest_orders}/>
          </div>
        </div>
      </>

  );
};

export default AdminHome;
