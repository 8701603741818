import React, { useState } from "react";
import { CgProfile } from "react-icons/cg";
import { AiOutlineDown } from "react-icons/ai";
import { FcShop } from "react-icons/fc";
import CreateShopForm from "./CreateShopForm";
import { Link } from "react-router-dom";

const CreateShop = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  return (
    <React.Fragment>
      {isFormVisible ? (
        <CreateShopForm />
      ) : (
        <div className="bg-gradient-to-r from-cyan-500 to-blue-500 py-12 px-8 min-h-screen">
          <div className="bg-white max-w-lg p-10 w-full mx-auto rounded-md shadow-md">
            <div className="flex justify-between items-center">
              <Link to="/home" className="flex flex-row mb-4 items-center">
                <img
                  src="https://st2.depositphotos.com/3867453/12012/v/600/depositphotos_120126908-stock-illustration-letter-g-number-6-logo.jpg"
                  alt="company logo"
                  className="w-8 h-8"
                />
                <p className="ml-2 italic font-extrabold text-2xl">GShop</p>
              </Link>
              <button className="flex items-center p-2 hover:bg-gray-200 rounded-md">
                <CgProfile size={30} />
                <AiOutlineDown size={20} className="ml-2" />
              </button>
            </div>
            <div className="flex flex-col items-center mt-8">
              <div>
                <FcShop size={200} />
              </div>
              <div className="text-2xl font-normal">
                Create your online store
              </div>
              <button
                onClick={() => setIsFormVisible(true)}
                className="w-full h-[40px] text-center py-2 px-4 mt-6 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Create Store
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CreateShop;
