import { redirect } from "react-router-dom";
import { api } from "../../../apis";

export async function customerLogout (bid) {
    const response = await api.get(`/businesses/${bid}/signout`);

    if(response.status===200) {
        localStorage.removeItem(`${bid}-customer`);
        localStorage.removeItem(`${bid}-customerTokenExpiration`);
        return redirect(`/business/${bid}`);
    }
    else {
        alert(response);
    }
}