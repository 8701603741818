import React, { useEffect, useState } from "react";
import AddAddressForm from "./AddAddressForm";
import {
  getCustomerDetails,
  getCustomerToken,
  getDeliveryMode,
} from "../../../../utils/businessDetails";
import { useParams } from "react-router-dom";
import { api } from "../../../../apis";
// import UpdateAddress from "./UpdateAddressForm";
import UpdateAddressForm from "./UpdateAddressForm";
import  toast, { Toaster } from 'react-hot-toast';

const Address = ({ formData, setFormData }) => {
  const [savedAddresses, setSavedAddress] = useState([]);
  const [pickupAddresses, setPickupAddresses] = useState([]);
  // const [selectedAddress, setSelectedAddress] = useState({
  //   addressId: "",
  //   name: "",
  //   mobileNo: "",
  //   city: "",
  //   state: "",
  //   address: "",
  //   pinCode: "",
  //   isDefault: false,
  // });

  const selected_pickup_address = pickupAddresses[0];
    
  const defaultAddress = savedAddresses.filter(
    (item) => item.is_default === true
  );
  const otherAddresses = savedAddresses.filter(
    (item) => item.is_default === false
  );
  const [checked, setChecked] = useState("");
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const [showUpdateAddressForm, setShowUpdateAddressForm] = useState(false);
  const params = useParams();
  const businessDeliveryMode = getDeliveryMode(params.bid);
  const [deliveryMode, setDeliveryMode] = useState(
    businessDeliveryMode !== "in/out-store" ? businessDeliveryMode : (localStorage.getItem("delivery_mode") ? localStorage.getItem("delivery_mode") : "in-store")
  );

  useEffect(() => {
    if (checked === "" && deliveryMode === "out-store") {
        if(defaultAddress[0]) {
      setChecked(defaultAddress[0].address_id);

      // setSelectedAddress({
      //   name: defaultAddress[0].name,
      //   mobileNo: defaultAddress[0].phone_no,
      //   city: defaultAddress[0].city,
      //   state: defaultAddress[0].state,
      //   address: defaultAddress[0].address,
      //   pinCode: defaultAddress[0].pincode,
      //   isDefault: defaultAddress[0].is_default,
      // });

      setFormData((formData) => ({
        ...formData,
        name: defaultAddress[0].name,
        mobileNo: defaultAddress[0].phone_no,
        city: defaultAddress[0].city,
        state: defaultAddress[0].state,
        address: defaultAddress[0].address,
        pinCode: defaultAddress[0].pincode,
        delivery_mode: deliveryMode,
        address_id: defaultAddress[0].address_id
      }));
    }
    }
  }, [checked, defaultAddress, setFormData, deliveryMode]);

  useEffect(() => {
    if (deliveryMode === "in-store") {
      // console.log(selected_pickup_address);
      if(selected_pickup_address) {
        setChecked("");
        setFormData((formData) => ({
          ...formData,
          store_name: selected_pickup_address.store_name,
          address: selected_pickup_address.address,
          city: selected_pickup_address.city,
          state: selected_pickup_address.state,
          pinCode : selected_pickup_address.pincode,
          delivery_mode: deliveryMode,
          address_id: selected_pickup_address.id,
        }));
        // return;
      }
      // setFormData((formData) => ({
      //   ...formData,
      //   name: "",
      //   mobileNo: "",
      //   city: "",
      //   state: "",
      //   address: "",
      //   pinCode: "",
      //   delivery_mode: deliveryMode,
      //   address_id: "-1",
      // }));
    }
  }, [deliveryMode, setFormData, selected_pickup_address]);

  const addressChangeHandler = (event) => {
    event.stopPropagation();
    // setChecked(true);
    setChecked(event.target.value);
    const selectedAddress = savedAddresses.filter(
      (item) => item.address_id === parseInt(event.target.value)
    );

    // setSelectedAddress({
    //   name: selectedAddress[0].name,
    //     mobileNo: selectedAddress[0].phone_no,
    //     city: selectedAddress[0].city,
    //     state: selectedAddress[0].state,
    //     address: selectedAddress[0].address,
    //     pinCode: selectedAddress[0].pincode,
    //     isDefault: selectedAddress[0].is_default,
    // });

    setFormData((formData) => ({
      ...formData,
      name: selectedAddress[0].name,
      mobileNo: selectedAddress[0].phone_no,
      city: selectedAddress[0].city,
      state: selectedAddress[0].state,
      address: selectedAddress[0].address,
      pinCode: selectedAddress[0].pincode,
      delivery_mode: deliveryMode,
      address_id: event.target.value,
    }));
  };

  const selectPickupAddressHandler = (event) => {
    // console.log(event.target.value);
    const selectedPickupAddress = pickupAddresses.filter((item) => item.id === parseInt(event.target.value));
    // console.log("selectedPickUpAddress",selectedPickupAddress);
    setFormData((formData) => ({
      ...formData,
      store_name: selectedPickupAddress[0].store_name,
      address: selectedPickupAddress[0].address,
      city: selectedPickupAddress[0].city,
      state: selectedPickupAddress[0].state,
      pinCode :selectedPickupAddress[0].pincode,
      delivery_mode: deliveryMode,
      address_id: event.target.value,
    }));
  };

  const showAddAddressHandler = () => {
    setShowAddAddressForm(true);
    // setSelectedAddress((prevState) => ({
    //   ...prevState,
    //   buttonType: "ADD",
    //   isDefault: false,
    // }));
  };

  const showUpdateAddressHandler = () => {
    setShowUpdateAddressForm(true);
    // setSelectedAddress((prevState) => ({
    //   ...prevState,
    //   buttonType: "EDIT",
    // }));
  }

  const hideAddressFormHandler = () => {
    setShowAddAddressForm(false);
  };

  const hideUpdateAddressFormHandler = () => {
    setShowUpdateAddressForm(false);
  }

  const selectDeliveryModeHandler = (event) => {
    setDeliveryMode(event.target.value);
    localStorage.setItem("delivery_mode", event.target.value);
  };

  const deleteAddressHandler = (address_id, user_id) => {
    api.delete(`/businesses/${params.bid}/address`, {params: {address_id: address_id, user_id: user_id}, 
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${getCustomerToken(params.bid)}`
        },
    }).then((response) => {
        window.location.reload();
    }).catch((error) => {
      if (error.code === "ERR_NETWORK") {
        toast.error(error.message);
        return;
      }
      toast.error(`Cannot delete address \n${error.response.data.error_message}`);
    });
  };

  useEffect(() => {
    api
      .get(
        `businesses/${params.bid}/addresses`,
        { params: { user_id: getCustomerDetails(params.bid).user.id },
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${getCustomerToken(params.bid)}`
          },
        }
      )
      .then((response) => {
        // savedAddresses = response.data;
        setSavedAddress(response.data);
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          toast.error(error.message);
          return;
        }
        // console.log(error);
      });
  }, [params.bid]);

  useEffect(() => {
    api.get(`/businesses/${params.bid}/admin/addresses`, {headers: {
      "Content-Type":"application/json",
      Authorization: `Bearer ${getCustomerToken(params.bid)}`,
    }}).then((response) => {
      setPickupAddresses(response.data);
    }).catch((error) => {
      if (error.code === "ERR_NETWORK") {
        toast.error(error.message);
        return;
      }
      // console.log('Error', error.response.data.error_message);
    });
  }, [params.bid]);

  return (
    <div>
      <Toaster/>
      <div className="mb-8">
        <h1 className="mb-2">
          {businessDeliveryMode === "in/out-store" ? (
            <strong>Select Delivery Mode</strong>
          ) : (
            <strong>Delivery Mode</strong>
          )}
        </h1>
        {businessDeliveryMode === "in-store" && (
          <div>
            <input
              type="radio"
              name="delivery-mode"
              value="in-store"
              id="in-store"
              defaultChecked
              onChange={selectDeliveryModeHandler}
            />
            <label htmlFor="in-store" className="ml-2 mr-12">
              In-Store pickup
            </label>
          </div>
        )}
        {businessDeliveryMode === "out-store" && (
          <div>
            <input
              type="radio"
              name="delivery-mode"
              value="out-store"
              id="out-store"
              defaultChecked
              onChange={selectDeliveryModeHandler}
            />
            <label htmlFor="out-store" className="ml-2">
              Onsite delivery
            </label>
          </div>
        )}
        {businessDeliveryMode === "in/out-store" && (
          <div className=" flex max-[400px]:flex max-[400px]:flex-col">
            <div>
            <input
              type="radio"
              name="delivery-mode"
              value="in-store"
              id="in-store"
              checked={deliveryMode==="in-store"}
              onChange={(event) => selectDeliveryModeHandler(event)}
            />
            <label htmlFor="in-store" className="ml-2 mr-12">
              In-Store pickup
            </label>
            </div>
            <div>
            <input
              type="radio"
              name="delivery-mode"
              value="out-store"
              id="out-store"
              checked={deliveryMode==="out-store"}
              onChange={(event) => selectDeliveryModeHandler(event)}
            />
            <label htmlFor="out-store" className="ml-2">
              Onsite delivery
            </label>
            </div>
          </div>
        )}
      </div>
      {deliveryMode === "out-store" && (
        <div>
          <div className="flex justify-between items-center mb-4 max-[300px]:flex-col max-[300px]:items-start">
            <p className="text-lg">
              <strong>Select Delivery Address</strong>
            </p>
            <button
              className="border rounded-md px-4 py-2 border-gray-700 text-gray-600 text-xs font-bold"
              onClick={showAddAddressHandler}
            >
              ADD NEW ADDRESS
            </button>
          </div>
          <div>
            <p className="mb-4 text-gray-500 font-bold text-xs">
              DEFAULT ADDRESS
            </p>
            {defaultAddress.map((address, index) => (
              <div
                key={index}
                className={`flex items-baseline gap-4 border p-4 hover:cursor-pointer rounded-md ${
                  parseInt(checked) === address.address_id &&
                  "shadow-[0_0px_5px_1px_rgba(0,0,0,0.1)]"
                }`}
              >
                <input
                  type="radio"
                  id={address.address_id}
                  name="address"
                  className="hover:cursor-pointer"
                  value={address.address_id}
                  defaultChecked
                  onChange={(event) => addressChangeHandler(event)}
                />
                <label
                  htmlFor={address.address_id}
                  className="hover:cursor-pointer text-sm"
                >
                  <p className="font-bold mb-4">{address.name}</p>
                  <p className="mb-4 font-light text-gray-600">
                    {`${address.address}, ${address.city}, ${address.state}, ${address.pincode}`}
                  </p>
                  <p className="mb-4">
                    <span className="font-light text-gray-600">Mobile: </span>
                    <span className="font-bold">+1{address.phone_no}</span>
                  </p>
                  {parseInt(checked) === address.address_id && (
                    <div className=" flex gap-2 max-[300px]:flex-col max-[300px]:w-full max-[300px]:item-start">
                      <button
                        className="border px-4 py-1 rounded-md border-black text-sm font-bold text-gray-800"
                        onClick={() => deleteAddressHandler(address.address_id, address.user_id)}
                      >
                        REMOVE
                      </button>
                      <button className="border px-4 py-1 rounded-md border-black text-sm font-bold text-gray-800"
                      onClick={showUpdateAddressHandler}>
                        EDIT
                      </button>
                    </div>
                  )}
                </label>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <p className="mb-4 text-gray-500 font-bold text-xs">
              OTHER ADDRESS
            </p>
            {otherAddresses.map((address, index) => (
              <div
                key={index}
                className={`flex items-baseline gap-4 border p-4 hover:cursor-pointer rounded-md mb-2 ${
                  parseInt(checked) === address.address_id &&
                  "shadow-[0_0px_5px_1px_rgba(0,0,0,0.1)]"
                }`}
              >
                <input
                  type="radio"
                  id={address.address_id}
                  name="address"
                  value={address.address_id}
                  className="hover:cursor-pointer"
                  onChange={(event) => addressChangeHandler(event)}
                />
                <label
                  htmlFor={address.address_id}
                  className="hover:cursor-pointer text-sm"
                >
                  <p className="font-bold mb-4">{address.name}</p>
                  <p className="mb-4 font-light text-gray-600">
                    {`${address.address}, ${address.city}, ${address.state}, ${address.pincode}`}
                  </p>
                  <p className="mb-4">
                    <span className="font-light text-gray-600">Mobile: </span>
                    <span className="font-bold">+1{address.phone_no}</span>
                  </p>
                  {parseInt(checked) === address.address_id && (
                    <>
                      <button
                        className="border px-4 py-1 rounded-md border-black text-sm font-bold text-gray-800"
                        onClick={() => deleteAddressHandler(address.address_id, address.user_id)}
                      >
                        REMOVE
                      </button>
                      <button className="border px-4 py-1 rounded-md border-black text-sm font-bold text-gray-800 ml-4"
                      onClick={showUpdateAddressHandler}>
                        EDIT
                      </button>
                    </>
                  )}
                </label>
              </div>
            ))}
          </div>
          {showAddAddressForm && (
            <AddAddressForm onClose={hideAddressFormHandler} />
          )}
          {showUpdateAddressForm && (
            <UpdateAddressForm onClose={hideUpdateAddressFormHandler} savedAddresses={savedAddresses} selectedAddressId={checked} />
          )}
        </div>
      )}
      {deliveryMode === "in-store" && (
        <div>
          <h1>
            <strong>PICK UP ADDRESS</strong>
          </h1>
          {/* Shop address to be updated after owner has set the address of the shop after api creation */}
          <div className="flex flex-col gap-2">
            {pickupAddresses.map((item, index) => (
            <div key={item.id} className="text-gray-600 border rounded-md px-4 py-2 flex flex-row items-baseline gap-4">
              <input type="radio" id={item.id} value={item.id} name="pickup-address" onChange={selectPickupAddressHandler} />
              <label htmlFor={item.id}>
                <p className="font-bold text-black">{item.store_name}</p>
                <p className=" font-light">{item.address}, {item.city}, {item.state} {item.pincode}</p>
                <p><span className="font-light">Mobile:</span><span className="ml-2 font-bold text-black">+1{item.phone_no}</span></p>
              </label>
            </div>))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Address;
