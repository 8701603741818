import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../apis";
import EmailVerificationPage from "./EmailVerificationPage";

const Signup = () => {
  const locationState = useLocation().state;
  const [email, setEmail] = useState(locationState ? locationState : "");
  const [passwordObj, setPasswordObj] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState({
    password: "",
    confirmPassword: "",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");

  // const navigate = useNavigate();

  const emailChangeHandler = (event) => {
    setEmail((email) => {
      return event.target.value;
    });
  };

  const passwordChangeHandler = (event) => {
    const { name, value } = event.target;
    setPasswordObj((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    validatePassword(event);
  };

  const validatePassword = (event) => {
    let { name, value } = event.target;
    setPasswordError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (
            passwordObj.confirmPassword &&
            value !== passwordObj.confirmPassword
          ) {
            stateObj["confirmPassword"] = "Passwords do not match.";
          } else if (
            passwordObj.confirmPassword &&
            value === passwordObj.confirmPassword
          ) {
            stateObj["confirmPassword"] = "";
          }
          if (
            !(
              /^(?=.*[A-Z])/.test(value) &&
              /^(?=.*[a-z])/.test(value) &&
              /^(?=.*\d)/.test(value) &&
              /^(?=.*[!@#$%^&*])/.test(value) &&
              value.length > 7
            )
          ) {
            stateObj[name] =
              "Password must be atleast 8 characters long and contain atleast one lowercase and uppercase letter, one digit and one special character.";
          }
          break;

        case "confirmPassword":
          if (passwordObj.password && value !== passwordObj.password) {
            stateObj[name] = "Passwords do not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const firstNameChangeHandler = (event) => {
    setFirstName((firstName) => {
      return event.target.value;
    });
  };

  const lastNameChangeHandler = (event) => {
    setLastName((lastName) => {
      return event.target.value;
    });
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    if (!(passwordError.password || passwordError.confirmPassword)) {
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: passwordObj.password,
        phone_no: phoneNo,
      };
      api
        .post("/signup", JSON.stringify(formData), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            toast.success("User ID created successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
            // const initialValues = {
            //   password: "",
            //   confirmPassword: "",
            // };
            // setEmail("");
            // setFirstName("");
            // setLastName("");
            // setPasswordObj({ ...initialValues });
            // setPasswordError({ ...initialValues });
            setIsLogin(true);
            // setTimeout(() => {
            // navigate("/login");
            // setIsLogin(true);
            //window.location.reload(true);
            // }, 2000);
          }
        })
        .catch((error) => {
          if(error.code==="ERR_NETWORK") {
            alert(`${error.message} \nKindly check your connection`);
            return;
          }
          toast.error(error.response.data.error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return !isLogin ? (
    <div className="min-h-screen bg-gradient-to-r from-cyan-500 to-blue-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-lg bg-white shadow sm:rounded-lg sm:px-10">
        <Link to="/home" className="mt-8 px-4 flex flex-row items-center">
          <img
            src="https://st2.depositphotos.com/3867453/12012/v/600/depositphotos_120126908-stock-illustration-letter-g-number-6-logo.jpg"
            alt="company logo"
            className="w-8 h-8"
          />
          <p className="ml-2 italic font-extrabold text-2xl">GShop</p>
        </Link>
        <h2 className="mt-6 px-4 text-3xl text-gray-900">
          Create your GShop ID
        </h2>
        <p className="px-4 text-gray-500">Start your business today</p>
        <div className="mt-4 py-8 px-4 ">
          <form className="space-y-6" onSubmit={formSubmitHandler}>
            <div className="flex justify-between">
              <div className="pr-4">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-500"
                >
                  First Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="firstName"
                    autoComplete="on"
                    required
                    value={firstName}
                    onChange={firstNameChangeHandler}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="pl-4">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-500"
                >
                  Last Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="lastName"
                    autoComplete="on"
                    required
                    value={lastName}
                    onChange={lastNameChangeHandler}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-500"
              >
                Email Address
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="on"
                  required
                  value={email}
                  onChange={emailChangeHandler}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="phoneNo"
                className="block text-sm font-medium text-gray-500"
              >
                Phone Number
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  name="phoneNo"
                  autoComplete="on"
                  required
                  value={phoneNo}
                  onChange={(event) => setPhoneNo(event.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-500"
              >
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={isVisible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={passwordObj.password}
                  onChange={passwordChangeHandler}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onBlur={validatePassword}
                />
                {isVisible ? (
                  <AiOutlineEye
                    className="absolute top-2 right-2"
                    size={25}
                    onClick={() => setIsVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute top-2 right-2"
                    size={25}
                    onClick={() => setIsVisible(true)}
                  />
                )}
              </div>
              {passwordError.password && (
                <span className="text-red-500 text-sm">
                  {passwordError.password}
                </span>
              )}
            </div>
            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-500"
              >
                Confirm Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={isVisible ? "text" : "password"}
                  name="confirmPassword"
                  autoComplete="off"
                  required
                  value={passwordObj.confirmPassword}
                  onChange={passwordChangeHandler}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onBlur={validatePassword}
                />
                {isVisible ? (
                  <AiOutlineEye
                    className="absolute top-2 right-2"
                    size={25}
                    onClick={() => setIsVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute top-2 right-2"
                    size={25}
                    onClick={() => setIsVisible(true)}
                  />
                )}
              </div>
              {passwordError.confirmPassword && (
                <span className="text-red-500 text-sm">
                  {passwordError.confirmPassword}
                </span>
              )}
            </div>
            <div>
              {!(passwordError.password || passwordError.confirmPassword) ? (
                <button
                  type="submit"
                  className="w-full h-[40px] text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                >
                  Submit
                </button>
              ) : (
                <button
                  disabled
                  className="w-full h-[40px] text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-600 bg-gray-400"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="space-y-6 pb-8 px-4">
          <div className="flex items-center">
            <div className="bg-gray-400 flex-grow h-px"></div>
            <span className="text-gray-500 px-4">or</span>
            <div className="bg-gray-400 flex-grow h-px"></div>
          </div>
          {/* <div>
            <button className="w-full h-[44px] text-center border text-sm font-medium rounded-md shadow-md hover:bg-gray-100 border-gray-300">
              <div className="flex items-center justify-center">
                <FcGoogle size={20} />
                <p className="ml-2">Continue with Google</p>
              </div>
            </button>
          </div> */}
          <div className="flex flex-row text-sm">
            <h4>Already have a GShop ID?</h4>
            <Link to="/login" className="text-blue-600 pl-2 underline">
              Log in
            </Link>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  ) : (
    <EmailVerificationPage email={email} />
  );
};

export default Signup;
