import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Enquiry from "./Enquiry";
import Loader from "../../../Loader/Loader";
import Filter from "../../../Filter/Filter";
import useFetch from "../../../hooks/useFetch";
import { useFilterState } from "../../../Filter/FilterContext";
import { getFilteredItems } from "../../../../utils/filterItems";
import "./EnquiryList.scss";
import toast,{Toaster} from "react-hot-toast";

const EnquiryList = () => {
  const params = useParams();
  const { query, resetQueryHandler } = useFilterState();
  const searchParam = useRef(null);
  const { loading, error, data } = useFetch(
    `/businesses/${params.bid}/admin/enquiries`,
    null,
    "owner",
    params
  );
  
  useEffect(() => {
    return (() => resetQueryHandler());
  }, [resetQueryHandler]);

  if (error) {
     toast.error(error);
  }

  let enquiries = data?.result;
  // console.log(data?.result);
  if (data) {
    const filteredItems = getFilteredItems({
      items: enquiries,
      filterBy: [searchParam.current],
      query: query,
    });
    enquiries = filteredItems;
  }

  const updateSearchParam = (searchBy) => {
    searchParam.current = searchBy;
  };


  return <>
    <Toaster/>
    {!loading ? (
    <div className="ml-auto bg-white enquiryContainer">
      <div className="relative overflow-x-auto shadow-md">
        <div className="flex flex-row justify-between items-center">
          <div className="pl-4 pt-4">
            <span className="font-bold text-xl">All enquiries</span>
          </div>
          {/* <div className="p-4">
            <label htmlFor="table-search" className="sr-only">
              Search
            </label>
            <div className="relative mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5"
                placeholder="Search htmlFor items"
              />
            </div>
          </div> */}
        </div>
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-200">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 whitespace-nowrap border-r border-gray-300"
              >
                Customer Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 whitespace-nowrap border-r border-gray-300"
              >
                Enquiry ID
              </th>
              <th
                scope="col"
                className="px-6 py-3 whitespace-nowrap border-r border-gray-300"
              >
                Product Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 whitespace-nowrap border-r border-gray-300"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 whitespace-nowrap border-r border-gray-300"
              >
                Price
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className="px-1 py-1 border-b border-r border-gray-200 bg-white text-sm w-0.5 overflow-hidden"
                onClick={() => updateSearchParam("first_name")}
              >
                <Filter searchType="text" placeholder="" />
              </td>
              <td
                className="px-1 py-1 border-b border-r border-gray-200 bg-white text-sm w-0.5 overflow-hidden"
                onClick={() => updateSearchParam("enquiry_id")}
              >
                <Filter searchType="text" placeholder="" />
              </td>
              <td
                className="px-1 py-1 border-b border-r border-gray-200 bg-white text-sm w-0.5 overflow-hidden"
                onClick={() => updateSearchParam("name")}
              >
                <Filter searchType="text" placeholder="" />
              </td>
              <td
                className="px-1 py-1 border-b border-r border-gray-200 bg-white text-sm w-0.5 overflow-hidden"
                onClick={() => updateSearchParam("updated_on")}
              >
                <Filter searchType="text" placeholder="" />
              </td>
              <td
                className="px-1 py-1 border-b border-r border-gray-200 bg-white text-sm w-0.5 overflow-hidden"
                onClick={() => updateSearchParam("price")}
              >
                <Filter searchType="text" placeholder="" />
              </td>
              <td className="px-1 py-1 border-b border-gray-200 bg-white text-sm w-0.5 overflow-hidden"></td>
            </tr>
            {data &&
              enquiries.map((item, index) => (
                <Enquiry enquiry={item} key={item.enquiry_id} />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <div className="w-screen h-screen flex items-center justify-center mx-auto my-auto">
      <Loader />
    </div>
  )}
      </>;
};

export default EnquiryList;
