import React from "react";

const Stepper = (props) => {
  return (
    <div className="flex items-center px-10">
      <div className="relative flex flex-col items-center">
        <div
          className={`border rounded-full w-12 h-12 flex items-center justify-center border-gray-400 mx-auto ${
            props.currentPage === 2 ? "bg-green-700 text-white" : ""
          }`}
        >
          1
        </div>
        <div
          className={`absolute top-0 mt-14 text-sm text-gray-500 w-32 text-center ${
            props.currentPage === 2 ? "text-gray-900" : ""
          }`}
        >
          Select Name
        </div>
      </div>
      <div
        className={`border border-gray-300 flex-grow ${
          props.currentPage === 2 ? "border-green-700" : ""
        }`}
      >
        {/* {line} */}
      </div>
      <div className="relative flex flex-col items-center">
        <div
          className={`border rounded-full w-12 h-12 flex items-center justify-center border-gray-400 mx-auto ${
            props.isValid.isBusinessModelValid && props.isValid.isDeliveryMethodValid && props.currentPage !== 1
              ? "bg-green-700 text-white"
              : ""
          }`}
        >
          2
        </div>
        <div
          className={`absolute top-0 mt-14 text-sm text-gray-500 w-32 text-center ${
            props.isValid.isBusinessModelValid && props.isValid.isDeliveryMethodValid && props.currentPage !== 1
              ? "text-gray-900"
              : ""
          }`}
        >
          Select Model
        </div>
      </div>
    </div>
  );
};

export default Stepper;
