import React from "react";
import "./Sidebar.scss";

import SidebarContent from "./SidebarContent";

const Sidebar = () => {
  return (
    <div className="sidebar">
      {/* <hr /> */}
      <SidebarContent></SidebarContent>
    </div>
  );
};

export default Sidebar;
