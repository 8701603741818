import React from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

const Navigation = (props) => {
  const buttonClass =
    "w-30 h-[40px] text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700";

  const onNextClickHandler = () => {
    props.setPage((prevState) => prevState + 1);
  };

  const onBackClickHandler = () => {
    if (props.page !== 1) {
      props.setPage((prevState) => prevState - 1);
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="mr-8">
        {props.page === 1 ? (
          ""
        ) : (
          <button className="font-medium" onClick={onBackClickHandler}>
            <div className="flex items-center">
              <span>
                <MdOutlineArrowBackIosNew size={15} />
              </span>
              <span className="ml-2">Back</span>
            </div>
          </button>
        )}
      </div>
      {props.page !== 2 ? (
        <button
          className={`${buttonClass} ${
            props.isValid.isBusinessNameValid
              ? ""
              : "bg-gray-400 hover:bg-gray-400"
          }`}
          disabled={!props.isValid.isBusinessNameValid}
          onClick={onNextClickHandler}
        >
          Next
        </button>
      ) : (
        <button
          className={`${buttonClass} ${
            props.isValid.isBusinessNameValid &&
            props.isValid.isBusinessModelValid &&
            props.isValid.isDeliveryMethodValid &&
            !props.isLoading
              ? ""
              : "bg-gray-400 hover:bg-gray-400"
          }`}
          disabled={
            !(
              props.isValid.isBusinessNameValid &&
              props.isValid.isBusinessModelValid &&
              props.isValid.isDeliveryMethodValid
            ) || props.isLoading
          }
          onClick={props.onSubmit}
        >
          {props.isLoading ? "Submitting..." : "Submit"}
        </button>
      )}
    </div>
  );
};

export default Navigation;
