import React, { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { RxDotFilled } from "react-icons/rx";
import { Link } from "react-router-dom";

const ImageSlider = ({ slides }) => {
  // console.log(slides);
  const [currentIndex, setCurrentIndex] = useState(0);
  const timerRef = useRef(null);

  const nextSlideHandler = () => {
    // console.log(currentIndex);
    const isLastSlide = currentIndex === slides.length - 1;
    const newSlide = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newSlide);
  };

  const prevSlideHandler = () => {
    if (currentIndex === 0) {
      setCurrentIndex(slides.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const gotoIndexHandler = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    timerRef.current = setTimeout(() => {
        if (currentIndex < slides.length - 1) {
            setCurrentIndex(currentIndex + 1);
          } else {
            setCurrentIndex(0);
          }
    }, 2000);
    return (() => clearTimeout(timerRef.current));
  });

  return (
    <div>
      <div className="h-screen w-full relative group max-w-screen overflow-hidden">
        <button
          className="hidden group-hover:block absolute top-1/2 text-gray-500 right-8 z-10"
          onClick={nextSlideHandler}
        >
          <FaChevronRight size={30} />
        </button>
        <button
          className="hidden group-hover:block absolute top-1/2 left-8 text-gray-500 z-10"
          onClick={prevSlideHandler}
        >
          <FaChevronLeft size={30} />
        </button>
        <div
          className="h-screen flex transform duration-1000"
          style={{ transform: `translateX(-${100 * currentIndex}vw)`, width: `${100 * slides.length}vw` }}
        >
          {/* <div style={{backgroundImage: `url(${slides[currentIndex].src})`}} className="h-full w-screen bg-cover bg-center duration-500"></div> */}
          {slides.map((slide, index) => (
            <Link
              key={index}
              to={`products/product/${slide.id}`}
              style={{ backgroundImage: `url(${slide.images[0]})` }}
              className="h-screen w-screen bg-cover bg-center"
            ></Link>
          ))}
        </div>
        <div
        className="absolute w-full flex items-center justify-center -mt-16"
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="text-gray-500"
              onClick={() => gotoIndexHandler(index)}
            >
              <RxDotFilled size={40} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
