import React from "react";
import "./Table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CurrencyFormat from "react-currency-format";

const TableList = (props) => {
  const rows = props.data? props.data : [];

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">S.No</TableCell>
            <TableCell className="tableCell">Order ID</TableCell>
            <TableCell className="tableCell">Customer Name</TableCell>
            <TableCell className="tableCell">Amount</TableCell>
            <TableCell className="tableCell">Created On</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell className="tableCell">{row.serial_no}</TableCell>
              <TableCell className="tableCell">

                  {row.order_id}

              </TableCell>
              <TableCell className="tableCell">{row.name}</TableCell>
              <TableCell className="tableCell">
                <CurrencyFormat value={row.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
              </TableCell>
              <TableCell className="tableCell">
                {row.created_date}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableList;
