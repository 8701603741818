export function getFilteredItems({ items, filterBy, query }) {
    let products = [];
    for(const field of filterBy) {
        const filteredProducts = items?.filter((item) => {
            return field===null ? item : item[field]?.toString().toLowerCase().indexOf(query.toLowerCase())!==-1});
        products = products.concat(filteredProducts);
    }
    products = [...new Set(products)];
    return products;
}
