import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../apis";

import  toast, { Toaster } from 'react-hot-toast';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  const emailChangeHandler = (event) => {
    setEmail((email) => {
      return event.target.value;
    });
  };

  const passwordChangeHandler = (event) => {
    setPassword((password) => {
      return event.target.value;
    });
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    const formData = {
      email: email,
      password: password,
    };
    api
      .post("/signin", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        if (response.status === 200) {
          // console.log(response);
          const owner_id = response.data.saved_user.user.id;
          if (localStorage.getItem("owner") !== null) {
            localStorage.removeItem("owner");
          }
          localStorage.setItem(
            "owner",
            JSON.stringify({ ...response.data.saved_user })
          );
          const expiration = new Date();
          expiration.setHours(expiration.getHours() + 24);
          // expiration.setSeconds(expiration.getSeconds() + 10);
          localStorage.setItem('ownerTokenExpiration', expiration.toISOString());
          await api
            .get(`/businesses`, {
              params: { owner_id },
              headers: {
                Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem("owner")).token,
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (Object.keys(response.data).length === 0) {
                navigate("/create-shop"); //no business exists
              } else {
                navigate("/select-shop"); //business already configured
              }
            })
            .catch((error) => {
              if(error.code==="ERR_NETWORK") {
                toast.error(`${error.message} \nKindly check your connection`);
                return;
              }
              toast.error(`Error ${error.response.data.error_message}`);
            });
        }
      })
      .catch((error) => {
        if(error.code==="ERR_NETWORK") {
          toast.error(`${error.message} \nKindly check your connection`);
          return;
        }
        toast.error(`Error ${error.response.data.error_message}`);
      });
  };

  return (
      <>
        <Toaster/>
        <div
            className="min-h-screen bg-gradient-to-r from-cyan-500 to-blue-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-3/4 sm:max-w-lg bg-white shadow sm:rounded-lg sm:px-10">
            <Link to="/home" className="mt-8 px-4 flex flex-row items-center">
              <img
                  src="https://st2.depositphotos.com/3867453/12012/v/600/depositphotos_120126908-stock-illustration-letter-g-number-6-logo.jpg"
                  alt="company logo"
                  className="w-8 h-8"
              />
              <p className="ml-2 italic font-extrabold text-2xl">GShop</p>
            </Link>
            <h2 className="mt-6 px-4 text-3xl text-gray-900">Log in</h2>
            <p className="px-4 text-gray-500">Continue to GShop</p>
            <div className="mt-4 py-8 px-4 ">
              <form className="space-y-6" onSubmit={formSubmitHandler}>
                <div>
                  <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-500"
                  >
                    Email Address
                  </label>
                  <div className="mt-1">
                    <input
                        type="email"
                        name="email"
                        autoComplete="on"
                        required
                        value={email}
                        onChange={emailChangeHandler}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-500"
                  >
                    Password
                  </label>
                  <div className="mt-1 relative">
                    <input
                        type={isVisible ? "text" : "password"}
                        name="password"
                        autoComplete="current-password"
                        required
                        value={password}
                        onChange={passwordChangeHandler}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                    {isVisible ? (
                        <AiOutlineEye
                            className="absolute top-2 right-2"
                            size={25}
                            onClick={() => setIsVisible(false)}
                        />
                    ) : (
                        <AiOutlineEyeInvisible
                            className="absolute top-2 right-2"
                            size={25}
                            onClick={() => setIsVisible(true)}
                        />
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end">
                  {/* <div className="flex flex-row items-center">
                <input
                  type="checkbox"
                  name="remember-me"
                  id="remember-me"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="text-sm block text-gray-500 ml-2">
                  Remember me
                </label>
              </div> */}
                  <div>
                    <Link
                        to="forgotPassword"
                        className="font-medium text-blue-600 hover:text-blue-500"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <div>
                  <button
                      type="submit"
                      className="w-full h-[40px] text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className="space-y-6 pb-8 px-4">
              <div className="flex items-center">
                <div className="bg-gray-400 flex-grow h-px"></div>
                <span className="text-gray-500 px-4">or</span>
                <div className="bg-gray-400 flex-grow h-px"></div>
              </div>
              {/* <div>
            <button className="w-full h-[44px] text-center border text-sm font-medium rounded-md shadow-md hover:bg-gray-100 border-gray-300">
              <div className="flex items-center justify-center">
                <FcGoogle size={20} />
                <p className="ml-2">Continue with Google</p>
              </div>
            </button>
          </div> */}
              <div className="flex flex-row text-sm">
                <h4>New to GShop?</h4>
                <Link to="/sign-up" className="text-blue-600 pl-2 underline">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>

  );
};

export default Login;
