import React, { Fragment, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const BusinessModel = (props) => {
  const [businessModel, setBusinessModel] = useState("");
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [showInfo, setShowInfo] = useState({
    serviceBased: false,
    productBased: false,
    pickup: false,
    onsite: false,
  });

  const selectModelHandler = (event) => {
    props.setModel((prevState) => {
      return event.target.value;
    });

    setBusinessModel(event.target.value);
  };

  const selectDeliveryMethodHandler = (event) => {
    let tempDeliveryMethods = [...deliveryMethods];
    const uncheckedDeliveryMethod = tempDeliveryMethods.find(
      (item) => item === event.target.value
    );
    if (uncheckedDeliveryMethod) {
      tempDeliveryMethods = tempDeliveryMethods.filter(
        (item) => item !== uncheckedDeliveryMethod
      );
    } else {
      tempDeliveryMethods.push(event.target.value);
    }
    setDeliveryMethods(tempDeliveryMethods);
    props.setDeliveryModes(tempDeliveryMethods);
  };

  const showInfoHandler = (infoType) => {
    if (infoType === "serviceBased") {
      setShowInfo((prevState) => ({
        ...prevState,
        serviceBased: true,
      }));
    } else if (infoType === "productBased") {
      setShowInfo((prevState) => ({
        ...prevState,
        productBased: true,
      }));
    } else if (infoType === "pickup") {
      setShowInfo((prevState) => ({
        ...prevState,
        pickup: true,
      }));
    } else if (infoType === "onsite") {
      setShowInfo((prevState) => ({
        ...prevState,
        onsite: true,
      }));
    }
  };

  const hideInfoHandler = (infoType) => {
    if (infoType === "serviceBased") {
      setShowInfo((prevState) => ({
        ...prevState,
        serviceBased: false,
      }));
    } else if (infoType === "productBased") {
      setShowInfo((prevState) => ({
        ...prevState,
        productBased: false,
      }));
    } else if (infoType === "pickup") {
      setShowInfo((prevState) => ({
        ...prevState,
        pickup: false,
      }));
    } else if (infoType === "onsite") {
      setShowInfo((prevState) => ({
        ...prevState,
        onsite: false,
      }));
    }
  };

  return (
    <Fragment>
      <div>
        <span className="font-bold text-2xl">Select your Business model</span>
        <div
          className="flex mx-auto mt-8 font-light"
          onChange={selectModelHandler}
        >
          <label className="flex px-4 items-center border border-gray-400 rounded-md w-2/3 h-20 hover:border-gray-700">
            <input type="radio" name="model" value="service-based" />
            <div className="flex justify-between items-center w-full">
              <p className="ml-2">Variable pricing</p>
              <div
                className="relative"
                onMouseEnter={() => showInfoHandler("serviceBased")}
                onMouseLeave={() => hideInfoHandler("serviceBased")}
              >
                <AiOutlineInfoCircle size={20} />
                {showInfo.serviceBased && (
                  <div>
                    <div className="absolute w-0 h-0 border-t-[10px] border border-gray-800 border-l-[10px] border-r-[10px] border-l-transparent border-r-transparent"></div>
                    <p className="bg-gray-800 w-96 absolute top-7 -left-48 p-1 rounded-md text-white">
                    A dynamic pricing strategy where the final cost is determined through negotiation between the seller and buyer. Both parties engage in discussions, starting from their initial price points, aiming to reach a mutually agreeable cost before finalizing the order.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </label>
          <label className="flex items-center px-4 border border-gray-400 ml-4 rounded-md w-2/3 h-20 hover:border-gray-700">
            <input type="radio" name="model" value="product-based" />
            <div className="flex justify-between items-center w-full">
              <p className="ml-2">Static pricing</p>
              <div
                className="relative"
                onMouseEnter={() => showInfoHandler("productBased")}
                onMouseLeave={() => hideInfoHandler("productBased")}
              >
                <AiOutlineInfoCircle size={20} />
                {showInfo.productBased && (
                  <div>
                    <div className="absolute w-0 h-0 border-t-[10px] border border-gray-800 border-l-[10px] border-r-[10px] border-l-transparent border-r-transparent"></div>
                    <p className="bg-gray-800 w-96 absolute top-7 -left-80 p-1 rounded-md text-white">
                    A static pricing strategy where products or services are offered at a set price, non-negotiable by the buyer. The cost is predetermined by the seller, ensuring consistency and transparency for all customers upon purchase.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </label>
        </div>
      </div>

      {/* set delivery method */}
      {businessModel && <div className={`mt-8 transition-all ease-in-out ${businessModel ? "max-h-[600px]" : "h-0"}`}>
        <span className="font-bold text-2xl">
          Select preferred delivery method
        </span>
        <div className="flex mx-auto mt-8 font-light">
          <label className="flex px-4 items-center border border-gray-400 rounded-md w-2/3 h-20 hover:border-gray-700">
            <input
              type="checkbox"
              name="delivery"
              value="in-store"
              required
              onChange={(event) => selectDeliveryMethodHandler(event)}
            />
            <div className="flex justify-between items-center w-full">
              <span className="ml-2">In-store</span>
              <div
                className="relative"
                onMouseEnter={() => showInfoHandler("pickup")}
                onMouseLeave={() => hideInfoHandler("pickup")}
              >
                <AiOutlineInfoCircle size={20} />
                {showInfo.pickup && (
                  <div>
                    <div className="absolute w-0 h-0 border-t-[10px] border border-gray-800 border-l-[10px] border-r-[10px] border-l-transparent border-r-transparent"></div>
                    <p className="bg-gray-800 w-96 absolute top-7 -left-48 p-1 rounded-md text-white">
                    In this arrangement, customers obtain merchandise and services within the owner's establishment.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </label>
          <label className="flex items-center px-4 border border-gray-400 ml-4 rounded-md w-2/3 h-20 hover:border-gray-700">
            <input
              type="checkbox"
              name="delivery"
              value="out-store"
              required
              onChange={selectDeliveryMethodHandler}
            />
            <div className="flex justify-between items-center w-full">
              <span className="ml-2">
                {businessModel === "product-based" ? "Home delivery" : "Onsite"}
              </span>
              <div
                className="relative"
                onMouseEnter={() => showInfoHandler("onsite")}
                onMouseLeave={() => hideInfoHandler("onsite")}
              >
                <AiOutlineInfoCircle size={20} />
                {showInfo.onsite && (
                  <div>
                    <div className="absolute w-0 h-0 border-t-[10px] border border-gray-800 border-l-[10px] border-r-[10px] border-l-transparent border-r-transparent"></div>
                    <p className="bg-gray-800 w-96 absolute top-7 -left-80 p-1 rounded-md text-white">
                    In this arrangement, the owner delivers goods and services to the customer's specified location.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </label>
        </div>
      </div>}
    </Fragment>
  );
};

export default BusinessModel;
