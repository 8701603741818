import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Modal from "../../../UI/Modal";

const OrdersFilter = (props) => {
  const statusArray = [
    "All",
    "On the way",
    "Delivered",
    "Cancelled",
    "Returned",
  ];
  const timeArray = ["Anytime", "Last 30 days", "Last 6 months", "Last year"];
  const [status, setStatus] = useState(
    props.status ? props.status : statusArray[0]
  );
  const [time, setTime] = useState(props.time ? props.time : timeArray[0]);

  const resetFilterHandler = () => {
    setStatus(statusArray[0]);
    setTime(timeArray[0]);
  };

  return (
    <Modal onBackdropClick={props.onClose}>
      <div className="bg-white max-w-md mx-auto">
        <div className="flex justify-between px-4 pt-4">
          <h1 className="font-bold">Filter Orders</h1>
          <button onClick={props.onClose}>
            <RxCross2 size={20} />
          </button>
        </div>
        <div className="p-4">
          <h1 className="font-bold text-sm mb-2">Status</h1>
          <ul>
            {statusArray.map((item, index) => (
              <li key={index} className="mb-1">
                <input
                  type="radio"
                  name="status"
                  id={item}
                  value={item}
                  checked={status === item}
                  onClick={() => setStatus(item)}
                />
                <label htmlFor={item} className="ml-2 font-light">
                  {item}
                </label>
              </li>
            ))}
          </ul>
        </div>
        <hr />
        <div className="p-4">
          <h1 className="font-bold text-sm mb-2">Time</h1>
          <ul>
            {timeArray.map((item, index) => (
              <li key={index} className="mb-1">
                <input
                  type="radio"
                  name="time"
                  id={item}
                  value={item}
                  checked={time === item}
                  onClick={() => setTime(item)}
                />
                <label htmlFor={item} className="ml-2 font-light">
                  {item}
                </label>
              </li>
            ))}
          </ul>
        </div>
        <hr />
        <div className="p-4 flex gap-2 flex-col sm:flex-row">
          <button
            className="flex-1 border border-black rounded-md p-2"
            onClick={resetFilterHandler}
          >
            CLEAR FILTERS
          </button>
          <button className="flex-1 border border-black rounded-md p-2 bg-pink-700 text-white">
            APPLY
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default OrdersFilter;
