import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { api } from "../../../../apis";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getOwnerToken } from "../../../../utils/businessDetails";
import RecursiveComponent from "../Products/RecursiveComponent";

const CreateCategory = () => {
  const navigate = useNavigate();
  const categories = useLocation().state;
  const [categoryImg, setCategoryImg] = useState([]);
  const [categoryData, setCategoryData] = useState({
    name: "",
    description: "",
    // mediaURL: "",
    media: "",
  });
  const [parentId, setParentId] = useState("");
  // const [parentId, setParentId] = useState([]);  // for multiple parent categories

  // for multiple parent categories
  // const selectParentCategoryHandler = (parentCategoryIdArray) => {
  //   // console.log("parent category id:", parentCategoryIdArray);
  //   setParentId((prevState) => (parentCategoryIdArray));
  // }

  const selectParentCategoryHandler = (parentCategoryId) => {
    setParentId((prevState) => (parentCategoryId));
  }

  const params = useParams();
  // const token = localStorage.getItem("authToken");

  const addImageHandler = (event) => {
    const file = event.target.files[0];
    // const filesObjArr = [];
    // files.map((item, id) => (
    //   filesObjArr.push({file: item, id: Math.random()*100})
    // ));

    setCategoryData((prevState) => ({ ...prevState, media: file }));
    
    // const imgObj = {
    //   src: URL.createObjectURL(file),
    //   id: Math.random() * 10,
    // };
    // const imgArr = [...categoryImg, imgObj];
    const imgArr = [];
    // filesObjArr.map((item, index) => (
    //   imgArr.push({src: URL.createObjectURL(item.file), id: item.id,})
    // ));
    imgArr.push(URL.createObjectURL(file));
    // setCategoryImg(imgArr);
    setCategoryImg(imgArr);
    // setCategoryData((prevState) => ({ ...prevState, mediaURL: imgArr[0].src }));
  };

  const deleteImageHandler = (index) => {
    // let imgArr = [...categoryImg];
    // imgArr = imgArr.filter((item) => item.id !== index);
    setCategoryImg([]);

    // let filesObjArr = [...categoryData.media];
    // filesObjArr = filesObjArr.filter((item) => (item.id !== index));
    setCategoryData((prevState) => ({ ...prevState, media: "" }));
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setCategoryData((prevState) => ({ ...prevState, [name]: value }));
  };

  const formSubmitHandler = (event) => {
    // send data to server
    event.preventDefault();
    const formData = new FormData();

    formData.append("name", categoryData.name);
    formData.append("parent_id", parentId);
    formData.append("description", categoryData.description);
    formData.append("image", categoryData.media);

    api
      .post(`/businesses/${params.bid}/categories/`, formData, {
        headers: {
          Authorization: `Bearer ${getOwnerToken()}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setCategoryData(() => ({
          name: "",
          description: "",
          media: "",
        }));
        toast.success("Category created successfully");
        navigate("..");
      })
      .catch((error) => {
        if(error.code==="ERR_NETWORK") {
          alert(`${error.message} \nKindly check your connection`);
          return;
        }
        toast.error(`Error adding new category \n${error.response.data.error_message}`);
      });
  };

  return (
    <div className="flex items-center justify-center p-8 lg:w-4/5 lg:ml-auto max-sm:w-full max-sm:px-2">
      <div className="mx-auto w-full sm:max-w-lg">
        <div className="flex gap-4 items-center px-9">
          <Link
            to=".."
            className="border p-2 rounded-md border-gray-400 hover:bg-white"
          >
            <BiArrowBack size={20} color="gray" />
          </Link>
          <span className="font-medium text-xl">Add category</span>
        </div>
        <form className="py-6 px-9" onSubmit={formSubmitHandler}>
          <div className="p-4 shadow-lg rounded-md bg-white">
            <div className="mb-5">
              <label
                htmlFor="name"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                Title
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Tshirt"
                  required
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  value={categoryData.name}
                  onChange={inputChangeHandler}
                />
              </label>
            </div>

            <label
              htmlFor="description"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              Description
            </label>
            <div className="w-full mb-5">
              <textarea
                id="description"
                name="description"
                rows="4"
                className="w-full border rounded-md border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md focus:border"
                placeholder="TShirt Collection..."
                required
                maxLength="200"
                value={categoryData.description}
                onChange={inputChangeHandler}
              ></textarea>
              {/* <div className="flex items-center justify-between px-3 py-2 dark:border-gray-600">
                <div className="flex pl-0 space-x-1 sm:pl-2">
                  <button
                    type="button"
                    className="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                  >
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 12 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 6v8a5 5 0 1 0 10 0V4.5a3.5 3.5 0 1 0-7 0V13a2 2 0 0 0 4 0V6"
                      />
                    </svg>
                    <span className="sr-only">Attach file</span>
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                  >
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 16 20"
                    >
                      <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                    </svg>
                    <span className="sr-only">Set location</span>
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                  >
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 18"
                    >
                      <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                    <span className="sr-only">Upload image</span>
                  </button>
                </div>
              </div> */}
            </div>

            {/* Parent Category */}
            <div className="mb-5">
              <label
                htmlFor="parentCategory"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                Parent Category
              </label>
              {/* <Categories categories={categories} level={0} selectParentCategoryHandler={selectParentCategoryHandler}/> */}
              <RecursiveComponent data={categories} level={0} selectedItem={parentId} selectItem={selectParentCategoryHandler} />
            </div>
          </div>

          <div className="p-4 shadow-lg rounded-md bg-white mt-6">
            <div>
              <label className="mb-3 block text-base font-medium text-[#07074D]">
                Upload File
              </label>

              <div>
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="sr-only"
                  onChange={addImageHandler}
                />
                <label
                  htmlFor="file"
                  className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                >
                  <div>
                    <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                      Drop files here
                    </span>
                    <span className="mb-2 block text-base font-medium text-[#6B7280]">
                      Or
                    </span>
                    <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] hover:cursor-pointer">
                      Browse
                    </span>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="p-4 shadow-lg rounded-md bg-white mt-6">
            <div className="mb-5">
              <label
                htmlFor="category"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                Media
              </label>
            </div>
            <div className="grid grid-flow-row grid-cols-3 gap-2">
              {categoryImg.map((item, index) => (
                <div className="relative w-32 h-32" key={index}>
                  <img
                    id={index}
                    src={item}
                    alt="category"
                    className="w-32 h-32 object-cover absolute top-0 left-0"
                  />
                  <div className="absolute top-0 left-0 hover:bg-gray-600 hover:bg-opacity-30 hover:cursor-pointer w-full h-full"></div>
                  <div
                    className="absolute left-12 top-12 mx-auto w-4 h-4 text-center text-5xl flex items-center justify-center text-transparent hover:text-gray-700 hover:cursor-pointer"
                    onClick={(event) => deleteImageHandler(item.id)}
                  >
                    X
                  </div>
                </div>
              ))}
            </div>
          </div>

          <hr className="mt-6 border-gray-300" />
          <div className="mt-6 flex justify-end">
            <button
              className="hover:shadow-form w-40 rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateCategory;
