import React, { useState } from 'react';

import  toast, { Toaster } from 'react-hot-toast';
import { api } from '../apis';
import { Link, useNavigate, useParams } from 'react-router-dom';

const ResetPassword = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordReset, setPasswordReset] = useState(false);

    const formSubmitHandler = (event) => {
        event.preventDefault();

        const form_data = {
            new_pwd: password,
        };

        if(params.bid) {
            api.post(`/businesses/${params.bid}/users/${params.user_id}/resetPassword/${params.token}`, JSON.stringify(form_data), {headers: {
                "Content-Type": "application/json",
            }}).then((response) => {
                toast.success("Password reset successfully");
                setPasswordReset(true);
            }).catch((error) => {
              if(error.code==="ERR_NETWORK") {
                toast.error(`${error.message} \nKindly check your connection`);
                return;
              }
                toast.error(error.response.data.error_message);
                navigate(`/business/${params.bid}/login/forgotPassword`);
            });
        }
        else {
            api.post(`/owners/${params.owner_id}/resetPassword/${params.token}`, JSON.stringify(form_data), {headers: {
                "Content-Type": "application/json",
            }}).then((response) => {
                toast.success("Password reset successfully");
                setPasswordReset(true);
            }).catch((error) => {
              if(error.code==="ERR_NETWORK") {
                toast.error(`${error.message} \nKindly check your connection`);
                return;
              }
                toast.error(error.response.data.error_message);
                navigate(`/login/forgotPassword`);
            });
        }
    };

  return (
      <>
          <Toaster/>
          <section className="bg-slate-200">
              <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                  {/* <a
        href=""
        className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
      >
        <img
          className="w-8 h-8 mr-2"
          src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg"
          alt="logo"
        />
        Flowbite
      </a> */}
                  <div className="w-full p-6 bg-white rounded-lg shadow md:mt-0 sm:max-w-md sm:p-8">
                      <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                          Reset Password
                      </h2>
                      <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5">
                          {/* <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              placeholder="name@gmail.com"
              onChange={(event) => setEmail(event.target.value)}
              required=""
            />
          </div> */}
                          <div>
                              <label
                                  htmlFor="password"
                                  className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                  New Password
                              </label>
                              <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  value={password}
                                  placeholder="••••••••"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                  required=""
                                  onChange={(event) => setPassword(event.target.value)}
                              />
                          </div>
                          <div>
                              <label
                                  htmlFor="confirm-password"
                                  className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                  Confirm password
                              </label>
                              <input
                                  type="password"
                                  name="confirmPassword"
                                  id="confirm-password"
                                  value={confirmPassword}
                                  placeholder="••••••••"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                  required=""
                                  onChange={(event) => setConfirmPassword(event.target.value)}
                              />
                          </div>
                          {password !== confirmPassword &&
                              <div className='text-red-600'>
                                  Passwords do not match.
                              </div>}
                          {/* <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="newsletter"
                aria-describedby="newsletter"
                type="checkbox"
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                required=""
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                for="newsletter"
                className="font-light text-gray-500 dark:text-gray-300"
              >
                I accept the{" "}
                <a
                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  href="#"
                >
                  Terms and Conditions
                </a>
              </label>
            </div>
          </div> */}
                          {!passwordReset && <button
                              type="submit"
                              onClick={formSubmitHandler}
                              disabled={password !== confirmPassword}
                              className="w-full bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                          >
                              Reset password
                          </button>}
                      </form>
                      {passwordReset && <div className="mt-4 text-green-700 flex flex-col gap-2">
                          <span>Password Reset successfully!</span>
                          <Link to={params.bid ? `/business/${params.bid}/login` : `/login`}
                                className='text-blue-600 underline'>Login</Link>
                      </div>}
                  </div>

              </div>
          </section>
      </>

  );
};

export default ResetPassword;