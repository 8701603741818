import React, { Fragment } from "react";
import ModalOverlay from "./ModalOverlay";
import ReactDOM from "react-dom";
import Backdrop from "./Backdrop";

const Modal = (props) => {
  const portalElement = document.getElementById("overlays");

  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop onClick={props.onBackdropClick} />, portalElement)}
      {/* do not pass any marginLeft props with Modal for modal overlay is to be centered */}
      {ReactDOM.createPortal(<ModalOverlay children={props.children} marginLeft={props.marginLeft} top={props.top} />, portalElement)}
    </Fragment>
  );
};

export default Modal;
