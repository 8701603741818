import React, { Fragment, useState } from "react";
import { BiSolidAddToQueue, BiSolidEdit } from "react-icons/bi";
import { TiTickOutline } from "react-icons/ti";
import { TiCancel } from "react-icons/ti";
import EnquiryDetails from "./EnquiryDetails";
import { api } from "../../../../apis";
import QuoteForm from "./QuoteForm";
import { useParams } from "react-router-dom";
import { PiArrowsCounterClockwiseFill, PiBackspace } from "react-icons/pi";
import { getOwnerToken } from "../../../../utils/businessDetails";
import toast,{Toaster} from "react-hot-toast";
import CurrencyFormat from "react-currency-format";

const Enquiry = (props) => {

  const enquiry = {
    enquiry_id: props.enquiry.enquiry_id,
    status: props.enquiry.status,
    permitted_states: props.enquiry.permitted_states,
    first_name: props.enquiry.first_name,
    last_name: props.enquiry.last_name,
    updated_on: props.enquiry.updated_on,
    price: props.enquiry.price,
    product_name: props.enquiry.name,
    // isExpanded: false,
    user_id: props.enquiry.user_id,
    group_id: props.enquiry.group_id,
    delivery_mode: props.enquiry.delivery_mode,
    address_id: props.enquiry.address_id,
    photo: props.enquiry.photo,
    product_id: props.enquiry.product_id,
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const detailsShowHandler = () => {
    // setEnquiry((prevState) => ({
    //   ...prevState,
    //   isExpanded: !prevState.isExpanded,
    // }));
    setIsExpanded((prevState) => !prevState);
  };

  const [isQuoteFormShown, setIsQuoteFormShown] = useState(false);
  const [buttonType, setButtonType] = useState("");

  const quoteFormShownHandler = (event, buttonType) => {
    event.stopPropagation();
    setIsQuoteFormShown(true);
    setButtonType(buttonType);
  };

  const quoteFormHideHandler = () => {
    setIsQuoteFormShown(false);
  };

  const params = useParams();

  const submitQuoteHandler = (quoteFormData) => {
    // console.log(quoteFormData);
    const form_data = new FormData();
    form_data.append("status", quoteFormData.status);
    form_data.append("remark", quoteFormData.remark);
    form_data.append("price", quoteFormData.price);

    quoteFormData.photos.map((item) => form_data.append("images", item));

    // for(const x of form_data) {
    //   // console.log(`${x[0]} : ${x[1]}`);
    // }
    api.put(
      `/businesses/${params.bid}/admin/enquiries/${enquiry.enquiry_id}`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",          
          Authorization: `Bearer ${getOwnerToken()}`,
        },
      }
    ).then((response) => {
        window.location.reload() // reload page to reflect changes in table data after submission of the form
    }).catch((error) => {
      if(error.code==="ERR_NETWORK") {
        toast.error(`${error.message} \nKindly check your connection`);
        return;
      }
        toast.error(`Error submitting form \n${error.response.data.error_message}`);
    });
  };

  const enquiryActionHandler = (event, buttonType) => {
    event.stopPropagation();
    api.put(
        `/businesses/${params.bid}/admin/enquiries/${enquiry.enquiry_id}`,
        JSON.stringify({status: buttonType}),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getOwnerToken()}`,
          },
        }
      ).then((response) => {
          window.location.reload(); // reload page to reflect changes in table data after submission of the form
      }).catch((error) => {
        if(error.code==="ERR_NETWORK") {
          toast.error(`${error.message} \nKindly check your connection`);
          return;
        }
          toast.error(`Error submitting form \n${error.response.data.error_message}`);
      });
  };

  //188
  const createOrderHandler = (event) => {
    event.stopPropagation();
    const form_data = {
      user_id: enquiry.user_id,
      group_id: enquiry.group_id,
      delivery_mode: enquiry.delivery_mode,
      address_id: enquiry.address_id,
      items: [{
        product_id: enquiry.product_id,
        enquiry_id: enquiry.enquiry_id,
        price: enquiry.price,
      }],
    };

    api.post(`businesses/${params.bid}/admin/order`, JSON.stringify(form_data), {headers: {
      "Content-Type":"application/json",
      Authorization:`Bearer ${getOwnerToken()}`,
    }}).then((response) => {
      window.location.reload();
    }).catch((error) => {
      if(error.code==="ERR_NETWORK") {
        toast.error(`${error.message} \nKindly check your connection`);
        return;
      }
      toast.error(error.response.data.error_message);
    });
  };

  return (
      <>
        <Toaster/>
        <Fragment key={enquiry.enquiry_id}>
          <tr
              key={enquiry.enquiry_id}
              className={`bg-white border-b hover:bg-gray-50 cursor-pointer ${enquiry.status === "CANCELLED" && "text-red-900"}`}
              onClick={detailsShowHandler}
          >
            <td
                className={`px-6 py-4 font-medium ${enquiry.status === "CANCELLED" ? "text-red-900" : "text-gray-900"} whitespace-nowrap border-r border-gray-200`}
            >
              {enquiry.first_name + " " + enquiry.last_name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">{enquiry.enquiry_id}</td>
            <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">{enquiry.product_name}</td>
            <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">{enquiry.updated_on.split("T")[0]}</td>
            <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200">
              <CurrencyFormat value={enquiry.price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
            </td>
            <td className="px-6 py-4 whitespace-nowrap border-r border-gray-200 text-right">
              <div className="flex justify-start gap-4">
                {enquiry.permitted_states.includes("QUOTED") && (
                    <button
                        onClick={(event) => (quoteFormShownHandler(event, "QUOTED"))}
                        id="QUOTED"
                        className="font-medium text-white hover:underline flex bg-blue-600 border rounded-lg py-1 px-2"
                    >
                      <BiSolidEdit size={20}/>
                      Quote
                    </button>
                )}
                {enquiry.permitted_states.includes("ACCEPTED") && (
                    <button
                        id="ACCEPTED"
                        onClick={(event) => (enquiryActionHandler(event, "ACCEPTED"))}
                        className="font-medium text-white hover:underline flex bg-green-600 border rounded-lg py-1 px-2"
                    >
                      <TiTickOutline size={20}/>
                      Accept
                    </button>
                )}
                {enquiry.permitted_states.includes("COUNTERED") && (
                    <button
                        onClick={(event) => (quoteFormShownHandler(event, "COUNTERED"))}
                        id="COUNTERED"
                        className="font-medium text-white hover:underline flex bg-blue-600 border rounded-lg py-1 px-2"
                    >
                      <PiArrowsCounterClockwiseFill size={20}/>
                      Counter
                    </button>
                )}
                {enquiry.permitted_states.includes("CANCELLED") && (
                    <button
                        id="CANCELLED"
                        onClick={(event) => (quoteFormShownHandler(event, "CANCELLED"))}
                        className="font-medium text-white hover:underline flex bg-red-600 border rounded-lg py-1 px-2"
                    >
                      <TiCancel size={20}/>
                      Cancel
                    </button>
                )}
                {enquiry.permitted_states.includes("UNCANCELLED") && (
                    <button
                        id="UNCANCELLED"
                        onClick={(event) => (enquiryActionHandler(event, "UNCANCELLED"))}
                        className="font-medium text-white hover:underline flex bg-purple-600 border rounded-lg py-1 px-2"
                    >
                      <PiBackspace size={20}/>
                      Uncancel
                    </button>
                )}
                {enquiry.permitted_states.includes("CANCELLED_BY_CUSTOMER") && (
                    <div
                        onClick={(event) => (event.stopPropagation())}
                        className="font-medium hover:cursor-default text-red-900"
                    >
                      Cancelled by Customer
                    </div>
                )}
                {enquiry.status === "ACCEPTED" && (
                    <button
                        onClick={(event) => (createOrderHandler(event))}
                        className="font-medium text-white hover:underline flex bg-indigo-600 border rounded-lg py-1 px-2">
                      <BiSolidAddToQueue size={20}/>
                      Create Order
                    </button>
                )}
                {enquiry.status === "CREATED_ORDER" && (
                    <div
                        onClick={(event) => (event.stopPropagation())}
                        className="font-medium hover:cursor-default text-cyan-700">
                      Order created
                    </div>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <EnquiryDetails
                // isDetailsShown={enquiry.isExpanded}
                isDetailsShown={isExpanded}
                enquiryId={enquiry.enquiry_id}
                onClickQuote={props.quoteFormShownHandler}
            />
          </tr>
          {isQuoteFormShown && (
              <QuoteForm
                  onHideForm={quoteFormHideHandler}
                  onSubmitQuote={submitQuoteHandler}
                  buttonType={buttonType}
              />
          )}
        </Fragment>
      </>

  );
};

export default Enquiry;
