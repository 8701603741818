import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  LoginPage,
  SignupPage,
  HomePage,
  CreateShopPage,
  SelectBusinessPage,
} from "./Routes";
import Layout from "./components/Customer/Layout/Layout";
import Home from "./components/Customer/pages/Home/Home";
import Product from "./components/Customer/pages/Product/Product";
import Products from "./components/Customer/pages/Products/Products";
// import { checkAuthToken } from "./utils/auth";
import AdminLayout from "./components/Owner/Layout/AdminLayout";
import AdminHome from "./components/Owner/pages/Home/AdminHome";
import CustomersList from "./components/Owner/pages/Customers/CustomersList";
import OrdersList from "./components/Owner/pages/Orders/OrdersList";
import ProductsList from "./components/Owner/pages/Products/ProductsList";
import CategoriesList from "./components/Owner/pages/Categories/CategoriesList";
import SingleOrder from "./components/Owner/pages/Orders/SingleOrder";
import CreateOrder from "./components/Owner/pages/Orders/CreateOrder";
import SingleProduct from "./components/Owner/pages/Products/SingleProduct";
import CreateProduct from "./components/Owner/pages/Products/CreateProduct";
import SingleCategory from "./components/Owner/pages/Categories/SingleCategory";
import CreateCategory from "./components/Owner/pages/Categories/CreateCategory";
import Login from "./components/Customer/Login/Login";
import Signup from "./components/Customer/Signup/Signup";
import Cart from "./components/Customer/Cart/Cart";
import CartLayout from "./components/Customer/Layout/CartLayout";
// import CustomerDashboard from "./components/Customer/pages/Dashboard/CustomerDashboard";
import EnquiryList from "./components/Owner/pages/Enquiries/EnquiryList";
import CustomerDashboardLayout from "./components/Customer/pages/Dashboard/Layout/CustomerDashboardLayout";
import CustomerEnquiryList from "./components/Customer/pages/Dashboard/Enquiries/CustomerEnquiryList";
import Profile from "./components/Customer/pages/Dashboard/Profile/Profile";
import CustomerOrdersList from "./components/Customer/pages/Dashboard/Orders/CustomerOrdersList";
import Form from "./components/Customer/Cart/Form/Form";
import ManageBusiness from "./components/Owner/pages/ManageBusiness/ManageBusiness";
import {
  checkBusiness,
  fetchandUpdateBusinessDetails,
  getCustomerToken,
  getOwnerToken,
} from "./utils/businessDetails";
import VerifyEmail from "./components/Signup/VerifyEmail";
import CustomerVerifyEmail from "./components/Customer/Signup/CustomerVerifyEmail";
import { loader as logoutAction } from "./components/Owner/Logout/Logout";
import { customerLogout } from "./components/Customer/Logout/Logout";
import ForgotPassword from "./utils/ForgotPassword";
import ResetPassword from "./utils/ResetPassword";
import ErrorPage from "./pages/ErrorPage";
import Customer404Page from "./components/Customer/pages/Error/Customer404Page";
import FilterContext from "./components/Filter/FilterContext";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "/login",
        children: [
          {
            index: true,
            element: <LoginPage />,
          },
          {
            path: "forgotPassword",
            element: <ForgotPassword />,
          },
        ],
      },
      {
        path: "owners/:owner_id/resetPassword/:token",
        element: <ResetPassword />,
      },
      {
        path: "/sign-up",
        element: <SignupPage />,
      },
      {
        path: "/logout",
        loader: logoutAction,
      },
      {
        path: "/owners/:owner_id/verify/:token",
        element: <VerifyEmail />,
      },
      {
        path: "/admin/:bid",
        element: <AdminLayout />,
        loader: getOwnerToken,
        // loader: checkAuthToken,  //remove comment once done with admin dashboard
        children: [
          {
            path: "/admin/:bid",
            element: <AdminHome />,
          },
          {
            path: "/admin/:bid/orders",
            children: [
              {
                index: true,
                element: <OrdersList />,
              },
              {
                path: ":oid",
                element: <SingleOrder />,
              },
              {
                path: "create",
                element: <CreateOrder />,
              },
            ],
          },
          {
            path: "/admin/:bid/enquiries",
            children: [
              {
                index: true,
                element: <EnquiryList />,
              },
            ],
          },
          {
            path: "/admin/:bid/products",
            children: [
              {
                index: true,
                element: <ProductsList />,
              },
              {
                path: ":pid",
                element: <SingleProduct />,
              },
              {
                path: "create",
                element: <CreateProduct />,
              },
            ],
          },
          {
            path: "/admin/:bid/categories",
            children: [
              {
                index: true,
                element: <CategoriesList />,
              },
              {
                path: ":cid",
                element: <SingleCategory />,
              },
              {
                path: "create",
                element: <CreateCategory />,
              },
            ],
          },
          {
            path: "customers",
            element: <CustomersList />,
          },
          {
            path: "manage-business",
            element: <ManageBusiness />,
            loader: ({ params }) => checkBusiness(params.bid),
          },
          {
            path: "profile",
            element: <Profile userType="owner" />,
          },
        ],
      },
      {
        path: "/create-shop",
        element: <CreateShopPage />,
        loader: getOwnerToken,
      },
      {
        path: "/select-shop",
        element: <SelectBusinessPage />,
        loader: getOwnerToken,
      },
      {
        path: "/business/:bid",
        element: <CartLayout />, //to update cart of the customer to the state when customer left
        loader: ({ params }) => fetchandUpdateBusinessDetails(params.bid),
        errorElement: <Customer404Page />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: "/business/:bid/dashboard",
            element: <CustomerDashboardLayout />,
            loader: ({ params }) => getCustomerToken(params.bid),
            children: [
              {
                index: true,
                element: <Profile userType="customer" />,
                // element: <CustomerDashboard />,
              },
              {
                path: "enquiries",
                element: <CustomerEnquiryList />,
                // id: `/business/:bid/dashboard/enquiries`,
                // loader: ({params}) => (getCustomerToken(params.bid)),
              },
              {
                path: "orders",
                element: <CustomerOrdersList />,
              },
              {
                path: "profile",
                element: <Profile userType="customer" />,
              },
            ],
          },
          {
            path: "/business/:bid/login",
            children: [
              {
                index: true,
                element: <Login />,
              },
              {
                path: "forgotPassword",
                element: <ForgotPassword />,
              },
            ],
          },
          {
            path: "/business/:bid/users/:user_id/resetPassword/:token",
            element: <ResetPassword />,
          },
          {
            path: "/business/:bid/logout",
            loader: ({ params }) => customerLogout(params.bid),
          },
          {
            path: "/business/:bid/signup",
            element: <Signup />,
          },
          {
            path: "/business/:bid/users/:user_id/verify/:token", //requested to change the URL
            element: <CustomerVerifyEmail />,
          },
          {
            path: "/business/:bid/cart",
            element: <Layout />,
            children: [
              {
                index: true,
                element: <Cart />,
              },
              {
                path: "checkout",
                element: <Form />,
                id: "checkout",
                loader: ({ params }) => getCustomerToken(params.bid),
              },
            ],
          },
          {
            path: "/business/:bid/products/category/:cid",
            element: <Layout />,
            // id: "/business/:bid/products/:cid",
            // loader: ({params}) => (getCustomerToken(params.bid)),
            children: [
              {
                index: true,
                element: <Products />,
              },
            ],
          },
          {
            path: "/business/:bid/products/product/:pid",
            element: <Layout />,
            children: [
              {
                index: true,
                element: <Product />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <FilterContext>
      {/* <div> */}
        <RouterProvider router={router} />
      {/* </div> */}
    </FilterContext>
  );
}

export default App;
