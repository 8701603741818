import React, { useState } from "react";
import ImageExpander from "../../../../Owner/pages/Enquiries/ImageExpander";
import CurrencyFormat from "react-currency-format";

const CustomerEnquiryLedger = (props) => {
  const [expandImages, setExpandImages] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  // console.log(props)
  const customer_details =  props.customerDetails;
  const expandImageHandler = (images) => {
    // console.log("image expander images", images);
    const selected_images = [...images];
    setExpandImages(selected_images);
    setIsExpanded(true);
  };

  const hideImageExpandedHandler = () => {
    setIsExpanded(false);
  };

  return (
    <td colSpan={3} className="align-top w-1/2">
      <div
        className={` overflow-y-auto transition-all duration-700 h-[500px] relative ${
          props.isDetailsShown ? "max-h-[500px] p-6 border" : "max-h-0"
        } ease-in-out`}
      >
        {props.enquiryLedger.map((item, index) => (
          <div
            key={index}
            className="flex flex-col rounded-xl border border-gray-100 text-left text-gray-600 shadow-lg sm:p-4"
          >
            <div className="flex flex-row">
            <img
              className="mr-5 block h-8 w-8 max-w-full text-left align-middle sm:h-16 sm:w-16 rounded-full"
              src={item.created_by_customer ? customer_details.user.photo[0] : "https://cdn0.iconfinder.com/data/icons/kameleon-free-pack-rounded/110/Boss-3-512.png"}
              alt="Profile"
            />
            <div className="text-left w-full">
              <div className="mb-2 flex flex-col justify-between text-gray-600 sm:flex-row">
                <h3 className="font-medium">{item.created_by_customer ? props.enquiryCreatorName : "Owner"}</h3>
                <div className="flex items-center gap-2">
                  <p>Price:</p>
                  <CurrencyFormat value={item.price} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
                </div>
              </div>
              <p className="text-sm">{item.remark}</p>
              <div className="flex items-center justify-between gap-2 mt-2">
                <p><span className="mr-1 text-red-500">Status:</span><span>{item.status}</span></p>
                <time className="text-xs" dateTime="2022-11-13">
                  {item.updated_on.split("T")[0]}
                </time>
              </div>
            </div>
            </div>
            <div className=" w-full overflow-x-auto lg:overflow-visible">
              <div className="flex items-center mt-4" onClick={() => expandImageHandler(item.images)}>
                {item.images && item.images.map((image, index) => (
                  <img key={index}
                  alt="enquiry"
                  src={image}
                  className="relative inline-block h-12 w-12 rounded-full border-2 border-white object-cover object-center hover:z-10 focus:z-10" 
                  />
                ))}
                </div>
                {isExpanded && <ImageExpander onClose={hideImageExpandedHandler} images={expandImages} type="ledger" user="customer" />}
              </div>
          </div>
        ))}
      </div>
    </td>
  );
};

export default CustomerEnquiryLedger;
