import React, { useEffect, useState } from "react";
import Payment from "./Payment";
import Address from "./Address";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getBusinessModel, getCustomerDetails } from "../../../../utils/businessDetails";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../../../apis";
import { cartActions } from "../../../redux/reducers/cartReducer";
import  toast, { Toaster } from 'react-hot-toast';
import OrderThankYou from "../OrderThankYou";

const Form = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [orderDetails, setOrderDetails] = useState({
    isOrdered: false,
    customer: {
      address: {
        address1:"",
        city: "",
        state: "",
        pincode : "" ,
      },
      name: "",
      phoneNo: "",
      email: "",
    },
    orderId: "",
    orderItems: [],
    orderedOn: "",
    orderTotal: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    mobileNo: "",
    pinCode: "",
    city: "",
    state: "",
    address: "",
    paymentMethod: "",
    cardNo: "",
    nameOnCard: "",
    validity: "",
    cvv: "",
    delivery_mode: "",
    address_id: "",
    store_name: "",
  });

  //   // console.log("formData", formData);
  const businessModel = getBusinessModel(params.bid);
  const customerDetails = getCustomerDetails(params.bid);
  const products = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();

  const placeOrderHandler = () => {
    // TODO: call api to convert cart to enquiry written in Cart.js "getQuote" if businessModel is service-based
    if (businessModel === "service-based") {
      const form_data = new FormData();
      form_data.append("user_id", customerDetails.user.id);
      form_data.append('address_id', formData['address_id']);
      form_data.append("delivery_mode", formData.delivery_mode);
      products.forEach((item, index) => {
        form_data.append(`items[${index}][product_id]`, item.id);
        form_data.append(`items[${index}][price]`, item.price);
        form_data.append(`items[${index}][status]`, "INITIATED");
        form_data.append(`items[${index}][remark]`, item.enquiryFormDataMessage);
        // form_data.append(`items[${index}][street_address]`, formData.address);
        // form_data.append(`items[${index}][city]`, formData.city);
        // form_data.append(`items[${index}][zip_code]`, formData.pinCode);
        form_data.append(`items[${index}][images]`, []);
      });

      // for(const x of form_data.entries()) {
      //   // console.log(`${x[0]}:${x[1]}`);
      // }

      api.post(`/businesses/${params.bid}/enquiries`, form_data, {headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${customerDetails.token}`
        }}).then(async(response) => {
        dispatch(cartActions.resetCart());
        localStorage.removeItem(`${params.bid}-cart`);
        toast.success("Enquiry created successfully!");
        const order_details = {
          isOrdered: false,
          customer: {
            address: {
              address1:"",
              city: "",
              state: "",
              pincode : "" ,
            },
            name: "",
            phoneNo: "",
            email: "",
          },
          orderId: "",
          orderItems: [],
          orderedOn: "",
          orderTotal: 0,
        };
        for(const item of response.data) {
          await(api.get(`/businesses/${params.bid}/enquiry_details`, {params: {enquiry_id: item.enquiry_id}, headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${customerDetails.token}`,
            }}).then(async(res) => {
            order_details.isOrdered = true;
            order_details.customer.address.address1 = res.data.enquiry_details[0].address_details[0].address;
            order_details.customer.address.city = res.data.enquiry_details[0].address_details[0].city;
            order_details.customer.address.state = res.data.enquiry_details[0].address_details[0].state;
            order_details.customer.address.pincode = res.data.enquiry_details[0].address_details[0].pincode;
            order_details.customer.name = res.data.enquiry_details[0].first_name + res.data.enquiry_details[0].last_name;
            order_details.customer.phoneNo = res.data.enquiry_details[0].phone;
            order_details.customer.email = res.data.enquiry_details[0].email;
            order_details.delivery_mode = res.data.enquiry_details[0].delivery_mode;
            order_details.orderId = res.data.enquiry_details[0].group_id;
            order_details.orderedOn = res.data.enquiry_details[0].created_on;
            order_details.orderTotal = order_details.orderTotal + res.data.enquiry_details[0].price;
            await api.get(`/businesses/${params.bid}/products/${item.product_id}`, {headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${customerDetails.token}`}})
                .then(({ data }) => {
                  order_details.orderItems.push(data);
                }).catch((error) => {
                  if(error.code==="ERR_NETWORK") {
                    toast.error(`${error.message} \nKindly check your connection`);
                    return;
                  }
                  toast.error(`Error \n${error.response.data.error_message}`);
                });
          }).catch((error) => {
            if(error.code==="ERR_NETWORK") {
              toast.error(`${error.message} \nKindly check your connection`);
              return;
            }
            toast.error(`Error \n${error.response.data.error_message}`);
          }))}
        setOrderDetails((prevState) => ({
          ...prevState,
          ...order_details,
        }));
      }).catch((error) => {
        if(error.code==="ERR_NETWORK") {
          toast.error(`${error.message} \nKindly check your connection`);
          return;
        }
        toast.error(`Error creating enquiry \n${error.response.data.error_message}`);
      });
    }

    // TODO: call api to convert cart to order written in Cart.js "checkout" if businessModel is product-based
    if (businessModel === "product-based") {

      const dataItems = products.map((item) => ({
        product_id: item.id,
        price: item.price,
        quantity: item.quantity,
      }));

      const data = {
        delivery_mode: formData.delivery_mode,
        payment_mode: formData.paymentMethod,
        user_id: customerDetails.user.id,
        address_id: formData.address_id,
        items: dataItems,
      };

      // // console.log(data);

      api
          .post(`/businesses/${params.bid}/order`, JSON.stringify(data), {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${customerDetails.token}`,
            },
          })
          .then((response) => {
            toast.success("Order created successfully!");
            dispatch(cartActions.resetCart());
            localStorage.removeItem(`${params.bid}-cart`);
            setOrderDetails({
              isOrdered: true,
              customer: {
                address: {
                  address1: response.data.address[0].address,
                  city: response.data.address[0].city,
                  state: response.data.address[0].state,
                  pincode : response.data.address[0].pincode,
                },
                name: response.data.address[0].name,
                phoneNo: response.data.address[0].phone_no,
                email: "",
              },
              delivery_mode: response.data.order_details[0].delivery_mode,
              orderId: response.data.order_details[0].order_id,
              orderItems: [],
              orderedOn: response.data.order_details[0].created_on,
              orderTotal: response.data.order_details[0].total,
            });
            api.get(`/businesses/${params.bid}/orders`, {params: {limit: 1, user_id: customerDetails.user.id}, headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${customerDetails.token}`,
              }}).then((response) => {
              setOrderDetails((prevState) => ({
                ...prevState,
                customer: {
                  ...prevState.customer,
                  email: response.data[0].email,
                },
                orderItems: Object.values(response.data[0].order_items.reduce((acc, item) => {
                  let key = item.product_id;
                  if(!acc[key]) {
                    acc[key] = {
                      ...item,
                      quantity: 0,
                      totalPrice: 0,
                    };
                  }
                  acc[key] = {
                    ...acc[key],
                    quantity: acc[key].quantity+1,
                    totalPrice: parseInt(acc[key].totalPrice) + parseInt(item.price),
                  };
                  return acc;
                }, {})),
              }));
            }).catch((error) => {
              if(error.code==="ERR_NETWORK") {
                toast.error(`${error.message} \nKindly check your connection`);
                return;
              }
              toast.error(error.response.data.error_message);
            });
          })
          .catch((error) => {
            if(error.code==="ERR_NETWORK") {
              toast.error(`${error.message} \nKindly check your connection`);
              return;
            }
            toast.error(`Order cannot be created! ${error.response.data.error_message}`);
          });
    }
  };

  const conditionalComponent = () => {
    if (businessModel === "product-based") {
      switch (page) {
        case 0:
          return <Address formData={formData} setFormData={setFormData} />;
        case 1:
          return (
              <Payment
                  formData={formData}
                  setFormData={setFormData}
                  placeOrder={placeOrderHandler}
              />
          );
        default:
          return <Address formData={formData} setFormData={setFormData} />;
      }
    } else {
      switch (page) {
        case 0:
          return <Address formData={formData} setFormData={setFormData} />;
        default:
          return <Address formData={formData} setFormData={setFormData} />;
      }
    }
  };

  useEffect(() => {
    if(!location.state) {
      navigate(`/business/${params.bid}/cart`);
    }
  }, [params.bid, navigate, location.state]);

  return (
      <>
        <Toaster/> {
        !orderDetails.isOrdered ? <div className="py-4 max-w-5xl mx-auto border my-8 rounded-lg">
              <div className="text-center border-b pb-4">
        <span
            className={`mr-2 tracking-widest ${
                page === 0
                    ? "text-emerald-500 pb-1 border-b-2 border-emerald-500 font-bold"
                    : "text-gray-500"
            }`}
        >
          DELIVERY
        </span>
                {businessModel === "product-based" && (
                    <>
                      <span className="font-extralight">----------</span>
                      <span
                          className={`ml-2 tracking-widest ${
                              page === 1
                                  ? "text-emerald-500 pb-1 border-b-2 border-emerald-500 font-bold"
                                  : "text-gray-500"
                          }`}
                      >
              PAYMENT
            </span>
                    </>
                )}
              </div>
              <div className="flex flex-col sm:flex-row gap-2 px-4 py-4">
                <div className="flex-[6]">{conditionalComponent()}</div>
                <div className="border"></div>
                <div className="flex-[3]">
                  <p className="text-gray-600 text-sm mb-4 font-bold">PRICE DETAILS</p>
                  <div className="flex justify-between font-light mb-2">
                    <p>Total Price</p>
                    <p>${location.state ? location.state.total : ""}</p>
                  </div>
                  <div className="flex justify-between font-light mb-2">
                    <p>Discount on Price</p>
                    <p className="text-green-600">-$0</p>
                  </div>
                  <hr/>
                  <div className="flex justify-between mt-4 mb-4">
                    <h3>
                      <strong>Total Amount</strong>
                    </h3>
                    <h3>
                      <strong>${location.state ? location.state.total : ""}</strong>
                    </h3>
                  </div>
                  {page === 0 ? (
                      businessModel === "product-based" ? (
                          <button
                              className="bg-pink-700 text-white rounded-sm py-2 w-full"
                              onClick={() => setPage(page + 1)}
                          >
                            CONTINUE
                          </button>
                      ) : (
                          <button
                              className="bg-pink-700 text-white rounded-sm py-2 w-full"
                              onClick={placeOrderHandler}
                          >
                            CREATE ENQUIRY
                          </button>
                      )
                  ) : (
                      <div className="flex gap-4">
                        {/* <button className="bg-pink-700 text-white rounded-sm py-2 flex-1">
                PAY NOW
              </button> */}
                        <button
                            className="bg-gray-700 text-white rounded-sm py-2 flex-1"
                            onClick={() => setPage(page - 1)}
                        >
                          BACK
                        </button>
                      </div>
                  )}
                  {formData.delivery_mode === "out-store" && (
                      <div className="mt-20">
                        <p className="border-b-2 mb-2 pb-2 text-gray-600 text-sm font-bold">
                          SHIPPING TO
                        </p>
                        <div className="text-gray-600">
                          <p>{formData.name}</p>
                          <p>{formData.address}</p>
                          <p>{`${formData.city}, ${formData.state} ${formData.pinCode}`}</p>
                        </div>
                      </div>
                  )}
                  {formData.delivery_mode === "in-store" && (
                      <div className="mt-20">
                        <p className="border-b-2 mb-2 pb-2 text-gray-600 text-sm font-bold">
                          PICKUP AT
                        </p>
                        <div className="text-gray-600">
                          <p>{formData.store_name}</p>
                          <p>{formData.address}</p>
                          <p>{`${formData.city}, ${formData.state} ${formData.pinCode}`}</p>
                        </div>
                      </div>
                  )}
                </div>
              </div>

            </div> :
            <OrderThankYou orderDetails={orderDetails}/>
      }
      </>


  );
};

export default Form;
