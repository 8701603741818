import React from 'react';

const ShopName = (props) => {
    const nameChangeHandler = (event) => {
        props.setBusinessName((prevState) => {
            return event.target.value;
        })
    };

  return (
    <div>
        <div className="space-y-6">
            <div>
              <label
                htmlFor="business"
                className="block text-2xl font-bold"
              >
                Specify your business name
              </label>
              <div className="mt-8">
                <input
                  type="text"
                  name="business"
                  autoComplete="on"
                  required
                  value={props.name}
                  onChange={nameChangeHandler}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
        </div>
    </div>
  );
};

export default ShopName;