import React, {useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomerEnquiry from "./CustomerEnquiry";
import { api } from "../../../../../apis";
import { getCustomerToken } from "../../../../../utils/businessDetails";
import  toast, { Toaster } from 'react-hot-toast';

const CustomerEnquiryList = () => {
  const params = useParams();
  // const authToken = JSON.parse(localStorage.getItem(`${params.bid}-customer`)).token;
  const authToken = getCustomerToken(params.bid);
  const user_id = JSON.parse(localStorage.getItem(`${params.bid}-customer`)).user.id;
  const [enquiries, setEnquiries] = useState([
    {
      enquiry_id: "",
      permitted_states: [],
      first_name: "",
      last_name: "",
      updated_on: "",
      price: "",
      isExpanded: false,
      name: ""
    },
  ]);

  useEffect(() => {
    api
      .get(`/businesses/${params.bid}/enquiries`, {params: {user_id: user_id},
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        // const enquiries = response.data.result.filter((item) => (item.permitted_states.length > 0));
        // setEnquiries(enquiries);
        setEnquiries(response.data.result);
      }).catch((error) => {
        if(error.code==="ERR_NETWORK") {
          toast.error(`${error.message} \nKindly check your connection`);
          return;
        }
        toast.error(error.response.data.error_message);

      });
  }, [authToken, params.bid, user_id]);

  return (

      <>
        <Toaster/>
        <div className="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
          <div className="shadow-md">
            <div className="pl-4 pt-4">
              <span className="font-bold text-xl">All enquiries</span>
            </div>
            <div className="p-4">
              <label htmlFor="table-search" className="sr-only">
                Search
              </label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                    type="text"
                    id="table-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5"
                    placeholder="Search htmlFor items"
                />
              </div>
            </div>
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-200">
              <tr>
                {/* <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </th> */}
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Customer Name
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Enquiry ID
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Product Name
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
              </thead>
              <tbody>
              {enquiries.map((item, index) => (
                  <CustomerEnquiry enquiry={item} key={item.enquiry_id}/>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </>

  );
};

export default CustomerEnquiryList;
