import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = (props) => {
  return (
    <div className='p-16 text-center'>
        <h1 className="text-center text-xl text-gray-700 font-semibold mb-4">Error {props.description}</h1><br/>
        <Link to={`/${props.pages}`} className=' bg-indigo-600 rounded-md px-4 py-2 text-white'>{props.text}</Link>
    </div>
  );
};

export default ErrorPage;