import React, { Fragment, useState } from "react";
import { CgAdd } from "react-icons/cg";
import { GrSubtractCircle } from "react-icons/gr";
import {FiEdit} from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import { api } from "../../../../apis";
import { getOwnerToken } from "../../../../utils/businessDetails";
import DeleteModal from "../../components/Modal/DeleteModal";

const ChildCategory = ({ items, categories, level }) => {
  const params = useParams();
  const [showNested, setShowNested] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleNested = (category_id) => {
    setShowNested({ ...showNested, [category_id]: !showNested[category_id] });
  };

  const toggleDeleteCategoryHandler = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const deleteCategoryHandler = (category_id) => {
    api.delete(`/businesses/${params.bid}/categories/${category_id}`, {headers: {
      Authorization: `Bearer ${getOwnerToken()}`
    }}).then((response) => {
      window.location.reload();
    }).catch((error) => {
      if(error.code==="ERR_NETWORK") {
        alert(`${error.message} \nKindly check your connection`);
        return;
      }
      alert(`Error \n${error.response.data.error_message}`);
    });
  };

  return (
    <Fragment>
      {items.map((item, index) => (
        <Fragment key={index}>
          <tr className="border-b border-gray-200 hover:bg-gray-100">
            <td
              className={`py-3 px-6 text-left whitespace-nowrap`} style={item.children.length===0 ? {paddingLeft: level * 60 + 'px'} : {paddingLeft: level * 50 + 'px'}}
            >
              <div className="flex items-center gap-4">
                {item.children.length > 0 && (
                  <button onClick={() => toggleNested(item.id)}>
                    {!showNested[item.id] && <CgAdd size={22} />}
                    {showNested[item.id] && <GrSubtractCircle size={22} />}
                  </button>
                )}
                <img
                  // src={`${process.env.REACT_APP_SERVER_URL}${item.photo_url}`}
                  src={`${item.photo_url}`}
                  alt="category"
                  className=" w-12 h-12 object-cover rounded-2xl"
                />
                <p className="font-medium text-lg">{item.name}</p>
              </div>
            </td>
            {/* <td className="py-3 px-6 text-center">
              <span className="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-md">
                Active
              </span>
            </td> */}
            <td className="py-3 px-6 text-center">
              <div className="flex item-center justify-center">
                <Link
              to={`${item.id}`}
              state={{
                id: item.id,
                name: item.name,
                description: item.description,
                mediaURL: item.photo_url,
                categories: categories,
                parentId: item.parent_id,
              }}
              className="bg-blue-600 text-white px-4 py-1.5 rounded-md flex items-center gap-1 justify-center hover:bg-blue-500"
            >
              <FiEdit size={20} />
              <span>Edit Item</span>
            </Link>
            <div className="bg-red-600 text-white px-4 py-1.5 rounded-md flex items-center gap-1 justify-center hover:bg-red-500 ml-4">
              <button className="w-full flex" onClick={() => toggleDeleteCategoryHandler(item.id)}>
                <RiDeleteBinLine size={20} />
                <span>Delete Item</span>
              </button>
            </div>
              </div>
            </td>
          </tr>
          {showNested[item.id] && item.children.length > 0 && <ChildCategory items={item.children} categories={categories} level={level+1} />}

          {showDeleteModal && 
          <DeleteModal itemType="Category" itemName={item.name} closeModalHandler={toggleDeleteCategoryHandler} deleteItemHandler={() => deleteCategoryHandler(item.id)} />}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default ChildCategory;
