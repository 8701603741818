import React from "react";

const ModalOverlay = (props) => {
  // // console.log(`top-[${props.top}%]`, props.top, props.top===undefined);
  return (
    <div
      // className={`fixed overflow-y-auto h-screen top-0 ${props.marginLeft===undefined ? `left-1/2 ml-[-230px]` : ``} z-[90]`}
      className={`fixed overflow-y-auto ${props.top===undefined ? "top-0" : `top-[${props.top}%]`} ${props.marginLeft===undefined ? `md:w-1/2 w-full md:left-1/4 left-0` : ``} z-[90]`}
      style={{
        top: `${props.top}%`
        }
      }
    >
      {props.children}
    </div>
  );
};

export default ModalOverlay;
