import React, { useEffect, useState } from "react";
import Stepper from "./components/Stepper";
import Navigation from "./components/Navigation";
import ShopName from "./ShopFormPages/ShopName";
import BusinessModel from "./ShopFormPages/BusinessModel";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../apis";
import  toast, { Toaster } from 'react-hot-toast';
function CreateShopForm() {
  const [page, setPage] = useState(1);
  const [businessName, setBusinessName] = useState("");
  const [businessModel, setBusinessModel] = useState("");
  const [deliveryModes, setDeliveryModes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const isBusinessNameValid = businessName.trim().length > 0;
  const isBusinessModelValid =
      businessModel === "service-based" || businessModel === "product-based";
  const isDeliveryMethodValid = deliveryModes.length>0

  const isValid = {
    isBusinessModelValid,
    isBusinessNameValid,
    isDeliveryMethodValid,
  };

  const formSubmitHandler = async() => {
    //check if user isAuthenticated and isAdmin and then retrieve the owner id from local storage
    const formData = {
      owner_id: String(JSON.parse(localStorage.getItem("owner")).user.id),
      business_name: businessName,
      business_model: businessModel,
      delivery_mode: deliveryModes.length===1 ? deliveryModes[0] : "in/out-store",
    };
    setIsLoading(true);
    await api.post("/businesses", JSON.stringify(formData), {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("owner")).token}`,
      }
    }).then((response) => {
      if(response.data[0].business_id) {
        // navigate(`/${response.data[0].name}/admin`);
        setIsLoading(false);
        setBusinessModel("");
        setBusinessName("");
        navigate("/select-shop");
      }
    }).catch((error) => {
      if(error.code==="ERR_NETWORK") {
        toast.error(`${error.message} \nKindly check your connection`);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      toast.error(`Error creating Business! ${error.response.data.error_message}`);
    });
  }

  useEffect(() => {
    setBusinessModel("");
    setDeliveryModes([]);
    if(page===1) {
      setBusinessName("");
    }
  }, [page]);

  return (
      <>
        <Toaster/>
        <div className="bg-gradient-to-r from-cyan-500 to-blue-500 py-12 px-8 min-h-screen">
          <div className="max-w-lg w-full bg-white mx-auto px-10 py-10 rounded-md">
            <Link to="/home" className="flex flex-row mb-4 items-center">
              <img
                  src="https://st2.depositphotos.com/3867453/12012/v/600/depositphotos_120126908-stock-illustration-letter-g-number-6-logo.jpg"
                  alt="company logo"
                  className="w-8 h-8"
              />
              <p className="ml-2 italic font-extrabold text-2xl">GShop</p>
            </Link>
            <div>
              <Stepper currentPage={page} isValid={isValid}/>
            </div>
            {page === 1 ? (
                <div className="mt-16">
                  <ShopName business={businessName} setBusinessName={setBusinessName}/>
                </div>
            ) : (
                <div className="mt-16">
                  <BusinessModel model={businessModel} setModel={setBusinessModel} deliveryModes={deliveryModes}
                                 setDeliveryModes={setDeliveryModes}/>
                </div>
            )}
            <div className="mt-16">
              <Navigation page={page} setPage={setPage} isValid={isValid} onSubmit={formSubmitHandler}
                          isLoading={isLoading}/>
            </div>
          </div>
        </div>
      </>

  );
}

export default CreateShopForm;