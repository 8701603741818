import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../apis";
import  toast, { Toaster } from 'react-hot-toast';

const SelectBusiness = () => {
  //const owner_id = localStorage.getItem("owner_id");
  const [businesses, setBusinesses] = useState([]);
  const navigate = useNavigate();

  const selectBusinessHandler = useCallback((business) => {
    localStorage.setItem(`${business.business_name_id}-business`, JSON.stringify(business));
    navigate(`/admin/${business.business_name_id}`);
  }, [navigate]);

  useEffect(() => {
    let owner_id = JSON.parse(localStorage.getItem("owner")).user.id;
    api
      .get("/businesses", { params: { owner_id: owner_id },
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("owner")).token}`,
        },
      })
      .then((response) => {
        // console.log(response);
        const businesses = response.data.businesses.map((business) => {
          return (
            // // console.log(business.business_name)
            <li key={business.business_id} className="mt-4">
              <button
                className="w-full h-[40px] text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                onClick={(event) => (selectBusinessHandler(business))}
              >
                {business.business_name}
              </button>
            </li>
          );
        });
        setBusinesses(businesses);
      })
      .catch((error) => {
        // console.log(error);
        if(error.code==="ERR_NETWORK") {
          toast.error(`${error.message} \nKindly check your connection`);
          return;
        }
        toast.error(`Something went wrong! \n${error.response.data.error_message}`);

      });
    return () => {
      owner_id = null;
    };
  }, [selectBusinessHandler]);

  return (
      <>
        <Toaster/>
        <div className="bg-gradient-to-r from-cyan-500 to-blue-500 min-h-screen py-12 px-8">
          <div className="max-w-lg w-full bg-white mx-auto px-10 shadow-md rounded-lg py-10">
            <Link to="/home" className="px-4 flex flex-row items-center">
              <img
                  src="https://st2.depositphotos.com/3867453/12012/v/600/depositphotos_120126908-stock-illustration-letter-g-number-6-logo.jpg"
                  alt="company logo"
                  className="w-8 h-8"
              />
              <p className="ml-2 italic font-extrabold text-2xl">GShop</p>
            </Link>
            <div className="px-4 mt-10">
          <span className="font-bold text-2xl">
            Select your business to manage
          </span>
            </div>
            <div className="mt-10 px-4">
              <ul>{businesses}</ul>
            </div>
            <div className="mt-10 px-4 flex justify-end">
              <Link
                  to={"/create-shop"}
                  className="w-auto h-[40px] text-right py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              >
                Create shop
              </Link>
            </div>
          </div>
        </div>
      </>

  );
};

export default SelectBusiness;
