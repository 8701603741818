import React, { useEffect } from "react";
import "./ProductsList.scss";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";
import Loader from "../../../Loader/Loader";
import Filter from "../../../Filter/Filter";
import { getFilteredItems } from "../../../../utils/filterItems";
import { useFilterState } from "../../../Filter/FilterContext";
import Product from "./Product";

const ProductsList = () => {
  const params = useParams();
  const {query, resetQueryHandler} = useFilterState();
  const { loading, error, data } = useFetch(
    `/businesses/${params.bid}/products/`,
    null,
    "owner",
    null
  );

  useEffect(() => {
    return (() => resetQueryHandler());
  }, [resetQueryHandler]);

  if (error) {
    return toast.error(error);
  }

  let filteredProducts = data;
  if (data) {
    const filteredItems = getFilteredItems({
      items: filteredProducts,
      filterBy: ["name"],
      query: query,
    });
    filteredProducts = [...filteredItems];
  }

  return !loading ? (
    <div className=" ml-auto bg-white productContainer">
      <div className="relative overflow-x-auto p-4 justify-between">
        <div>
          <h2 className="text-2xl font-semibold leading-tight">Products</h2>
        </div>
        <div className=" mt-4 flex flex-row justify-between">
          <div className="flex sm:flex-row flex-col">
            <Filter searchType="text" placeholder="Products" />
          </div>
          <div className="flex items-center justify-between">
            <div className="">
              <Link
                to="create"
                className="bg-indigo-600 px-4 py-2 rounded-md text-white font-semibold tracking-wide cursor-pointer"
              >
                Add Product
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className=" py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Sold
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Featured
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Quantity
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  filteredProducts.map((item, index) => (
                    <Product key={index} item={item} />))}
              </tbody>
            </table>
            <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
              <span className="text-xs xs:text-sm text-gray-900">
                Showing 1 to 3 of 3 Entries
              </span>
              <div className="inline-flex mt-2 xs:mt-0">
                <button className="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-l">
                  Prev
                </button>
                &nbsp; &nbsp;
                <button className="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-r">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  ) : (
    <div className="w-screen h-screen flex items-center justify-center mx-auto my-auto">
      <Loader />
    </div>
  );
};

export default ProductsList;
