import React, { useEffect } from "react";
import "./CategoriesList.scss";
import { Link, useParams } from "react-router-dom";
import RecursiveComponent from "./RecursiveComponent";
import useFetch from "../../../hooks/useFetch";
import Loader from "../../../Loader/Loader";
import Filter from "../../../Filter/Filter";
import { useFilterState } from "../../../Filter/FilterContext";
import { getFilteredItems } from "../../../../utils/filterItems";

const CategoriesList = () => {
  const params = useParams();
  const {query, resetQueryHandler} = useFilterState();
  const { loading, error, data } = useFetch(
    `/businesses/${params.bid}/categories/`,
    null,
    "owner",
    params
  );

  useEffect(() => {
    return (() => resetQueryHandler());
  }, [resetQueryHandler]);

  if (error) {
    return alert(error);
    // return <div>{error}</div>
  }

  let categories = data;
  if (data) {
    const filteredItems = getFilteredItems({
      items: data.categories,
      filterBy: ["name"],
      query: query,
    });
    categories = [...filteredItems];
  }

  return !loading ? (
    <div className="ml-auto bg-white categoryContainer">
      <div className="relative overflow-x-auto">
        <div className="px-4 md:px-4 py-4 md:py-4">
          <div className="items-center justify-between">
            <p
              tabIndex="0"
              className="focus:outline-none text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal"
            >
              Categories
            </p>
            <div className="sm:flex sm:flex-row sm:justify-between sm:items-center mt-4 flex flex-col justify-start items-start">
              <Filter searchType="text" placeholder="Category" />
              <div className="sm:flex items-center flex justify-end">
                <Link
                  to="create"
                  state={categories}
                  className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-indigo-700 hover:bg-indigo-600 focus:outline-none rounded"
                >
                  <p className="font-medium leading-none text-white">
                    Add Category
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className=" overflow-x-auto">
          <div className="overflow-x-auto border">
            <div className="">
              <div className="min-w-screen flex bg-gray-100 font-sans overflow-hidden">
                <div className="w-full ">
                  <div className="bg-white shadow-md overflow-x-auto ">
                    <table className="min-w-max w-full table-auto">
                      <thead>
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                          <th className="py-3 px-6 text-left">Name</th>
                          <th className="py-3 px-6 text-center">Actions</th>
                        </tr>
                      </thead>
                      {data && (
                        <tbody className="text-gray-600 text-sm font-light">
                          {categories.map((item) => (
                            <RecursiveComponent
                              key={item.id}
                              item={item}
                              categories={categories}
                            />
                          ))}
                        </tbody>
                      )}
                    </table>
                    <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                      <span className="text-xs xs:text-sm text-gray-900">
                        Showing 1 to 4 of 50 Entries
                      </span>
                      <div className="inline-flex mt-2 xs:mt-0">
                        <button className="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-l">
                          Prev
                        </button>
                        &nbsp; &nbsp;
                        <button className="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-r">
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="w-screen h-screen flex items-center justify-center mx-auto my-auto">
      <Loader />
    </div>
  );
};

export default CategoriesList;
